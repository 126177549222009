import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Checkbox from 'libraries/Checkbox';
import Dialog from 'libraries/Dialog';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { withStyles } from '@mui/styles';

import Table from 'components/table_components/table';
import TableCellCustom from 'components/table_components/table_cell';
import TableCellHead from 'components/table_components/table_cell_head';

import Correct from './assets/correct.svg';
import Wrong from './assets/wrong.svg';
import Empty from './assets/empty.svg';


import styles from './styles';

export default withStyles(styles)(({ classes, questionResults }) => {
    const [dialogOpened, setDialogOpened] = useState(false);

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const returnIconSrc = (checked, correctChoice) => {
        if (!checked) return Empty;
        if (correctChoice) return Correct;
        return Wrong;
    };

    if (!questionResults) return null;

    return (
        <div>
            <Checkbox
                label="Prospect List"
                checked
                disabled
                classNameLabel="u-txt--bold"
            />
            {
                Boolean(questionResults[0]?.answerResults?.length) && (
                    <Button
                        color="primary"
                        onClick={handleDialog}
                    >
                        View Prospect Details
                    </Button>
                )
            }
            <Dialog
                open={dialogOpened}
                titleComponent="Prospects"
                onClose={handleDialog}
                handleClose={handleDialog}
            >
                <TableContainer>

                    <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCellHead className={classes.tableCellHead} label="Annual Revenue" hideSort />
                                <TableCellHead className={classes.tableCellHead} label="Company Size" hideSort />
                                <TableCellHead className={classes.tableCellHead} label="Industry" hideSort />
                                <TableCellHead className={classes.tableCellHead} label="Summary" hideSort />
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {
                                questionResults[0]?.answerResults?.map(({ checked, correctChoice, candidateSalesSimulationProspect: { id, annualRevenue, companySize, industry, summary } }) => (
                                    <TableRow
                                        key={id}
                                        classes={{
                                            root: clsx(classes.tableRow, correctChoice && classes.tableRowCorrect),
                                            hover: classes.hoverable
                                        }}
                                    >
                                        <TableCellCustom className={classes.tableCell} align="left">
                                            <img src={returnIconSrc(checked, correctChoice)} className="u-mrg--rx2" alt="" />
                                            {annualRevenue}
                                        </TableCellCustom>
                                        <TableCellCustom className={classes.tableCell} align="left">
                                            {companySize}
                                        </TableCellCustom>
                                        <TableCellCustom className={classes.tableCell} align="left">
                                            {industry}
                                        </TableCellCustom>
                                        <TableCellCustom
                                            align="left"
                                            className={clsx(classes.tableCell, classes.reasonColumn)}
                                        >
                                            {summary}
                                        </TableCellCustom>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </div>
    );
});
