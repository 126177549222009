import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import NoInvitationsContent from '../NoInvitationsContent';

import { candidateResultCtx } from './candidatesStore';
import { statsCtx } from '../../../statsStore';
import { assessmentSettingsCtx } from '../../../store';

const CandidatesListWrapper = observer(({ match, isShortList, children }) => {
    const { audition, getTags } = useContext(assessmentSettingsCtx);
    const {
        isNoCandidates,
        shortlistCandidatesCount,
        totalCompletionsCount,
        loading: isLoadingStats
    } = useContext(statsCtx);

    const {
        loadCandidates, setLoading,
        cleanState, setSlug: setSlugCandidates,
        setShortlist, loadFiltersStats, initState,
        isLoading: isLoadingCandidateResults,
        setFilterCategory
    } = useContext(candidateResultCtx);

    const { scriptSlug } = match.params;

    useEffect(() => () => {
        cleanState();
    }, []);

    useEffect(() => {
        if (!scriptSlug) return;
        initState();
        setLoading(true);
        setSlugCandidates(scriptSlug);
        loadFiltersStats();
        setShortlist(isShortList);
        setFilterCategory();
        loadCandidates();
        getTags();
    }, [scriptSlug]);

    return (
        <>
            {!isLoadingStats && !isLoadingCandidateResults
            && (isNoCandidates || !totalCompletionsCount || (isShortList && !shortlistCandidatesCount))
                ? (
                    <NoInvitationsContent
                        slug={scriptSlug}
                        audition={audition}
                        hasCandidates={!isNoCandidates}
                        hasCompleted={Boolean(totalCompletionsCount)}
                        shortList={isShortList}
                    />
                ) : (
                    <>
                        {children}
                    </>
                )}
        </>
    );
});

export default withRouter(CandidatesListWrapper);
