// local files and components
import textIcon from 'components/icons/AnswerTypesIcons/text';
import multipleChoice from 'components/icons/AnswerTypesIcons/multiple-choice';
import audioIcon from 'components/icons/AnswerTypesIcons/audio';
import videoIcon from 'components/icons/AnswerTypesIcons/video';
import fileIcon from 'components/icons/AnswerTypesIcons/upload';
import docIcon from 'components/icons/AnswerTypesIcons/document';
import presentationIcon from 'components/icons/AnswerTypesIcons/presentation';
import spreadIcon from 'components/icons/AnswerTypesIcons/spreadsheet';
import codeIcon from 'components/icons/AnswerTypesIcons/code';
import infoIcon from 'components/icons/AnswerTypesIcons/info';
import numberIcon from 'components/icons/AnswerTypesIcons/number';
import imageIcon from 'components/icons/AnswerTypesIcons/image';
import csSimulationIcon from 'components/icons/AnswerTypesIcons/cs_simulation';
import cyberSimulationIcon from 'components/icons/AnswerTypesIcons/cyber_simulation';
import salesSimulationIcon from 'components/icons/AnswerTypesIcons/sales_simulation';
import marketingSimulationIcon from 'components/icons/AnswerTypesIcons/marketing_simulation';
import typingTestIcon from 'components/icons/AnswerTypesIcons/typing-test';
import qaSimulationIcon from 'components/icons/AnswerTypesIcons/qa_simulation';

import {
    TEXT, MULTIPLE_CHOICE_AUTOGRADED, AUDIO, VIDEO, ANY_FILE,
    NO_ANSWER, SINGLE_CHOICE_AUTO_GRADED, GOOGLE_DOC,
    PRESENTATION, SPREADSHEET, CODE_TEST, CODE_TEST_AUTOGRADED,
    SPREADSHEET_AUTO_GRADED, NUMBER, IMAGE, CS_SIMULATOR, TYPING_TEST,
    CYBER_SIMULATOR, EXCEL_AUTO_GRADED, SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR
} from 'helper/constants';

export const QuestionTypes = {
    [TEXT]: { icon: textIcon, label: 'Text' },
    [MULTIPLE_CHOICE_AUTOGRADED]: { icon: multipleChoice, label: 'Multiple Choice' },
    [SINGLE_CHOICE_AUTO_GRADED]: { icon: multipleChoice, label: 'Multiple Choice' },
    [AUDIO]: { icon: audioIcon, label: 'Audio' },
    [VIDEO]: { icon: videoIcon, label: 'Video' },
    [ANY_FILE]: { icon: fileIcon, label: 'Upload any file', labelShort: 'File Upload' },
    [IMAGE]: { icon: imageIcon, label: 'Image', labelShort: 'Image' },
    [NO_ANSWER]: { icon: infoIcon, label: 'Info Section', labelShort: 'Info' },
    [GOOGLE_DOC]: { icon: docIcon, label: 'Document' },
    [PRESENTATION]: { icon: presentationIcon, label: 'Presentation' },
    [SPREADSHEET]: { icon: spreadIcon, label: 'Spreadsheet' },
    [SPREADSHEET_AUTO_GRADED]: { icon: spreadIcon, label: 'Spreadsheet (auto-graded)' },
    [CODE_TEST_AUTOGRADED]: { icon: codeIcon, label: 'Code', labelShort: 'Code (auto-graded)' },
    [CODE_TEST]: { icon: codeIcon, label: 'Code' },
    [NUMBER]: { icon: numberIcon, label: 'Number' },
    [CS_SIMULATOR]: { icon: csSimulationIcon, label: 'Customer Service Simulation' },
    [CYBER_SIMULATOR]: { icon: cyberSimulationIcon, label: 'Cyber Security Simulation' },
    [TYPING_TEST]: { icon: typingTestIcon, label: 'Typing Test' },
    [EXCEL_AUTO_GRADED]: { icon: spreadIcon, label: 'Excel (auto-graded)' },
    [SALES_SIMULATOR]: { icon: salesSimulationIcon, label: 'Sales Simulation V1' },
    [QA_SIMULATOR]: { icon: qaSimulationIcon, label: 'QA Simulation' },
    [MARKETING_SIMULATOR]: { icon: marketingSimulationIcon, label: 'Marketing Simulation' }
};

export const getQuestionTypeInfo = (questionType, callback) => {
    const { icon, label, labelShort } = QuestionTypes[questionType] || {};
    return callback(icon, label, labelShort, questionType);
};

export const modifyScreeningQuestion = (question) => {
    const { screeningQuestion, questionGroup, singleChoice, text, fileType, ziggeoType } = question;
    if (!screeningQuestion) return { ...question };
    const modified = { ...question };
    modified.type = +fileType || +ziggeoType || TEXT;
    modified.questionGroup = { ...questionGroup, title: 'screening' };
    modified.answerType = singleChoice ? SINGLE_CHOICE_AUTO_GRADED : MULTIPLE_CHOICE_AUTOGRADED;
    modified.description = text;
    return modified;
};
