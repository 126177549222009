import { observable, action, computed } from 'mobx';
import PhoneNumber from 'awesome-phonenumber';
import { checkHasCompanyFeature, clone } from 'helper/commonFunctions';
import { SEND_SMS_INVITATION, VALIDATE_IS_EMAIL, VALIDATE_REQUIRED, VALIDATE_SPACES } from 'helper/constants';
import JOEvents from 'events/JOEvents';
import axios from 'axios';
import React from 'react';
import { inviteToAssessment } from 'requests/AssessmentRequests';
import { SUCCESS_INVITE_STATUS } from '../InviteResultsDialog';
import { setupGuideStore } from '../../layout/SetupGuide/store';
import { appStore } from '../../appStore';

const { CancelToken } = axios;
const invitationStep = 5;
let source = CancelToken.source();


export class InviteCandidatesTabStore {
    @observable inviteViaEmailForm = {};

    @observable showResponseInviteDialog = false;

    @observable showInviteByEmailLoader = false;

    @observable errorLimit = {};

    @observable slug = null;

    @observable successfullySentData = {};

    @observable currentColumnId = '';

    @observable errorMessage = '';

    @observable loading = false;

    @observable rowCount = 2;

    @observable defaultRowCount = 2;

    @observable isCSVInvitation = false;

    @observable defaultFormInvite = null;

    @observable formRef = null;

    @observable smsWarningDialogOpen = false;

    @observable stepsNeededState = 1;

    @observable currentStepState = 0;

    @observable error = '';

    @observable isCanceled = false;

    @computed get invitationArray() {
        return Object
            .keys(this.inviteViaEmailForm)
            .map((key) => {
                const invitationRow = {
                    name: this.inviteViaEmailForm[key].name.value,
                    email: this.inviteViaEmailForm[key].email.value
                };
                const hasSMSFeature = checkHasCompanyFeature(appStore.company, SEND_SMS_INVITATION);
                if (hasSMSFeature) invitationRow.phone = this.inviteViaEmailForm[key].phone.value;
                return invitationRow;
            });
    }

    @computed get hasInvalidPhoneNumbers() {
        return Object.values(this.inviteViaEmailForm).some(formRow => formRow?.isPhoneValid?.value === false);
    }

    @action setSlug = (value) => {
        this.slug = value;
    }

    @action setSmsWarningDialogOpen = (value) => {
        this.smsWarningDialogOpen = value;
    }

    @action onCancel = () => {
        this.isCanceled = true;
        source.cancel();
        this.loading = false;
    };

    @action onSubmitValidate = (props) => {
        this.formRef.current.errors = [];

        this.formRef.current.walk(this.formRef.current.childs).then((result) => {
            if (this.formRef.current.errors.length) {
                this.formRef.current.props.onError(this.formRef.current.errors);
            }

            if (result) {
                this.submitInviteForm(props);
            }

            return result;
        });
    }

    @action submitInviteForm = ({ onSubmitInviteForm, ...other }) => {
        if (!this.invitationArray.length) {
            appStore.flashMessage('Invitation details are missing.');
            return;
        }

        const hasSMSFeature = checkHasCompanyFeature(appStore.company, SEND_SMS_INVITATION);
        const smsLeft = appStore.company.countSmsLeft;

        const candidatesWithPhonesToInviteCount = this.invitationArray.filter(({ phone }) => phone).length;
        const shouldShowWarningModal = hasSMSFeature && (smsLeft < candidatesWithPhonesToInviteCount);

        if (!this.smsWarningDialogOpen && shouldShowWarningModal) {
            this.setSmsWarningDialogOpen(true);
            return;
        }

        if (onSubmitInviteForm) {
            onSubmitInviteForm(this.invitationArray);
        }
        this.setInitialState();
        this.loading = true;
        const stepsNeeded = Math.ceil(this.invitationArray.length / invitationStep);
        this.stepsNeededState = stepsNeeded;
        this.inviteCandidatesFunc(other, this.invitationArray, 1, stepsNeeded, {});
        this.setIsCSVInvitation(false);
    };


    @action inviteCandidatesFunc = (props, sentData, currentStep = 1, stepsNeeded, sentResult) => {
        if (!this.slug) return;
        const {
            onSentError, isQuick, onAllInvitationsSent,
            assessment, context
        } = props;
        const nextStartIndex = (currentStep - 1) * invitationStep;
        const nextLastIndex = nextStartIndex + invitationStep;
        source = CancelToken.source();
        return inviteToAssessment(this.slug, sentData.slice(nextStartIndex, nextLastIndex), false, { cancelToken: source.token })
            .then(({ success, data }) => {
                if (success) {
                    const { columnId, response, countSmsLeft } = data;
                    const newSuccessfullySentData = { ...sentResult };

                    appStore.setCountSmsLeft(countSmsLeft);

                    response.forEach((item) => {
                        if (!newSuccessfullySentData[item.status]) newSuccessfullySentData[item.status] = [];
                        newSuccessfullySentData[item.status].push(item);
                    });
                    this.setSuccessfullySentData(newSuccessfullySentData);

                    setupGuideStore.updateInvitationInfo();

                    if (!isQuick) {
                        this.setCurrentColumnId(columnId);
                    }
                    this.currentStepState = currentStep;
                    if (currentStep === stepsNeeded) {
                        if (onAllInvitationsSent) {
                            onAllInvitationsSent(data);
                        }
                        setTimeout(() => {
                            this.setIsLoading(false);
                            this.setInitialState();
                        }, 2000);
                        this.cleanForm();
                        // if (closeDialog) closeDialog();
                        this.toggleResponseInviteDialog();
                        if (newSuccessfullySentData[SUCCESS_INVITE_STATUS]) {
                            JOEvents.INVITED_CANDIDATES({
                                totalInvites: newSuccessfullySentData[SUCCESS_INVITE_STATUS].length,
                                id: assessment.uuid,
                                name: assessment.name,
                                quickInvite: isQuick,
                                context
                            });
                            if (this.isCSVInvitation) {
                                const { uuid: ttId, name: ttName } = assessment;
                                JOEvents.CANDIDATE_INVITED_CSV({ ttId, ttName });
                            }
                        }
                    } else if (currentStep < stepsNeeded && !this.isCanceled) {
                        this.inviteCandidatesFunc(props, sentData, currentStep + 1, stepsNeeded, newSuccessfullySentData);
                    }
                }
            })
            .catch((err) => {
                if ((window.navigator && !window.navigator.onLine) || (err.response && err.response.status.toString()[0] === '5')) {
                    this.setIsLoading(false);
                    this.setInitialState();
                    appStore.flashMessage('Something went wrong', 'error');
                    return;
                }

                if (axios.isCancel(err)) {
                    this.setIsLoading(false);
                    this.setInitialState();
                    return;
                }

                if (onSentError) {
                    onSentError();
                }

                if (!err.response) {
                    appStore.flashMessage('Something went wrong', 'error');
                    return;
                }

                const errValue = err.response.data.errors;
                if (errValue) {
                    if (typeof errValue === 'string') {
                        this.error = errValue || 'Something went wrong';
                    } else {
                        const { username: lastNameError, email: emailError } = errValue;
                        const lastNameFormattedError = (lastNameError && lastNameError[0] && lastNameError[0].message) || '';
                        const emailFormattedError = (emailError && emailError[0] && emailError[0].message) || '';
                        if (emailFormattedError || lastNameFormattedError) {
                            this.error = (
                                <>
                                    {lastNameFormattedError}
                                    {Boolean(emailFormattedError && lastNameFormattedError) && <br />}
                                    {emailFormattedError}
                                </>
                            );
                        }
                    }
                }

                setTimeout(() => {
                    this.setIsLoading(false);
                    this.setInitialState();
                }, 10000);
            });
    };


    @action setInitialState = () => {
        this.currentStepState = 0;
        this.stepsNeededState = 1;
        this.error = '';
        this.isCanceled = false;
    };


    @action setFormRef = (value) => {
        this.formRef = value;
    };

    @action setDefaultFormInvite = (value) => {
        this.defaultFormInvite = value;
    };

    @action setIsCSVInvitation = (value) => {
        this.isCSVInvitation = value;
    };

    @action setRowCount = (rowCount) => {
        this.rowCount = rowCount;
    };

    @action setDefaultRowCount = (rowCount) => {
        this.defaultRowCount = rowCount;
    };

    @action setCurrentColumnId = (columnId) => {
        this.currentColumnId = columnId;
    };

    @action setFormInvite = (newFormInvite) => {
        this.inviteViaEmailForm = newFormInvite;
    };

    @action toggleResponseInviteDialog = () => {
        const newValue = !this.showResponseInviteDialog;
        this.showResponseInviteDialog = newValue;
        if (!newValue) {
            setTimeout(() => {
                this.successfullySentData = {};
                this.cleanForm();
            }, 1000);
        }
    };

    @action setShowInviteByEmailLoader = (value) => {
        this.showInviteByEmailLoader = value;
    };

    @action setSuccessfullySentData = (newSuccessfullySentData) => {
        this.successfullySentData = newSuccessfullySentData;
    };

    @action setErrorMessage = (errorMessage) => {
        this.errorMessage = errorMessage;
    };

    @action cleanForm = () => {
        this.setFormInvite({});
        this.setErrorMessage('');
        this.rowCount = this.defaultRowCount;
        this.isCSVInvitation = false;
    };

    @action setIsLoading = (value) => {
        this.loading = value;
    }

    @action onValueChange = ({ fieldName, value, index }, emptyFieldsCallback) => {
        let copyOfFormInvite = clone(this.inviteViaEmailForm);

        if (!copyOfFormInvite[index]) {
            copyOfFormInvite[index] = clone(this.defaultFormInvite);
        }

        copyOfFormInvite[index][fieldName].value = value;
        if (fieldName === 'phone') {
            if (!value) {
                copyOfFormInvite[index].isPhoneValid.value = true;
            } else {
                copyOfFormInvite[index].isPhoneValid.value = PhoneNumber(`+${value}`).isValid();
            }
        }
        const hasSMSFeature = checkHasCompanyFeature(appStore.company, SEND_SMS_INVITATION);


        const allFieldsAreEmpty = hasSMSFeature
            ? (!copyOfFormInvite[index].name.value && !copyOfFormInvite[index].email.value && !copyOfFormInvite[index].phone.value)
            : (!copyOfFormInvite[index].name.value && !copyOfFormInvite[index].email.value);

        if (allFieldsAreEmpty) {
            emptyFieldsCallback();
            // Remove from values
            delete copyOfFormInvite[index];
        } else if (hasSMSFeature) {
            copyOfFormInvite[index] = {
                name: {
                    value: copyOfFormInvite[index].name.value,
                    validator: [VALIDATE_SPACES]
                },
                email: {
                    value: copyOfFormInvite[index].email.value,
                    validator: [VALIDATE_IS_EMAIL, VALIDATE_REQUIRED]
                },
                phone: {
                    value: copyOfFormInvite[index].phone.value
                },
                isPhoneValid: {
                    value: copyOfFormInvite[index].isPhoneValid.value
                }
            };
        } else {
            copyOfFormInvite[index] = {
                name: {
                    value: copyOfFormInvite[index].name.value,
                    validator: [VALIDATE_SPACES]
                },
                email: {
                    value: copyOfFormInvite[index].email.value,
                    validator: [VALIDATE_IS_EMAIL, VALIDATE_REQUIRED]
                }
            };
        }

        // Clear object from null or empty
        copyOfFormInvite = Object.assign({}, copyOfFormInvite);
        this.setErrorMessage('');
        this.setFormInvite(copyOfFormInvite);
    };
}

export const inviteCandidatesTabStore = new InviteCandidatesTabStore();
