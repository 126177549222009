import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';

import { getInsightsQuestionByQuestion } from 'requests/AssessmentRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';

import InsightsSectionWrapper from '../InsightsSectionWrapper';
import QuestionDataImg from './QuestionData.svg';
import QuestionTableRow from './QuestionTableRow';


const styles = theme => ({
    tableContainer: {
        marginTop: 20,
        position: 'relative',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    tableCellHead: {
        height: 54,
        padding: 16,
        whiteSpace: 'nowrap',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[300]
    },
    tableCellHeadQuestion: {
        width: 140,
        paddingLeft: '20px !important'
    },
    tableHead: {
        border: `1px solid ${theme.palette.grey[300]}`
    },
    tableCellHeadAnswered: {
        minWidth: 350
    }
});

const QuestionByQuestion = observer(({ classes, audition }) => {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCategory, setSortCategory] = useState(null);
    const [isRandomized, setIsRandomized] = useState(false);
    const { slug } = audition;


    useEffect(() => {
        setLoading(true);
        getInsightsQuestionByQuestion(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, wasRandomized } = data;
                    setChartData(items);
                    setIsRandomized(wasRandomized);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const onSort = (newSortCategory, newSortOrder) => {
        setSortOrder(newSortOrder);
        setSortCategory(newSortCategory);
    };

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Question by Question Insights
            <QuestionTooltipBlack className="u-pdn--tx2 u-pdn--bx2" label={<>Question insights are calculated<br />even if assessment is unfinished</>} />
        </Typography>
    );

    let displayedData = [];
    let sortFunc;

    if (sortOrder && sortCategory === 'question') {
        sortFunc = (a, b) => (a.question.sort - b.question.sort) * (sortOrder === 'asc' ? 1 : -1);
    } else if (sortOrder && sortCategory === 'answered') {
        sortFunc = (a, b) => (a.answeredRate - b.answeredRate) * (sortOrder === 'asc' ? 1 : -1);
    }

    if (chartData) {
        displayedData = [...chartData];
        displayedData.sort(sortFunc);
    }

    return (
        <InsightsSectionWrapper
            header={header}
            imgSrc={QuestionDataImg}
            loading={loading}
            isEmpty={!chartData || !chartData.length || isRandomized}
            customEmptyLabel={isRandomized ? 'Questions and groups have been randomized' : ''}
            audition={audition}
            emptyLabel="question"
        >
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCellHead
                                label="Question"
                                onSort={onSort}
                                sortOrder={sortOrder}
                                sortCategory={{ [sortCategory]: true }}
                                className={clsx(classes.tableCellHead, classes.tableCellHeadQuestion)}
                            />
                            <TableCellHead
                                label="Skill"
                                className={clsx(classes.tableCellHead)}
                                hideSort
                            />
                            <TableCellHead
                                className={clsx(classes.tableCellHead, 'u-pdn--rx5')}
                                label={(
                                    <>
                                        Viewed
                                        <QuestionTooltipBlack
                                            className="u-pdn--tx2 u-pdn--bx2"
                                            label={<>Number of candidates <br />that viewed question</>}
                                        />
                                    </>
                                )}
                                hideSort
                                align="right"
                            />
                            <TableCellHead
                                className={clsx(classes.tableCellHead, classes.tableCellHeadAnswered, 'u-pdn--lx6')}
                                labelComponent={(
                                    <>
                                        Answered
                                        <QuestionTooltipBlack
                                            className="u-pdn--tx2 u-pdn--bx2"
                                            label={<>Number of candidates that <br />answered the question</>}
                                        />
                                    </>
                                )}
                                onSort={onSort}
                                label="Answered"
                                sortCategory={{ [sortCategory]: true }}
                                sortOrder={sortOrder}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {
                            displayedData
                                .map((item) => {
                                    const { question, ...other } = item;
                                    const { id: questionId } = question;
                                    return (
                                        <QuestionTableRow
                                            key={questionId}
                                            question={question}
                                            audition={audition}
                                            {...other}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </InsightsSectionWrapper>
    );
});

export default withStyles(styles)(withRouter(QuestionByQuestion));
