import React from 'react';
import Dialog from 'libraries/Dialog';
import { withStyles } from '@mui/styles';
import styles from './styles';
import CompareAnswers from './CompareAnswers';
import QuestionInfo from './QuestionInfo';

const PlagiarismDialog = ({ open, handleClose, candidateName, plagiarismData }) => (
    <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        titleComponent={
            <div> Similar answers detected </div>
        }
        handleClose={handleClose}
    >
        {plagiarismData.map(el => (
            <>
                <QuestionInfo
                    plagiarismQuestion={el.questionResult}
                />
                {
                    el.plagiarismEntities.map(plagiarismEntity => (
                        <CompareAnswers
                            candidateName={candidateName}
                            plagiarism={plagiarismEntity}
                            key={plagiarismEntity.questionResult.id}
                        />
                    ))
                }
            </>
        )) }
    </Dialog>
);

export default withStyles(styles)(PlagiarismDialog);
