import { createContext } from 'react';
import { action, observable } from 'mobx';
import {
    loadAssessmentStats,
    getNewExtensionsRequests
} from 'requests/AssessmentRequests';
import { getAuditionAiHealthInfo } from 'requests/ActiveLearningRequests';

const getAverageScore = (averageScore, totalCount) => {
    if (!totalCount || averageScore === null) {
        return ({
            value: '-',
            label: 'Average score'
        });
    }
    return ({
        value: `${averageScore}%`,
        label: 'Average score'
    });
};

export const returnStatsArray = (data) => {
    const {
        completionsRate, averageScore,
        inProgressRate, statsCount = {},
        expiredRate, countRealCandidates, avgTimeSpent
    } = data;

    return [{
        value: countRealCandidates,
        label: 'Candidates'
    }, {
        value: statsCount.inProgress,
        percentage: inProgressRate,
        label: 'In progress'
    }, {
        value: statsCount.expired,
        percentage: expiredRate,
        label: 'Expired'
    }, {
        value: statsCount.completed,
        percentage: completionsRate,
        label: 'Completed'
    }, {
        value: `${Math.round(avgTimeSpent / 60)}min`,
        label: 'Avg Completion Time'
    },
    getAverageScore(averageScore, statsCount.completed), {
        value: statsCount.hired,
        label: 'Hired'
    }
    ];
};

class StatsStore {
    @observable loading = true;

    @observable totalCount = null;

    @observable totalCompletionsCount = null;

    @observable shortlistCandidatesCount = null;

    @observable statsArr = [];

    @observable requestedMoreTimeCount = null;

    @observable resetCount = null;

    @observable slug = '';

    @observable isNoCandidates = false;

    @observable activeLearningStatistic = null;

    @observable tagsCount = {};

    @observable statsCount = {};

    @observable extensionRequestedCandidates = [];

    @action loadExtensionsRequests = () => {
        if (!this.slug) return;
        getNewExtensionsRequests(this.slug)
            .then(({ success, data }) => {
                if (success) {
                    this.extensionRequestedCandidates = data;
                }
            })
            .finally(() => {
                this.isLoadingLocal = false;
            });
    };


    @action loadActiveLearning = () => {
        if (!this.slug) return;
        return getAuditionAiHealthInfo(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.activeLearningStatistic = data;
                }
            });
    };

    @action loadAssessmentStats = () => {
        this.loadFullStats();
        this.loadActiveLearning();
    };

    @action loadFullStats = () => {
        if (!this.slug) return;
        return loadAssessmentStats(this.slug)
            .then(({ data, success }) => {
                if (data && success) {
                    this.setStatsData(data);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action setExtensionRequestedCandidates = (data) => {
        this.extensionRequestedCandidates = data;
    }

    @action setLoading = (value) => {
        this.loading = value;
    };

    @action setSlug = (value) => {
        this.slug = value;
    };

    @action increaseShortlistCandidatesCount = (increase = true) => {
        if (increase) { this.shortlistCandidatesCount += 1; } else {
            this.shortlistCandidatesCount -= 1;
        }
    };

    @action setStatsData = (data) => {
        const {
            totalCandidatesCount, shortlistCandidatesCount,
            completionsLeft, limitOfCompletions,
            statsCount = {}, tagsCount = {},
            resetCount, requestedMoreTimeCount,
            totalCompletionsCount
        } = data;

        this.totalCount = totalCandidatesCount;
        this.shortlistCandidatesCount = shortlistCandidatesCount;
        this.statsArr = returnStatsArray(data);
        this.isNoCandidates = !totalCandidatesCount;
        this.completionsLeft = completionsLeft;
        this.limitOfCompletions = limitOfCompletions;
        this.resetCount = resetCount;
        this.requestedMoreTimeCount = requestedMoreTimeCount;
        this.statsCount = statsCount;
        this.tagsCount = tagsCount;
        this.totalCompletionsCount = totalCompletionsCount;
    };

    @action cleanStatsData = () => {
        this.loading = false;
        this.totalCount = null;
        this.shortlistCandidatesCount = null;
        this.statsArr = [];
        this.slug = '';
        this.isNoCandidates = false;
        this.activeLearningStatistic = null;
        this.statsCount = {};
        this.tagsCount = {};
        this.requestedMoreTimeCount = null;
        this.totalCompletionsCount = null;
        this.extensionRequestedCandidates = [];
    }
}

export const statsStore = new StatsStore();
export const statsCtx = createContext(statsStore);
