import React from 'react';
import { withStyles } from '@mui/styles';

import VerticalBarChart from './VerticalBarChart';

const styles = {
    insightsChart: {
        position: 'relative',
        flexBasis: '100%',
        width: '100%',
        borderRadius: 6,
        backgroundColor: '#ffffff',
        padding: '30px 0 0',
        marginBottom: 8
    }
};

const ScoreDistributionChart = ({ classes, tooltipLabel, value }) => (
    <div
        className={classes.insightsChart}
    >
        <VerticalBarChart
            value={value}
            tooltipLabel={tooltipLabel}
        />
    </div>
);

export default withStyles(styles)(ScoreDistributionChart);
