import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';
import { CircularProgress } from '@mui/material';
import AssessmentEvents from 'events/AssessmentEvents';

import { getInsightsCompletions, exportInsightsCompletions } from 'requests/AssessmentRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import DownloadImg from 'img/download.svg';

import InsightsSectionWrapper from '../InsightsSectionWrapper';
import DatePicker from './DatePicker';
import Chart from './Chart';
import EmptyDataBlock from '../InsightsSectionWrapper/EmptyDataBlock';

import CompletionImg from './completion.svg';

const styles = theme => ({
    averageScore: {
        color: theme.palette.primary.main
    },
    select: {
        textTransform: 'capitalize',
        width: 235
    },
    button: {
        whiteSpace: 'nowrap',
        color: theme.palette.grey[500],
        fontWeight: 700,
        marginRight: 5,
        textTransform: 'capitalize',
        '&:last-of-type': {
            marginRight: 15
        }
    },
    buttonActive: {
        color: theme.palette.primary.main
    },
    exportButton: {
        minWidth: 145,
        fontWeight: 700
    }
});

const InsightsScoreDistributions = observer(({ classes, audition }) => {
    const [dateRange, setDateRange] = useState([moment().subtract(7, 'd').format(), moment().format()]);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [selectedDateSection, setSelectedDateSection] = useState(1);
    const [isCustomRange, setIsCustomRange] = useState(true);
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [transactionData, setTransactionData] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [dateRange]);

    const csvLink = useRef();

    const { name: ttName, uuid: ttId, slug } = audition;

    const getData = () => {
        setLoading(true);
        getInsightsCompletions(slug, dateRange)
            .then(({ success, data }) => {
                if (success) {
                    setChartData(data);
                }
            })
            .finally(() => {
                setIsFirstLoading(false);
                setLoading(false);
            });
    };

    const exportCompletions = () => {
        setExportLoading(true);
        exportInsightsCompletions(slug, dateRange)
            .then((data) => {
                AssessmentEvents.INSIGHTS_COMPLETIONS_DOWNLOADED({ ttId, ttName });
                setTransactionData(data);
                csvLink.current.link.click();
            })
            .finally(() => {
                setExportLoading(false);
            });
    };

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Candidate Completions
            <QuestionTooltipBlack
                className="u-pdn--tx2 u-pdn--bx2"
                label={(<>
                    Completion chart show numbers<br />of completed assessments by<br />candidates over time
                    </>
                )}
            />
        </Typography>
    );

    const onClick = (value) => {
        let d = new Date();
        if (value === 0) {
            AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({ ttId, ttName, filterType: 'all time' });
            setIsCustomRange(false);
        }
        if (value === 1) {
            AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({ ttId, ttName, filterType: 'last 7 days' });
            d = moment().subtract(7, 'd').format();
            setIsCustomRange(true);
        }
        if (value === 2) {
            AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({ ttId, ttName, filterType: 'last 30 days' });
            d = moment().subtract(30, 'd').format();
            setIsCustomRange(true);
        }

        setSelectedDateSection(value);
        setDateRange(value === 0 ? [null, null] : [d, (moment()).format()]);
    };

    const handleDateRange = (value) => {
        const newValue = [...value];
        if (!moment(value[1]).isValid()) {
            newValue[1] = new Date();
        }
        const toDate = new Date(newValue[1]);
        const toDateFormatted = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59);

        newValue[1] = moment(toDateFormatted).format();

        AssessmentEvents.INSIGHTS_COMPLETIONS_FILTERED({
            ttId,
            ttName,
            filterType: 'selected date',
            customDate: {
                from: newValue[0],
                to: newValue[1]
            }
        });
        setSelectedDateSection(null);
        setIsCustomRange(true);
        setDateRange(newValue);
    };

    const options = ['All time', 'Last 7 days', 'Last 30 days'];

    return (
        <InsightsSectionWrapper
            header={header}
            rightPart={!isFirstLoading ? (
                <>
                    {
                        options.map((item, index) => (
                            <Button
                                key={item}
                                className={clsx(classes.button, selectedDateSection === index && classes.buttonActive)}
                                onClick={() => onClick(index)}
                            >
                                {item}
                            </Button>
                        ))
                    }
                    <DatePicker
                        dateRange={dateRange}
                        setDateRange={handleDateRange}
                    />
                </>
            ) : null}
            imgSrc={CompletionImg}
            loading={loading}
            isEmpty={!chartData && !isCustomRange}
            audition={audition}
            emptyLabel="completion"
        >
            {
                chartData ? (
                    <Chart
                        value={chartData}
                        tooltipLabel="Completion"
                    />
                ) : (
                    <EmptyDataBlock
                        customHeader="No completions on selected dates"
                        imgSrc={CompletionImg}
                        audition={audition}
                        customEmptyLabel="Select another date range"
                    />
                )
            }
            <div className="u-txt--right">
                <Button
                    disabled={exportLoading}
                    color="primary"
                    className={classes.exportButton}
                    onClick={exportCompletions}
                >
                    {
                        !exportLoading ? (
                            <>
                                <img className="u-mrg--rx1" src={DownloadImg} alt="|" />
                                Download CSV
                            </>
                        ) : (
                            <CircularProgress size={20} color="primary" />
                        )
                    }
                </Button>
                <CSVLink
                    data={transactionData}
                    filename={`${ttName}-completions-${new Date().toISOString().slice(0, 10)}.csv`}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
            </div>
        </InsightsSectionWrapper>
    );
});

export default withStyles(styles)(withRouter(InsightsScoreDistributions));
