import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import DownloadImg from 'img/download.svg';
import styles from '../../styles';

const DownloadButton = ({ classes, onClick }) => (
    <div className="u-dsp--f u-ai--center u-jc--end u-mrg--tx2">
        <Button
            className={classes.downloadButton}
            onClick={onClick}
        >
            <img className="u-mrg--rx1" src={DownloadImg} alt="|" />
            Download CSV
        </Button>
    </div>
);

export default withStyles(styles)(DownloadButton);
