import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { SCORE_TYPE_AI, SEND_SMS_INVITATION } from 'helper/constants';
import CandidateMenu from 'components/candidate_cards/CandidateActions/CandidateMenu';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import CandidatePhotoEmail from './CandidatePhotoEmail';
import RejectShortlistActions from './RejectShortlistActions';

import useStyles from './styles';

const CandidatePhotoInfo = observer(({
    user, setUser, handleCloseCard,
    updateUserFields, getUserInfo,
    showHireBanner, setShowHireBanner,
    increaseShortlistCandidatesCount,
    audition, opportunitySlug, company, context,
    propPublic, hideCandidateDetails, getUserProfile,
    isIntegration, onHireChanges
}) => {
    const classes = useStyles();
    const { scoreType, uuid: ttId, name: ttName, active } = audition;
    const {
        candidateUuid, rank, score: scoreObj, candidateStatus,
        shortlisted, rejected
    } = user;
    const hired = candidateStatus === 'Hired';

    const dataForEvents = {
        candidateUUID: candidateUuid,
        context,
        ttId,
        ttName,
        score: scoreObj ? scoreObj.score : undefined,
        scoreIsAI: scoreObj ? scoreObj.type === SCORE_TYPE_AI : false,
        scoreType,
        rank,
        candidateHired: candidateStatus === 'Hired'
    };

    if (hideCandidateDetails) {
        return (
            <div className={classes.root}>
                <div className={classes.hiddenBlock} />
                <div className={clsx(classes.hiddenBlock, classes.hiddenBlockShorter)} />
            </div>
        );
    }

    const shownOptions = ['message', 'extend', 'feedback'];

    if (checkHasCompanyFeature(company, SEND_SMS_INVITATION)) {
        shownOptions.push('details');
    }

    return (
        <div className={classes.root}>
            <div className={clsx(classes.wrapper)}>
                <CandidatePhotoEmail
                    user={user}
                    hideCandidateDetails={hideCandidateDetails}
                />
                {
                    (!propPublic && !isIntegration) && (
                        <CandidateMenu
                            candidate={user}
                            company={company}
                            shownOptions={shownOptions}
                            resetAuditionCallback={handleCloseCard}
                            callback={getUserInfo}
                            dataForEvents={dataForEvents}
                            audition={audition}
                            opportunitySlug={opportunitySlug}
                            getUserProfile={getUserProfile}
                        />
                    )
                }
            </div>
            {
                (!propPublic && !isIntegration && (active || (rejected || shortlisted || hired))) && (
                    <RejectShortlistActions
                        showHireBanner={showHireBanner}
                        setShowHireBanner={setShowHireBanner}
                        user={user}
                        setUser={setUser}
                        getUserInfo={getUserInfo}
                        updateUserFields={updateUserFields}
                        audition={audition}
                        context={context}
                        propPublic={propPublic}
                        opportunitySlug={opportunitySlug}
                        increaseShortlistCandidatesCount={increaseShortlistCandidatesCount}
                        onHireChanges={onHireChanges}
                    />
                )
            }
        </div>
    );
});

export default CandidatePhotoInfo;
