import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';
import ScoringTips from '../../candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/ScoringTips';
import BottomContainer
from '../../candidate_cards/CandidateCardWrapper/GradeBlock/components/QuestionsContent/BottomContainer';
import AnswerContent from '../AnswerContent';
import { QA_USER_STORY, QA_TEST_CASE, QA_TEST_RUN, QA_EDIT_BUG, QA_CREATE_BUG } from '../../../helper/constants';
import TestRun from './QaTestRun';
import QaTicketHeader from './QaTicketHeader';

const useStyles = makeStyles(theme => ({
    testCaseOrder: {
        minWidth: 30,
        backgroundColor: theme.palette.blue[900],
        borderRadius: 8,
        color: 'white'
    },
    testCaseBlock: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 8,
        padding: 20
    },
    scoreText: {
        color: theme.palette.blue[500]
    },
    radioButtons: {
        '& input[type="radio"]+span': {
            color: `${theme.palette.blue[300]}!important`
        }
    }
}));

const TaskAnswerContent = ({
    userName = '', setGradeQuestion,
    candidate, audition, propPublic,
    updateUserAuditionAndBox, canGrade, currentTask,
    isIntegration, questionNumber, saveGrade, currentGrade, gradeLoading, questionCount,
    goToPreviousQuestion, goToNextQuestion, gradeableCard, taskNumber, candidateTasks
}) => {
    const currentQuestionIndex = questionNumber + 1;
    const { type, questionResults, qaSimulationTaskMetaData } = currentTask;
    const { testCaseOrder, testCaseBlock, scoreText, radioButtons } = useStyles();

    const taskScore = grade => (
        <div className={clsx(scoreText, 'u-txt--right u-txt--nowrap u-txt--bold u-mrg--tx2')}>
            Question score: {grade || 0} of 10 points
        </div>
    );

    switch (type) {
        case QA_CREATE_BUG:
            return (
                <>
                    <QaTicketHeader
                        ticketNumber={qaSimulationTaskMetaData.ticketNumber}
                        taskType={QA_CREATE_BUG}
                        questionResults={questionResults}
                    />
                    {
                        questionResults.map((taskQuestionResult, idx) => (
                            <div className="u-mrg--bx3" key={idx}>
                                <div className="u-mrg--bx2">
                                    {ReactHtmlParser(taskQuestionResult.question.description)}
                                </div>
                                <AnswerContent
                                    canGrade={canGrade}
                                    audition={audition}
                                    candidate={candidate}
                                    question={taskQuestionResult}
                                    questionIndex={questionNumber}
                                    userName={userName}
                                    setGradeQuestion={setGradeQuestion}
                                    propPublic={propPublic}
                                    updateUserAuditionAndBox={updateUserAuditionAndBox}
                                    isIntegration={isIntegration}
                                />
                                <BottomContainer
                                    withHeader={false}
                                    canGrade={canGrade}
                                    saveGrade={value => saveGrade(value, taskQuestionResult.id, idx)}
                                    question={taskQuestionResult}
                                    currentGrade={(gradeableCard || !propPublic) ? taskQuestionResult.userGrade : taskQuestionResult.grade}
                                    gradeLoading={gradeLoading === taskQuestionResult.id}
                                    totalCount={questionCount}
                                    activeAssessment={audition?.active}
                                    {...{ gradeableCard, propPublic }}
                                />
                            </div>
                        ))
                    }
                </>
            );
        case QA_EDIT_BUG:
            return (
                <>
                    <QaTicketHeader
                        ticketNumber={qaSimulationTaskMetaData.ticketNumber}
                        taskType={QA_EDIT_BUG}
                        questionResults={questionResults}
                    />
                    {
                        questionResults.map((taskQuestionResult, idx) => (
                            <div className={clsx(radioButtons, 'u-mrg--bx3')}>
                                <div className="u-mrg--bx2" key={idx}>
                                    {ReactHtmlParser(taskQuestionResult.question.description)}
                                </div>
                                <AnswerContent
                                    canGrade={canGrade}
                                    audition={audition}
                                    candidate={candidate}
                                    question={taskQuestionResult}
                                    questionIndex={questionNumber}
                                    userName={userName}
                                    setGradeQuestion={setGradeQuestion}
                                    propPublic={propPublic}
                                    updateUserAuditionAndBox={updateUserAuditionAndBox}
                                    isIntegration={isIntegration}
                                    hideScoreLabel
                                />
                                {taskScore(taskQuestionResult.grade)}
                            </div>
                        ))
                    }
                </>
            );
        case QA_TEST_RUN:
            return (
                <>
                    <QaTicketHeader
                        ticketNumber={qaSimulationTaskMetaData.ticketNumber}
                        taskType={QA_TEST_RUN}
                        questionResults={questionResults}
                    />
                    <div className="u-mrg--bx2">
                        {ReactHtmlParser(questionResults[0].question.description)}
                    </div>
                    <TestRun answerResults={questionResults[1].answerResults} />
                    {taskScore(questionResults[1].grade)}
                </>
            );
        case QA_TEST_CASE:
            // eslint-disable-next-line no-case-declarations
            const testCaseGrade = currentTask.questionResults.find(result => result.grade)?.grade;
            return (
                <>
                    <QaTicketHeader
                        ticketNumber={qaSimulationTaskMetaData.ticketNumber}
                        taskType={QA_TEST_CASE}
                        questionResults={questionResults}
                    />
                    {
                        questionResults.map((taskQuestionResult, idx) => (
                                <>
                                    <div className="u-mrg--bx2">
                                        {ReactHtmlParser(taskQuestionResult.question.description)}
                                    </div>
                                    {taskQuestionResult.question.qaSimulationTestCase && (
                                        (
                                            <div className={clsx(radioButtons, 'u-dsp--f u-mrg--bx3')} key={idx}>
                                                <div className={clsx(testCaseOrder, 'u-dsp--f u-jc--center u-mrg--rx2 u-pdn--tx1')}>{idx - 1}</div>
                                                <div className="u-dsp--f u-fdir--column u-wdt--100p">
                                                    <div className={clsx(testCaseBlock, 'u-mrg--bx2')}>
                                                        <div className="u-txt--bold u-mrg--bx2">Action</div>
                                                        {taskQuestionResult.question.qaSimulationTestCase.action}
                                                    </div>
                                                    <div className={testCaseBlock}>
                                                        <div className="u-txt--bold u-mrg--bx2">Expected Result</div>
                                                        {taskQuestionResult.question.qaSimulationTestCase.expectedResult || (
                                                            <AnswerContent
                                                                canGrade={canGrade}
                                                                audition={audition}
                                                                candidate={candidate}
                                                                question={taskQuestionResult}
                                                                questionIndex={questionNumber}
                                                                userName={userName}
                                                                setGradeQuestion={setGradeQuestion}
                                                                propPublic={propPublic}
                                                                updateUserAuditionAndBox={updateUserAuditionAndBox}
                                                                isIntegration={isIntegration}
                                                                hideScoreLabel
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </>
                        ))
                    }
                    {taskScore(testCaseGrade)}
                </>
            );
        case QA_USER_STORY:
            return (
                <>
                    <QaTicketHeader
                        ticketNumber={qaSimulationTaskMetaData.ticketNumber}
                        shownTitle={false}
                        taskType={QA_USER_STORY}
                        questionResults={questionResults}
                    />
                    {
                        questionResults.map(taskQuestionResult => (
                            <>
                                <div key={taskQuestionResult.id}>
                                    {ReactHtmlParser(taskQuestionResult.question.description)}
                                </div>
                                <br />
                            </>
                        ))
                    }
                    <hr />
                    <AnswerContent
                        canGrade={canGrade}
                        audition={audition}
                        candidate={candidate}
                        question={questionResults[1]}
                        questionIndex={questionNumber}
                        userName={userName}
                        setGradeQuestion={setGradeQuestion}
                        propPublic={propPublic}
                        updateUserAuditionAndBox={updateUserAuditionAndBox}
                        isIntegration={isIntegration}
                    />
                </>
            );
        default:
            return questionResults.map(taskQuestionResult => (
                <div key={taskQuestionResult.id}>
                    <hr />
                    <ScoringTips isTask question={taskQuestionResult} />
                    <hr />
                    <AnswerContent
                        canGrade={canGrade}
                        audition={audition}
                        candidate={candidate}
                        question={taskQuestionResult}
                        questionResults={questionResults}
                        questionIndex={questionNumber}
                        userName={userName}
                        setGradeQuestion={setGradeQuestion}
                        propPublic={propPublic}
                        updateUserAuditionAndBox={updateUserAuditionAndBox}
                        isIntegration={isIntegration}
                    />
                    <br />
                    <br />
                    <BottomContainer
                        canGrade={canGrade}
                        saveGrade={saveGrade}
                        question={taskQuestionResult}
                        currentGrade={currentGrade}
                        gradeLoading={gradeLoading}
                        totalCount={questionCount}
                        questionNumber={currentQuestionIndex}
                        goToPreviousQuestion={goToPreviousQuestion}
                        goToNextQuestion={goToNextQuestion}
                        previousQuestionDisabled={questionNumber === 0 && taskNumber === 0}
                        nextQuestionDisabled={questionNumber === questionCount - 1 && taskNumber === candidateTasks.length - 1}
                        activeAssessment={audition?.active}
                        {...{ gradeableCard, propPublic }}
                    />
                </div>
            ));
    }
};

export default TaskAnswerContent;
