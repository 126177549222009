import React from 'react';
import queryString from 'query-string';
import { withStyles } from '@mui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link, withRouter } from 'react-router-dom';

import { passwordValidateFunction } from 'helper/validationFunctions';
import Checkbox from 'libraries/Checkbox';
import { getPublicCompany } from 'requests/APIrequests';
import { registerEmployer } from 'requests/LoginRequests';
import CompanyLogo from 'components/logos/company_logo';
import AuthEvents from 'events/AuthEvents';
import OnboardingEvents from 'events/OnboardingEvents';

import TextInputOutlinedValidated from 'libraries/TextInputValidated';
import uuidv4 from 'uuid/v4';
import InputAdornment from '@mui/material/InputAdornment';
import OnBoardingButton from '../components/OnBoardingButton';
import RegistrationSidebarWrapper from '../../common/RegistrationSidebarWrapper';
import HomepageWrapper from '../../common/HomepageWrapper';
import Businesses from './businesses.svg';
import HidePasswordIcon from './hidePassword.svg';
import ShowPasswordIcon from './showPassword.svg';

import styles from './styles';

const MIN_COMPANY_NAME_LENGTH = 2;
const MAX_COMPANY_NAME_LENGTH = 100;
const MAX_FULL_NAME_LENGTH = 160;
const MAX_EMAIL_LENGTH = 180;

let fillCompanyDetailsStarted = false;

const baseURL = process.env.REACT_APP_BASE_URL;

const WrapperComponent = ({ children, invitingCompany, isPremium, isTrial }) => (invitingCompany ? (
    <HomepageWrapper>{children}</HomepageWrapper>
) : (
    <RegistrationSidebarWrapper isPremium={isPremium} isTrial={isTrial}>{children}</RegistrationSidebarWrapper>
));


class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.passwordLength = 8;
        this.nameLength = 2;

        this.state = {
            startSignUp: false,
            form: {
                terms: true,
                newsletters: false,
                company: '',
                email: '',
                hashCode: '',
                fullName: '',
                whoInvited: '',
                timeZone: null
            },
            errorForm: {
                email: '',
                fullName: '',
                password: '',
                company: ''
            },
            disableField: false,
            seekConfig: false,
            invitingCompany: null,
            loadingStarted: false,
            showPassword: false
        };
    }

    changeFormState = () => {
        const { startSignUp } = this.state;
        if (!startSignUp) {
            OnboardingEvents.START_SIGNUP();
            this.setState({ startSignUp: true });
        }
    };

    componentDidMount() {
        const { form } = this.state;
        const { location, teamMemberLink } = this.props;
        const queryParams = queryString.parse(location.search);
        queryParams.email = queryParams.email ? (queryParams.email).trim().replace(' ', '+') : false;

        form.email = queryParams.email;
        form.company = queryParams.company;
        form.fullName = (queryParams.firstName || queryParams.lastName) && `${queryParams.firstName || ''} ${queryParams.lastName || ''}`;
        form.hashCode = queryParams.hashCode;
        form.uuid = uuidv4();
        form.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (queryParams.email && queryParams.hashCode && queryParams.company) {
            this.setState({ disableField: true });
        }

        this.setState({
            form,
            whoInvited: `${queryParams.ownerFirstName || ''} ${queryParams.ownerLastName || ''}`,
            seekConfig: location.pathname === '/registration/seek'
        });

        if (teamMemberLink) {
            this.getCompanyLogo();
        }

        this.addValidationsOnFields();

        const link = document.createElement('link');
        link.id = 'canonical';
        link.rel = 'canonical';
        link.href = 'https://app.vervoe.com/registration';
        document.head.append(link);
    }

    componentWillUnmount() {
        const link = document.getElementById('canonical');
        link.remove();
    }

    onFocusCompany = () => {
        if (!fillCompanyDetailsStarted) {
            fillCompanyDetailsStarted = true;
            OnboardingEvents.FILL_COMPANY_DETAILS_STARTED();
        }
    };

    getCompanyLogo = () => {
        getPublicCompany()
            .then(({ success, company }) => {
                if (success) {
                    this.setState({ invitingCompany: company });
                }
            });
    };

    addValidationsOnFields = () => {
        ValidatorForm.addValidationRule('isTruthy', value => value);
        ValidatorForm.addValidationRule('noPlusInside', this.checkIfNoPlusInside);
        ValidatorForm.addValidationRule('trimMinStringLength', value => value.trim().length >= MIN_COMPANY_NAME_LENGTH);
        ValidatorForm.addValidationRule('trimMaxStringLength', value => value.trim().length <= MAX_COMPANY_NAME_LENGTH);
        ValidatorForm.addValidationRule('trimMaxFullNameLength', value => value.trim().length <= MAX_FULL_NAME_LENGTH);
        ValidatorForm.addValidationRule('trimMaxEmailLength', value => value.trim().length <= MAX_EMAIL_LENGTH);
    };

    checkIfNoPlusInside = (value) => {
        const hasPlus = new RegExp('\\+').test(value);
        return !hasPlus || (hasPlus && new RegExp('vervoe.com').test(value));
    };

    renderError = formParam => this.state.errorForm[formParam] || '';

    renderValue = (value) => {
        const { form } = this.state;
        if (form && (form[value] || form[value] === 0)) {
            return form[value];
        }
        return '';
    };

    handleChange = (e) => {
        const { form } = this.state;
        const targetName = e.target.name;
        let targetValue = e.target.value;
        if (targetName === 'terms' || targetName === 'newsletters') {
            targetValue = !form[targetName];
        }
        if (targetName === 'password') {
            this.customPasswordValidate(e); // validate password simultaneously
        } else if (targetName === 'email' || targetName === 'company') {
            this.setState({ errorForm: { [targetName]: '' } });
            this.validate(e);
        }

        const updateForm = { ...form, [targetName]: targetValue };
        this.setState({
            form: updateForm
        });
    };

    formNotValid = (errors) => {
        const { form } = this.state;
        OnboardingEvents.SUBMIT_SIGNUP(form);
        OnboardingEvents.FAILED_SUBMIT_SIGNUP(errors.map(item => item.getErrorMessage()));
    };

    register = (e) => {
        const { form, seekConfig, errorForm } = this.state;
        e.preventDefault();
        if (errorForm.password && errorForm.password.length) return;
        OnboardingEvents.SUBMIT_SIGNUP(form);
        const { location, flashMessage, history } = this.props;
        const parsed = queryString.parse(location.search);
        const seekConfigObj = seekConfig ? { referral: 'SEEK' } : null;
        const eventObj = {
            company: form.company
        };
        OnboardingEvents.FILL_COMPANY_DETAILS_SAVED(eventObj);
        this.setState({ loadingStarted: true });

        registerEmployer(
            Object.assign(
                {}, form,
                {
                    company: {
                        name: form.company,
                        referral: seekConfigObj,
                        timeZone: form.timeZone,
                        planGroupUuid: location.state?.isPremium ? '460861bb-1ea1-45a9-963f-20a893e672a7' : null
                    }
                }
            ),
            'redeem' in parsed
        )
            .then(({ data, success }) => {
                if (success) {
                    const { companySlug, refreshToken, confirmed, emailConfirmationExpiresAfterMinutes } = data;
                    const stringified = queryString.stringify(parsed);

                    // FirstPromoter Signup Tracking Code
                    window.$FPROM.trackSignup({
                        email: data.user.email,
                        first_name: data.user.firstName,
                        last_name: data.user.lastName
                    }, () => { console.log('Signup data received'); });

                    AuthEvents.IDENTIFY(data.user.uuid, data.userSegmentTraits);

                    AuthEvents.CREATE_ACCOUNT({
                        name: `${data.user.firstName} ${data.user.lastName}`,
                        email: data.user.email,
                        assessmentName: parsed.assessmentName
                    });

                    if (seekConfig) {
                        AuthEvents.SEEK_REGISTRATION();
                    }

                    if (confirmed && refreshToken) { // this is a team member and there is no need to verify email
                        const { protocol, port } = window.location;

                        localStorage.setItem('refreshToken', refreshToken);
                        localStorage.setItem('companySlug', companySlug);

                        const strLocationState = queryString.stringify(location.state);

                        window.location = `${protocol}//${companySlug}.${baseURL}${port ? `:${port}` : ''}/welcome?token=${refreshToken}&${strLocationState}${stringified}`;
                        localStorage.removeItem('refreshToken');
                    } else {
                        history.push(`/code-verification?${stringified}`, {
                            enteredEmail: form.email,
                            companySlug,
                            initialLocation: location,
                            emailConfirmationExpiresAfterMinutes
                        });
                    }
                }
            })
            .catch(({ response }) => {
                if (response && response.status && response.status === 403) flashMessage('Your IP address is restricted.', 'error');
                if (response && response.data && response.data.errors) {
                    OnboardingEvents.FAILED_SUBMIT_SIGNUP(response.data.errors);
                    const { 'company.name': companyName, email, firstName, password } = response.data.errors;
                    const emailErr = email && email[0] ? email[0].message : '';
                    const fullNameErr = firstName && firstName[0] ? firstName[0].message : '';
                    const passwordErr = password && password[0] ? password[0].message : '';
                    const companyNameErr = companyName && companyName[0] ? companyName[0].message : '';
                    this.setState({
                        errorForm: {
                            email: emailErr,
                            fullName: fullNameErr,
                            password: passwordErr,
                            company: companyNameErr
                        }
                    });
                }
            })
            .finally(() => {
                this.setState({ loadingStarted: false });
            });
    };

    validate = (e) => {
        this.refs[e.target.name].validate(e.target.value);
    };

    customPasswordValidate = (e) => {
        const { value } = e.target;
        const { errorForm } = this.state;
        const newErrorForm = JSON.parse(JSON.stringify(errorForm));
        newErrorForm.password = passwordValidateFunction(value, this.passwordLength);
        this.setState({ errorForm: newErrorForm });
    };

    render() {
        const {
            seekConfig,
            disableField,
            whoInvited,
            invitingCompany,
            loadingStarted,
            showPassword
        } = this.state;
        const {
            classes,
            buttonLabel,
            teamMemberLink,
            location,
            isTrial
        } = this.props;

        if (teamMemberLink && invitingCompany === null) {
            return null;
        }

        const isPremium = location.state?.isPremium;

        return (
            <WrapperComponent invitingCompany={invitingCompany} isPremium={isPremium} isTrial={isTrial}>
                <div className={classes.wrapper}>
                    <div className={classes.contentWrapper}>
                        <h1 className={`${classes.header} ${classes.registrationPageHeader}`}>
                            {invitingCompany ? 'Create account and join the team!' : 'Get started for free!'}
                        </h1>
                        {
                            whoInvited && invitingCompany
                                && (
                                    <div className={classes.invitationInfo}>
                                        <CompanyLogo
                                            company={invitingCompany}
                                            size={75}
                                            className={classes.companyLogo}
                                        />
                                        <div className={classes.subheader}>
                                            <strong>{whoInvited}</strong> invited you to join the <strong>{this.renderValue('company')}</strong> team.
                                        </div>
                                    </div>
                                )
                        }
                        <div className={classes.content}>
                            <ValidatorForm
                                onSubmit={this.register}
                                instantValidate={false}
                                onError={this.formNotValid}
                                id="registrationForm"
                            >
                                {!teamMemberLink && (
                                    <TextInputOutlinedValidated
                                        label="Company name"
                                        placeholder="e.g SpaceTech"
                                        onChange={this.handleChange}
                                        name="company"
                                        ref="company"
                                        variant="outlined"
                                        onFocus={this.onFocusCompany}
                                        formerror={this.renderError('company')}
                                        value={this.renderValue('company')}
                                        requirements={['Include at least two characters']}
                                        validators={['required', `trimMinStringLength:${MIN_COMPANY_NAME_LENGTH}`, `trimMaxStringLength:${MAX_COMPANY_NAME_LENGTH}`]}
                                        errorMessages={['Please enter your company name.', `Minimum ${MIN_COMPANY_NAME_LENGTH} characters`, `Maximum ${MAX_COMPANY_NAME_LENGTH} characters`]}
                                    />
                                )}
                                <TextInputOutlinedValidated
                                    variant="outlined"
                                    onClick={this.changeFormState}
                                    label="Work email"
                                    placeholder="e.g kate@hooli.com"
                                    onChange={this.handleChange}
                                    onBlur={this.validate}
                                    ref="email"
                                    name="email"
                                    disabled={disableField}
                                    formerror={this.renderError('email')}
                                    value={this.renderValue('email')}
                                    validators={['required', 'isEmail', 'noPlusInside', `trimMaxEmailLength:${MAX_EMAIL_LENGTH}`]}
                                    errorMessages={[
                                        'Please enter a valid work email e.g kate@hooli.com.',
                                        'Please enter a valid work email e.g kate@hooli.com.',
                                        'Please enter a work email address.',
                                        `Maximum ${MAX_EMAIL_LENGTH} characters`
                                    ]}
                                />
                                <TextInputOutlinedValidated
                                    variant="outlined"
                                    onClick={this.changeFormState}
                                    label="Full name"
                                    placeholder="e.g Kate Smith"
                                    onChange={this.handleChange}
                                    name="fullName"
                                    ref="fullName"
                                    onBlur={this.validate}
                                    formerror={this.renderError('fullName')}
                                    value={this.renderValue('fullName')}
                                    validators={[
                                        'required',
                                        `trimMinStringLength:${this.nameLength}`,
                                        `trimMaxFullNameLength:${MAX_FULL_NAME_LENGTH}`
                                    ]}
                                    errorMessages={[
                                        'Please enter your full name.',
                                        `Minimum ${this.nameLength} characters`,
                                        `Maximum ${MAX_FULL_NAME_LENGTH} characters`
                                    ]}
                                />
                                <TextInputOutlinedValidated
                                    className={classes.adornedEnd}
                                    onClick={this.changeFormState}
                                    label="Create a password"
                                    variant="outlined"
                                    placeholder="New password (8 chars)"
                                    ref="password"
                                    type={showPassword ? '' : 'password'}
                                    name="password"
                                    onFocus={this.customPasswordValidate}
                                    onBlur={this.validate}
                                    onChange={this.handleChange}
                                    formerror={this.renderError('password')}
                                    value={this.renderValue('password')}
                                    validators={['required']}
                                    errorMessages={[
                                        'Please enter your password.'
                                    ]}
                                    endAdornment={(
                                        <InputAdornment position="end" classes={{ root: classes.showPasswordButton }}>
                                            <div
                                                role="presentation"
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                                onMouseDown={(e) => { e.preventDefault(); }}
                                            >
                                                {showPassword
                                                    ? <><img src={HidePasswordIcon} alt="hide" className={classes.hideIcon} />Hide</>
                                                    : <><img src={ShowPasswordIcon} alt="show" className={classes.showIcon} />Show</>}
                                            </div>
                                        </InputAdornment>
                                    )}
                                />
                                <Checkbox
                                    className={classes.checkbox}
                                    onClick={this.changeFormState}
                                    name="newsletters"
                                    label="Send me tips, trends, updates and offers."
                                    onChange={this.handleChange}
                                    value={Boolean(this.renderValue('newsletters'))}
                                    checked={Boolean(this.renderValue('newsletters'))}
                                />
                                <p>By signing up you agree to&nbsp;
                                    <a
                                        href="https://vervoe.com/terms-of-use"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.link}
                                    >
                                        Vervoe's Terms of Use
                                    </a>
                                    <br />
                                    and&nbsp;
                                    <a
                                        href="https://vervoe.com/global-privacy-policy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.link}
                                    >
                                        Privacy Policy
                                    </a>
                                </p>
                                {seekConfig && <span>By clicking “Create account” I recognize that my behavior on Vervoe will be shared with SEEK Ltd.</span>}
                                <OnBoardingButton
                                    type="submit"
                                    hideIcon
                                    disabled={loadingStarted}
                                    loading={loadingStarted}
                                >
                                    {buttonLabel}
                                </OnBoardingButton>
                            </ValidatorForm>
                            <div className={classes.loginLabel}>
                                Already have an account? <Link to={{ pathname: '/login', state: { isPremium } }} className={classes.link} color="primary">Sign in</Link>
                            </div>
                            <p style={{ color: '#A9AAAE' }}>loved by businesses like yours</p>
                            <img src={Businesses} alt="Walmart AustraliaPost Chegg Myob" />
                        </div>
                    </div>
                </div>
            </WrapperComponent>
        );
    }
}

export default withRouter(withStyles(styles)(Registration));
