import React from 'react';
import AssessmentSettingsWrapper from 'components/assessments_pages/AssessmentSettingsWrapper';
import { loadContentLibraryAssessment, editContentLibraryAssessment } from 'requests/CMSRequests';

import TTSettings from 'components/assessments_pages/asessment_settings_components/TTSettings';
import LimitSettings from 'components/assessments_pages/asessment_settings_components/LimitSettings';
import DeadlineSettings from 'components/assessments_pages/asessment_settings_components/DeadlineSettings';
import SettingsFieldChange from 'components/assessments_pages/asessment_settings_components/SettingsFieldChange';
import PanelHeader from 'components/assessments_pages/asessment_settings_components/SectionHeader';

import SkillsRandomise from './components/SkillsRandomise';
import PopularSettings from './components/PopularSettings';
import CategoriesSelect from './components/CategoriesSelect';
import DeleteAssessment from './components/DeleteAssessment';
import EquipmentCheckSettings from './components/EquipmentCheckSettings';
import LevelSelect from './components/LevelSelect';
import AssessmentTags from './components/AssessmentTags';
import OnboardingAssessment from './components/OnboardingAssessment';


const CreateScriptSettings = ({ match, setSaving, audition, setLoading, setAudition }) => {
    const { auditionUuid } = match.params;

    const loadAuditionScript = () => (
        loadContentLibraryAssessment(auditionUuid)
            .then(({ success, data }) => ({ success, data: { ...data, description: data.settings.description, name: data.settings.name } }))
    );

    const loadAssessmentSettings = () => (
        loadContentLibraryAssessment(auditionUuid)
            .then(({ success, data }) => ({ success, data: { ...data.settings, uuid: data.uuid } }))
    );

    const saveAssessmentSettingsScript = form => editContentLibraryAssessment(auditionUuid, form)
        .then(({ success, data }) => {
            setAudition(data);
            return ({ success, data: data.settings });
        });

    return (
        <AssessmentSettingsWrapper
            isCMS
            setLoading={setLoading}
            setSaving={setSaving}
            audition={audition}
            setAudition={setAudition}
            loadAudition={loadAuditionScript}
            loadAssessmentSettings={loadAssessmentSettings}
            saveAssessmentSettings={saveAssessmentSettingsScript}
            leftPanel={(
                <>
                    <TTSettings />
                    <LimitSettings />
                    <SkillsRandomise setAuditionProps={setAudition} />
                    <PopularSettings />
                    <AssessmentTags />
                    <OnboardingAssessment />
                    <EquipmentCheckSettings />
                    <DeadlineSettings />
                    <DeleteAssessment />
                </>
            )}
            rightPanel={(
                <>
                    <SettingsFieldChange
                        maxLength={100}
                        label="Assessment Name"
                        field="name"
                        className="u-mrg--bx3 u-mrg--tx5"
                    />
                    <CategoriesSelect />
                    <LevelSelect />
                    <SettingsFieldChange
                        maxLength={500}
                        hasCounter
                        field="summary"
                        label="Assessment Summary"
                        multiline
                    />
                    <SettingsFieldChange
                        maxLength={1000}
                        hasCounter
                        notRequired
                        field="skillsTestedFlavorText"
                        label="Skills tested in this assessment"
                        multiline
                        minLength={1}
                    />
                    <SettingsFieldChange
                        maxLength={1000}
                        hasCounter
                        minLength={1}
                        notRequired
                        field="whatToTestWithFlavorText"
                        label="What to test with this assessment"
                        multiline
                    />
                    <PanelHeader>Instruction to candidates</PanelHeader>
                    <p className="u-mrg--tx3">
                        The instructions will appear in the email invitation and the assessment home screen for your candidates.
                    </p>
                    <SettingsFieldChange
                        maxLength={300}
                        hasCounter
                        minLength={1}
                        notRequired
                        field="description"
                        label="Instructions to Candidate"
                        multiline
                    />
                </>
            )}
        />
    );
};

export default CreateScriptSettings;
