import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { CODE_TEST_AUTOGRADED, SIMULATION_GROUP, PLAGIARISM_DETECTION, TYPING_TEST, TEXT } from 'helper/constants';
import Tooltip from 'libraries/Tooltip';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import RequiredAnswerSwitch from './components/RequiredAnswerSwitch';
import PlagiarismSwitch from './components/PlagiarismSwitch';
import TimerLimitSwitch from './components/TimerLimitSwitch';
import IncludeInGradingSwitch from './components/IncludeInGradingSwitch';
import AdditionalConstrains from './components/AdditionalConstrains';
import TypingTestTimerLimitSwitch from './components/TypingTestTimerLimitSwitch';
import ContentAccordition from '../ContentAccordition';
import { appCtx } from '../../../../appStore';

import styles from './styles';


const AdvancedSettings = ({
    question, audition, isCMS,
    classes, changeQuestionSettings
}) => {
    const { company } = useContext(appCtx);
    const { answerType } = question;
    const hasCompanyPlagiarismDetection = checkHasCompanyFeature(company, PLAGIARISM_DETECTION);
    const header = (
        <>
            {
                SIMULATION_GROUP.types.includes(answerType) && !isCMS && (
                    <Tooltip
                        tooltipClass={classes.tooltip}
                        label="Question settings are unable to be changed for simulations"
                    >
                        <div className={classes.wrapperBanner} />
                    </Tooltip>
                )
            }
            <Typography variant="h6">{isCMS ? 'Advanced ' : ''}Question Settings</Typography>
        </>
    );
    const content = (
        <>
            {answerType === TYPING_TEST
                ? (
                    <TypingTestTimerLimitSwitch
                        question={question}
                    />
                ) : (
                    <TimerLimitSwitch
                        {...{
                            changeQuestionSettings,
                            question,
                            audition
                        }}
                    />
                )
            }
            <RequiredAnswerSwitch
                {...{
                    question,
                    audition,
                    changeQuestionSettings
                }}
            />
            {
                hasCompanyPlagiarismDetection && question.answerType === TEXT && (
                    <PlagiarismSwitch
                        {...{
                            question,
                            audition,
                            changeQuestionSettings
                        }}
                    />
                )
            }
            <IncludeInGradingSwitch
                {...{
                    question,
                    changeQuestionSettings
                }}
            />
            {
                answerType === CODE_TEST_AUTOGRADED && (
                    <AdditionalConstrains
                        {...{
                            changeQuestionSettings,
                            question,
                            audition
                        }}
                    />
                )
            }
        </>
    );

    if (isCMS) {
        return (
            <ContentAccordition
                header={header}
                content={content}
                className={classes.accordion}
                prefix="advanced-"
            />
        );
    }

    return (
        <div className={classes.wrapper} id="question-settings">
            {header}
            <br />
            {content}
        </div>
    );
};

export default withStyles(styles)(AdvancedSettings);
