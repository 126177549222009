import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

import QuestionWrapperAssessmentPreview
from 'components/marketplace/Preview/common/QuestionWrapperAssessmentPreview';
import InterviewStepper from 'components/marketplace/Preview/SimplePreview/components/Stepper';
import QuestionHeader from 'components/marketplace/Preview/SimplePreview/components/QuestionHeader';
import { getAssessmentPreviewData } from 'requests/AssessmentRequests';
import NotFoundComponent from 'components/not_found';
import DeactivatedIcon from 'img/deactivated.svg';
import PoweredBy from 'img/poweredBy';
import PreviewHeader from './PreviewHeader';
import styles from './styles';

const Preview = ({
    classes, uuid, history
}) => {
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [assessment, setAssessment] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDeactivated, setIsDeactivated] = useState(false);

    useEffect(() => {
        getAssessmentPreviewData(uuid)
            .then(({ data, success }) => {
                if (!data) {
                    setIsDeactivated(true);
                    return;
                }
                if (data && success) {
                    const { audition, completedQuestions, ...others } = data;
                    setAssessment(audition);
                    setQuestions(completedQuestions);
                    setCompany(others);
                }
            })
            .catch(() => {
                history.push('/not-found');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [uuid]);

    if (isDeactivated) {
        return (
            <NotFoundComponent
                icon={DeactivatedIcon}
                mainText="The preview link has been deactivated."
                additionalText={(
                    <p className={classes.notFoundContent}>
                        The preview link for this assessment has been removed or deactivated. Contact the employer or try our&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vervoe.zendesk.com/hc/en-us"
                            className={classes.link}
                        >
                             Help Centre
                        </a>
                    </p>
                )}
            />
        );
    }

    return (
        <Dialog
            scroll="body"
            open
            fullScreen
        >
            {company && (
                <PreviewHeader
                    company={company}
                    assessment={assessment}
                />
            )}
            {
                assessment && !loading && (
                    <>
                        <div className={classes.previewContentWrapper}>
                            <QuestionWrapperAssessmentPreview
                                brandColor={company.color}
                                QuestionHeaderComp={QuestionHeader}
                                interview={assessment}
                                questions={questions}
                                question={questions[selectedQuestionIndex]}
                                selectedQuestionIndex={selectedQuestionIndex}
                                setSelectedQuestionIndex={setSelectedQuestionIndex}
                                isPreviewPage
                            />
                        </div>
                        <InterviewStepper
                            companyColor={company.color}
                            questions={questions}
                            assessmentTimed={assessment.timerEnabled}
                            selectedQuestionIndex={selectedQuestionIndex}
                            setSelectedQuestionIndex={setSelectedQuestionIndex}
                        />
                    </>
                )
            }
            {
                company && (
                    <div className={classes.poweredImgContainer}>
                        <PoweredBy color={company.color} />
                    </div>
                )
            }
            {
                loading && (
                    <>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress />
                        </div>
                    </>
                )
            }
        </Dialog>
    );
};

export default withRouter(withStyles(styles)(Preview));
