import React from 'react';


const AlertIcon = ({ color = '#F87E30', ...other }) => (
    <svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 10.8333L10 5.83334" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <ellipse cx="10.0001" cy="13.3333" rx="0.833333" ry="0.833333" fill={color} />
    </svg>
);

export default AlertIcon;
