import React from 'react';
import { withStyles } from '@mui/styles';
import CheatIcon from 'img/icons/cheatFlag.svg';
import clsx from 'clsx';
import styles from '../styles';

const QuestionInfo = ({ plagiarismQuestion, classes }) => (
    <>
        <div className={clsx(classes.questionTitle, 'u-mrg--bx3')}> <img src={CheatIcon} alt="" /> Question {plagiarismQuestion.sort}</div>
        <div className={classes.text}>
            <p>{plagiarismQuestion.question.description}</p>
        </div>

        <div className={clsx(classes.divider, 'u-mrg--tx2')} />
    </>
);

export default withStyles(styles)(QuestionInfo);
