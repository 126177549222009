import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

// material-ui components
import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { checkHasCompanyFeature, clone, isGoogleQuestion } from 'helper/commonFunctions';
import {
    CODE_CHALLENGE,
    TEXT, SINGLE_CHOICE_AUTO_GRADED,
    AUDIO, VIDEO, ANY_FILE, TYPING_TEST,
    NO_ANSWER, GOOGLE_DOC, PRESENTATION,
    SPREADSHEET, CODE_TEST, CODE_TEST_AUTOGRADED,
    SPREADSHEET_AUTO_GRADED, NUMBER, CS_SIMULATOR,
    ACCESS_PREMIUM_CONTENT, MULTIPLE_CHOICE_AUTOGRADED,
    SIMULATION_GROUP, EXCEL_AUTO_GRADED, MOBILE_ONLY_QUESTIONS
} from 'helper/constants';
import { appCtx } from 'components/appStore';
import NoMobileIcon from 'img/icons/noMobile.svg';

import { QuestionTypes } from 'helper/questionSettingsFunctions';
import { changeGoogleDriveLink, changeQuestionParams } from 'requests/QuestionsRequests';

import AnswerTypesDropDown from './AnswerTypesDropDown';
import SimulationTypesDropDown from './SimulationTypesDropDown';

// local files and components
import styles from './styles';

let googleLinks = {};

function isSpreadsheetType(type) {
    return type === SPREADSHEET || type === SPREADSHEET_AUTO_GRADED;
}

const AnswerTypes = observer(({
    classes, question, updateQuestion, setQuestion,
    setSaving, isCMS, assessmentHasTypingTest, onUpdateQuestion
}) => {
    const { company } = useContext(appCtx);
    const { answerType, googleDriveFile, id, basicQuestionTypes } = question;

    const hasAccessToPremiumContent = checkHasCompanyFeature(company, ACCESS_PREMIUM_CONTENT);
    const premiumQuestionTypesAvailable = checkHasCompanyFeature(company, 'CREATE_PREMIUM_QUESTIONS');

    const questionTypes = [{
        label: 'Text',
        icon: QuestionTypes[TEXT].icon,
        type: TEXT
    }, {
        label: 'Multiple Choice',
        icon: QuestionTypes[SINGLE_CHOICE_AUTO_GRADED].icon,
        type: SINGLE_CHOICE_AUTO_GRADED
    }, {
        label: 'Video',
        icon: QuestionTypes[VIDEO].icon,
        type: VIDEO
    }, {
        label: 'Audio',
        icon: QuestionTypes[AUDIO].icon,
        type: AUDIO
    }, {
        label: 'Code',
        icon: QuestionTypes[CODE_TEST].icon,
        type: checkHasCompanyFeature(company, CODE_CHALLENGE) ? CODE_TEST_AUTOGRADED : CODE_TEST
    }, {
        label: 'Spreadsheet',
        icon: QuestionTypes[SPREADSHEET].icon,
        type: SPREADSHEET
    }, {
        label: 'Spreadsheet',
        icon: QuestionTypes[SPREADSHEET_AUTO_GRADED].icon,
        type: SPREADSHEET_AUTO_GRADED,
        subLabel: 'auto graded'
    }, {
        label: 'Presentation',
        icon: QuestionTypes[PRESENTATION].icon,
        type: PRESENTATION,
        iconClassName: classes.fillIcon
    }, {
        label: 'Document',
        icon: QuestionTypes[GOOGLE_DOC].icon,
        type: GOOGLE_DOC
    }, {
        label: 'Upload',
        icon: QuestionTypes[ANY_FILE].icon,
        type: ANY_FILE,
        iconClassName: classes.fillIcon
    }, {
        label: 'Info',
        icon: QuestionTypes[NO_ANSWER].icon,
        type: NO_ANSWER,
        iconClassName: classes.fillIcon
    }, {
        label: 'Number',
        icon: QuestionTypes[NUMBER].icon,
        type: NUMBER
    }, {
        label: 'Typing test',
        icon: QuestionTypes[TYPING_TEST].icon,
        type: TYPING_TEST,
        disabled: assessmentHasTypingTest
    }, {
        label: 'Simulation',
        icon: QuestionTypes[CS_SIMULATOR].icon,
        type: SIMULATION_GROUP,
        disabled: !isCMS && !hasAccessToPremiumContent
    }, {
        label: 'Excel',
        icon: QuestionTypes[EXCEL_AUTO_GRADED].icon,
        type: EXCEL_AUTO_GRADED,
        subLabel: 'auto graded'
    }];

    if (answerType && checkHasCompanyFeature(company, CODE_CHALLENGE)) {
        questionTypes.splice(4, 0, {
            label: 'Code',
            icon: QuestionTypes[CODE_TEST].icon,
            type: CODE_TEST
        });
    }

    if (answerType === MULTIPLE_CHOICE_AUTOGRADED) {
        questionTypes.splice(2, 0, {
            label: 'Multiple Choice',
            icon: QuestionTypes[MULTIPLE_CHOICE_AUTOGRADED].icon,
            type: MULTIPLE_CHOICE_AUTOGRADED
        });
    }

    if (!premiumQuestionTypesAvailable) {
        questionTypes.forEach((item) => {
            item.disabled = item.disabled || !basicQuestionTypes.includes(item.type);
        });
    }
    useEffect(() => {
        googleLinks = { [answerType]: googleDriveFile };
    }, [id]);

    const onChange = async (type) => {
        const prevAnswerType = answerType;
        if (answerType === type) return;
        setSaving(true);
        const prevGoogleDriveFile = clone(googleDriveFile);
        changeQuestionParams(id, { answerType: type }, isCMS)
            .then(async ({ success, data: questionData }) => {
                if (success && questionData) {
                    const { id: questionDataId, ...other } = questionData;
                    const newQuestion = { ...question, ...other };
                    if (isGoogleQuestion(type)) {
                        setGoogleLinkToObj(prevAnswerType, prevGoogleDriveFile);
                        if (getGoogleLinkFromObj(type)) {
                            const { data: newGoogleDriveFile } = await updateGoogleLink(type);
                            newQuestion.googleDriveFile = newGoogleDriveFile;
                        }
                    }
                    setSaving(false);
                    setQuestion(newQuestion);
                    if (onUpdateQuestion) onUpdateQuestion(newQuestion);
                    if (updateQuestion) updateQuestion(newQuestion);
                }
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const updateGoogleLink = async (type) => {
        const nextLinkObj = getGoogleLinkFromObj(type) || null;
        if (!nextLinkObj) return;
        return changeGoogleDriveLink(question.id, nextLinkObj.id, isCMS);
    };

    const setGoogleLinkToObj = (newType, linkObj) => {
        const newLink = linkObj || null;
        googleLinks[newType] = newLink;
        if (isSpreadsheetType(newType)) {
            googleLinks[SPREADSHEET] = newLink;
            googleLinks[SPREADSHEET_AUTO_GRADED] = newLink;
        }
    };

    const getGoogleLinkFromObj = (newType) => {
        if (isSpreadsheetType(newType)) {
            return googleLinks[SPREADSHEET] || googleLinks[SPREADSHEET_AUTO_GRADED];
        }
        return googleLinks[newType];
    };

    if (answerType) {
        return (
            <>
                <AnswerTypesDropDown
                    questionTypes={questionTypes}
                    onChange={onChange}
                    value={answerType}
                />
                {
                    SIMULATION_GROUP.types.includes(answerType) && (
                        <SimulationTypesDropDown
                            onChange={onChange}
                            value={answerType}
                            isCMS={isCMS}
                        />
                    )
                }
            </>
        );
    }


    return (
        <div className={classes.wrapperIcon}>
            {
                questionTypes.map(({
                    subLabel, type, iconClassName,
                    label, icon: Icon, disabled
                }) => {
                    const selectedType = type.types ? type.types[0] : type;
                    const selectedLabel = type.label ? type.label : label;
                    const mobileOnlyType = MOBILE_ONLY_QUESTIONS.includes(type);

                    return (
                        <Card
                            key={selectedType}
                            disabled={disabled}
                            onClick={disabled ? undefined : () => onChange(selectedType)}
                            className={clsx(classes.card, disabled && classes.cardDisabled)}
                        >
                            <CardActionArea disabled={disabled}>
                                <CardContent
                                    classes={{ root: classes.cardContent }}
                                >
                                    {Icon && <Icon className={iconClassName} />}
                                    <p className={classes.label}>
                                        {selectedLabel}
                                        {mobileOnlyType && <img className={classes.noMobileIcon} src={NoMobileIcon} alt="!" />}
                                    </p>
                                    {
                                        subLabel && (
                                            <Typography className={classes.subLabel} component="span">
                                                {subLabel}
                                            </Typography>
                                        )
                                    }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    );
                })
            }
        </div>
    );
});

export default withStyles(styles)(AnswerTypes);
