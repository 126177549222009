export default theme => ({
    popularCategoryTitle: {
        height: 20,
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        textTransform: 'capitalize',
        fontWeight: 600,
        color: theme.palette.grey[600],
        '& img': {
            marginRight: 7,
            width: 20
        }
    }
});
