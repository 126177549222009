import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import StatsItem from 'components/assessments_pages/AssessmentStatsItem';
import RatingData from './RatingData';

import styles from './styles';
import { statsCtx } from './statsStore';

const AssessmentStats = observer(({ classes, audition }) => {
    const {
        loading, statsArr, loadAssessmentStats,
        setLoading, setSlug, cleanStatsData,
        loadRatingData
    } = useContext(statsCtx);
    const { uuid, slug } = audition;

    useEffect(() => {
        setSlug(slug);
    }, [slug]);

    useEffect(() => {
        setLoading(true);
        loadAssessmentStats();
        loadRatingData();
        return () => cleanStatsData();
    }, []);

    return (
        <div className={classes.wrapper}>
            <div>
                <p className={classes.label}>Candidates</p>
                <div className={classes.content}>
                    {
                        loading ? (
                            <div className={classes.loaderWrapper}>
                                <CircularProgress color="primary" size={25} className="u-mrg--tx2" />
                            </div>
                        ) : (
                            <>
                                {statsArr.map((item, key) => <StatsItem isPercentageGrey key={key} {...item} />)}
                            </>
                        )
                    }
                </div>
            </div>
            <RatingData uuid={uuid} />
        </div>
    );
});

export default withStyles(styles)(withRouter(AssessmentStats));
