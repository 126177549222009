import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import CandidatesSearch from 'components/job_or_assessment_settings/CandidatesSearch';
import TableFilter from '../../results_assessment/common/ResultsTableFilter';
import ExportButton from '../ExportButton';
import TableSort from '../TableSort';

import { assessmentSettingsCtx } from '../../store';
import { candidateResultCtx } from '../../results_assessment/common/CandidateListWrapper/candidatesStore';
import { invitationsCtx } from '../../candidate_invite/invitationsStore';
import { statsCtx } from '../../statsStore';
import InviteTableFilter from '../../candidate_invite/InviteCandidates/InviteTableFilter';

import useStyles from './styles';


const SearchFilterBlock = observer(({ match, isInvite, className = '' }) => {
    const classes = useStyles();
    const context = isInvite ? invitationsCtx : candidateResultCtx;
    const {
        query, handleChangeFilterSelect,
        filterCategory, handleChangeFilter,
        totalCount
    } = useContext(context);
    const { audition } = useContext(assessmentSettingsCtx);
    const { isNoCandidates } = useContext(statsCtx);
    const { scriptSlug } = match.params;

    return (
        <div className={clsx(classes.infoWrapper, className)}>
            <CandidatesSearch
                className={classes.search}
                onChange={handleChangeFilter}
                value={query}
                audition={audition}
            />
            <div className="u-dsp--f u-ai--center">
                <ExportButton
                    isAssessment
                    disabled={isNoCandidates}
                    slug={scriptSlug}
                    opportunity={audition}
                    candidatesCount={totalCount}
                    segmentEventContext={isInvite ? 'invite page' : 'select candidates'}
                />
                {
                    isInvite ? (
                        <InviteTableFilter />
                    ) : (
                        <TableFilter
                            isInvite={isInvite}
                            filterCategory={filterCategory}
                            handleChangeFilterSelect={handleChangeFilterSelect}
                        />
                    )
                }
                {!isInvite && (
                    <TableSort
                        disabled={!audition.active}
                        disabledLabel={!audition.active ? 'This feature is not available for closed assessment' : ''}
                    />
                )}
            </div>
        </div>
    );
});

export default withRouter(SearchFilterBlock);
