import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { getQuestionForGrading } from 'requests/ActiveLearningRequests';
import LoaderCalibration from 'components/loaders/LoaderCalibration';

import ContentWrapper from './components/ContentWrapper';
import useStyles from './styles';

const drawerWidth = 310;

const QuestionsContent = ({
    questionHashUuid, slug, selectedQuestionIndex,
    loadAudition, findNextQuestionToGrade, openedDrawerSection, setOpenedDrawerSection
}) => {
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!questionHashUuid) {
            setCurrentQuestion(null);
            return;
        }
        setIsLoading(true);
        getQuestionForGrading(slug, questionHashUuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setCurrentQuestion(data);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [questionHashUuid]);

    const handleOpenDrawer = (categoryName) => {
        if (categoryName === openedDrawerSection) {
            setOpenedDrawerSection(null);
            return;
        }
        setOpenedDrawerSection(categoryName);
    };

    if (isLoading) {
        return (
            <div className={classes.wrapper}>
                <div className={classes.contentLoaderWrapper}>
                    <LoaderCalibration />
                </div>
            </div>
        );
    }

    if (!currentQuestion) return null;

    return (
        <div className={classes.wrapper}>
            <ContentWrapper
                className={clsx(classes.content, {
                    [classes.contentShift]: Boolean(openedDrawerSection)
                })}
                selectedQuestionIndex={selectedQuestionIndex}
                loadAudition={loadAudition}
                question={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                findNextQuestionToGrade={findNextQuestionToGrade}
                questionHashUuid={questionHashUuid}
                handleOpenDrawer={handleOpenDrawer}
                openedSection={openedDrawerSection}
                drawerWidth={drawerWidth}
            />
        </div>
    );
};

export default QuestionsContent;
