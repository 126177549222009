import React, { useContext, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useVirtualizer } from '@tanstack/react-virtual';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { VALIDATE_IS_EMAIL, VALIDATE_REQUIRED, VALIDATE_SPACES } from 'helper/constants';
import { clone, checkPluralNew } from 'helper/commonFunctions';
import InviteFormField from 'components/job_or_assessment_settings/InviteForm/InviteFormFields/InviteFormField';
import PlusIcon from 'img/add.svg';

import stylesJs from './styles';

const InviteFormFields = observer(({
    children, id, company, classes, hideAddMoreRowsButton,
    onValueChange, hidePhoneField, context,
    onErrorInviteForm, expired
}) => {
    const {
        inviteViaEmailForm, setErrorMessage,
        setRowCount, rowCount, defaultFormInvite,
        formRef, setFormRef
    } = useContext(context);
    const isPhone = useMediaQuery('(max-width: 820px)');
    const scrollableTableRef = useRef(null);
    const rowVirtualizer = useVirtualizer({
        overscan: 5,
        count: rowCount,
        estimateSize: () => (isPhone ? 120 : 80),
        getScrollElement: () => scrollableTableRef.current
    });

    useEffect(() => {
        setFormRef(React.createRef());
        ValidatorForm.addValidationRule(VALIDATE_SPACES, value => Boolean(value.replace(/\s/g, '')));
    }, []);

    const onHandleChange = (fieldName, value, index) => {
        onValueChange({ fieldName, value, index }, () => {
            // Make fields of index are valid
            const formFieldsCount = 2;
            const formIndex = index * formFieldsCount;
            // Clear errors
            formRef.current.childs[formIndex].makeValid();
            formRef.current.childs[formIndex + 1].makeValid();
        });
    };

    const addElements = () => {
        const newRowCount = rowCount + 1;
        setRowCount(newRowCount);
        setTimeout(() => {
            const objDiv = document.getElementById('invite_rows_wrapper');
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 0);
    };

    const onClickPhone = (e) => {
        if (e.target.className.includes('flag')) {
            e.currentTarget.style.zIndex = 2;
            return;
        }
        e.currentTarget.style.zIndex = 1;
    };

    const renderRow = ({ index, size, start, key }) => (
        <div
            key={key}
            ref={rowVirtualizer.measureElement}
            onClick={onClickPhone}
            role="presentation"
            className={classes.scrollContainerItem}
            style={{
                height: `${size}px`,
                zIndex: 1,
                transform: `translateY(${start}px)`
            }}
        >
            <InviteFormField
                expired={expired}
                company={company}
                indexKey={index}
                onClickPhone={onClickPhone}
                onHandleChange={onHandleChange}
                hidePhoneField={hidePhoneField}
                listCountriesTopOffset={-30}
                formInvite={inviteViaEmailForm[index] ? inviteViaEmailForm[index] : clone(defaultFormInvite)}
            />
        </div>
    );


    const renderRows = () => {
        const virtualItems = rowVirtualizer.getVirtualItems();

        return (
            <div
                className={classes.scrollContainer}
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`
                }}
            >
                {virtualItems.map(virtualItem => renderRow(virtualItem))}
            </div>
        );
    };

    const formErrorHandler = (textFields) => {
        let requiredErrorCount = 0;
        let emailErrorCount = 0;
        let unknownErrorCount = 0;
        const errorMessages = [];

        textFields.forEach(({ state: { validators }, invalid: [i] }) => {
            // Show error index
            switch (validators[i]) {
                case VALIDATE_REQUIRED:
                    requiredErrorCount += 1;
                    break;
                case VALIDATE_IS_EMAIL:
                    emailErrorCount += 1;
                    break;
                case VALIDATE_SPACES:
                    requiredErrorCount += 1;
                    break;
                default:
                    unknownErrorCount += 1;
                    break;
            }
        });
        if (emailErrorCount > 0) {
            errorMessages.push(`${emailErrorCount} invalid email ${checkPluralNew(emailErrorCount, 'address', 'addresses')}`);
        }

        if (requiredErrorCount > 0) {
            errorMessages.push(`${requiredErrorCount} required ${checkPluralNew(requiredErrorCount, 'field')}`);
        }
        if (unknownErrorCount > 0) {
            errorMessages.push(`${unknownErrorCount} unknown ${checkPluralNew(unknownErrorCount, 'error')}`);
        }
        if (errorMessages.length) {
            if (onErrorInviteForm) onErrorInviteForm(inviteViaEmailForm, errorMessages);
            setErrorMessage(errorMessages.join('; '));
        }
    };

    return (
        <ValidatorForm
            ref={formRef}
            id={id || 'qa-invite-candidates-mails'}
            className="row u-clear"
            onError={formErrorHandler}
        >
            <div id="invite_rows_wrapper" ref={scrollableTableRef} className={`col-xs-12 ${classes.form}`}>
                {renderRows()}
            </div>

            {
                !hideAddMoreRowsButton && (
                    <div className="col-xs-12 u-mrg--tx2">
                        <Button
                            color="primary"
                            className={classes.addRow}
                            onClick={addElements}
                            disabled={expired}
                        >
                            <img src={PlusIcon} className="u-mrg--rx2" alt="" /> Add more rows
                        </Button>
                    </div>
                )
            }
            { children }
        </ValidatorForm>
    );
});

export default withStyles(stylesJs)(InviteFormFields);
