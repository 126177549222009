import React from 'react';
import ToggleDisplay from 'react-toggle-display';
import Button from '@mui/material/Button';

// local files and components
import Recorder from 'components/ziggeo/recorder';
import Player from 'components/ziggeo/player';

class MediaCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ziggeoToken: false,
            record: true,
            started: false
        };
    }

    componentDidMount() {
        const { ziggeo } = this.props;
        const { started } = this.state;
        this.setState({
            ziggeoToken: ziggeo ? ziggeo.token : '',
            record: !started ? !(ziggeo && ziggeo.token) : true
        });
    }

    componentWillReceiveProps(nextProps) {
        const { started } = this.state;
        const { ziggeo } = nextProps;
        this.setState({
            ziggeoToken: ziggeo ? ziggeo.token : '',
            record: !started ? !(ziggeo && ziggeo.token) : true
        });
    }

    handleClick = () => {
        const { record } = this.state;
        this.setState({ record: !record });
    }

    recordingStarted = () => {
        const { recordingStarted } = this.props;
        this.setState({ started: true });
        if (recordingStarted) recordingStarted();
    }

    recordingStoped = () => {
        const { recordingStoped } = this.props;
        this.setState({ started: false });
        if (recordingStoped) recordingStoped();
    }

    recordingProcessed = () => this.handleClick();

    render() {
        const {
            props: { recordingPublished, video },
            state: { record, started, ziggeoToken },
            recordingStarted, recordingStoped, recordingProcessed, handleClick
        } = this;
        return (
            <div className="u-wdt--100p">
                <ToggleDisplay show={record}>
                    <Recorder
                        {...{ recordingStarted, recordingStoped, recordingProcessed, recordingPublished }}
                        video={Boolean(video)}
                    />
                </ToggleDisplay>

                { !started && record && ziggeoToken && recordingPublished && (
                    <Button
                        className="u-mrg--tx3"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        Review { video ? 'video' : 'audio' }
                    </Button>
                )}
                <ToggleDisplay show={!record}>
                    <div className="u-txt--center">
                        <Player
                            ziggeo_token={ziggeoToken}
                            audio={!video}
                        />
                    </div>
                    {
                        !record && recordingPublished && (
                            <Button
                                className="u-mrg--tx2"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClick}
                            >
                                Re-record
                            </Button>
                        )
                    }
                </ToggleDisplay>
            </div>
        );
    }
}

export default MediaCase;
