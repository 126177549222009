import React, { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import { XAxis, YAxis, Area, CartesianGrid, AreaChart, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import CompletionsAreaChart from './CompletionsAreaChart';

import styles from './styles';

const tickStyle = { fontSize: 12, fontWeight: 700, fill: '#C7C8CC', fontFamily: 'Open Sans' };

class InsightsAreaChart extends PureComponent {
    constructor(props) {
        super(props);
        this.chartType = new CompletionsAreaChart(props.value);
    }

    componentWillReceiveProps(nextProps) {
        const { value } = this.props;
        if (nextProps.value !== value) {
            this.chartType.chartValues = nextProps.value;
        }
    }

    render() {
        const { tooltipLabel, classes } = this.props;
        return (
            <div className={classes.chartWrapper}>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        data={this.chartType.chartValues}
                        margin={{ top: 0, left: 0 }}
                    >
                        <defs>
                            <linearGradient id="insights_blue_vertical_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" stopColor="#436cff" />
                                <stop offset="100%" stopColor="#fff" />
                            </linearGradient>
                        </defs>
                        <XAxis
                            scale="point"
                            interval="preserveStartEnd"
                            padding={{ left: 30, right: 30 }}
                            dataKey="completed_date"
                            tickLine={false}
                            axisLine={false}
                            tick={tickStyle}
                        />
                        <YAxis
                            padding={{ top: 30 }}
                            width={this.chartType.getYAxisWidth}
                            tickMargin={10}
                            tickLine={false}
                            axisLine={false}
                            allowDecimals={false}
                            domain={[0, 'dataMax']}
                            tickSize={this.chartType.getTickSize}
                            tick={tickStyle}
                            tickFormatter={this.chartType.tickFormatter}
                        />
                        <CartesianGrid
                            vertical={false}
                            stroke="#ebf3f0"
                        />
                        <Tooltip
                            cursor={false}
                            separator=""
                            content={tooltipPayload => this.chartType.showTooltipData(tooltipPayload, tooltipLabel, classes)}
                        />
                        <Bar
                            dataKey="completed_date"
                            maxBarSize={100}
                            strokeWidth="1"
                            background={{ fill: '#F8F8FA', opacity: 0.7 }}
                            animationBegin={300}
                        />
                        <Area
                            dataKey="completed_count"
                            strokeWidth="3"
                            stroke="#1247F6"
                            fill="url(#insights_blue_vertical_gradient)"
                            animationBegin={300}
                            dot={{ stroke: '#1247F6', strokeWidth: 3, r: 4, fill: '#fff', fillOpacity: 1 }}
                            activeDot={{ stroke: '#fff', strokeWidth: 5, r: 8, fill: '#1247F6' }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default withStyles(styles)(InsightsAreaChart);
