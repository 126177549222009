import React from 'react';
import Player from 'components/ziggeo/player';

const MediaQuestion = ({ ziggeo, audio }) => {
    if (!(ziggeo && ziggeo.token)) return null;
    return (
        <div className="u-txt--center">
            <Player audio={audio} ziggeo_token={ziggeo.token} />
        </div>
    );
};

export default MediaQuestion;
