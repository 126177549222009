import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getCandidateInfo, getPublicCompany } from 'requests/APIrequests';
import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import PageWrapper from 'components/layout/PageWrapper';
import {
    addGuestCandidateComment, getPublicCandidateAudition,
    getGuestCandidatePosts, getPublicAuditionQuestionResults,
    getPublicCandidateOpportunitiesList, getPublicGradeQuestion, gradeGuestAnswer,
    getPublicAuditionScreeningQuestionResults
} from 'requests/JobOpportunityRequests';
import * as qs from 'query-string';
import { appCtx } from 'components/appStore';
import CardCompanyLogo from '../CardCompanyLogo';

import CandidateCardWrapper from '../CandidateCardWrapper';
import CardFooter from './CardFooter';
import AuditionListSelect from '../CandidateCardWrapper/AuditionListSelect';


const PublicCardContent = observer(({ match, location, gradeableCard }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingAudition, setLoadingAudition] = useState(false);
    const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState(0);
    const [selectedAuditionIndex, setSelectedAuditionIndex] = useState(0);
    const [opportunitiesList, setOpportunitiesList] = useState([]);
    const { candidateUuid } = match.params;
    const { company, setCompany } = useContext(appCtx);

    const parsed = qs.parse(location.search);
    const { assessment: assessmentSlug } = parsed;

    useEffect(() => {
        setLoading(true);
        getUserInfo();
        getCompany();
        getOpportunitiesList();
    }, []);

    useEffect(() => {
        if (!opportunitiesList.length) return;
        setInitialIndexes();
    }, [opportunitiesList]);

    const setInitialIndexes = () => {
        let opportunityIndex = 0;
        let columnIndex = 0;
        if (assessmentSlug) {
            for (let i = 0; i < opportunitiesList.length; i++) {
                const index = opportunitiesList[i].columns.findIndex(({ audition }) => audition.slug === assessmentSlug);
                if (index !== -1) {
                    columnIndex = index;
                    opportunityIndex = i;
                    break;
                }
            }
        }
        setSelectedIndexes(opportunityIndex, columnIndex);
    };

    const getUserInfo = () => {
        getCandidateInfo(candidateUuid, gradeableCard)
            .then((response) => {
                const { success, data } = response;
                if (success && data) {
                    setUser(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getCompany = () => {
        getPublicCompany()
            .then(({ success, company: companyRsp }) => {
                if (success && companyRsp) {
                    setCompany(companyRsp);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getGradeQuestion = id => getPublicGradeQuestion(id, gradeableCard);

    const getAuditionQuestionResults = id => getPublicAuditionQuestionResults(id, gradeableCard);

    const getAuditionScreeningQuestionResults = id => getPublicAuditionScreeningQuestionResults(id, gradeableCard);

    const setSelectedIndexes = (opportunityIndex = selectedOpportunityIndex, assessmentIndex = selectedAuditionIndex) => {
        if (!opportunitiesList) return;
        setLoadingAudition(true);
        setSelectedOpportunityIndex(opportunityIndex);
        setSelectedAuditionIndex(assessmentIndex);
        if (opportunitiesList[opportunityIndex].columns[assessmentIndex]) {
            getPublicCandidateAudition(opportunitiesList[opportunityIndex].columns[assessmentIndex].userAudition.uuid, gradeableCard)
                .then(({ success, data }) => {
                    if (success && data) {
                        setUser({ ...user, ...data });
                    }
                })
                .finally(() => {
                    setLoadingAudition(false);
                });
        }
    };

    const getOpportunitiesList = () => {
        getPublicCandidateOpportunitiesList(candidateUuid, gradeableCard)
            .then(({ success, data }) => {
                if (success && data) {
                    setOpportunitiesList(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let selectedAudition = {};
    if (opportunitiesList.length && opportunitiesList[selectedOpportunityIndex].columns[selectedAuditionIndex]) {
        selectedAudition = opportunitiesList[selectedOpportunityIndex].columns[selectedAuditionIndex].audition;
        selectedAudition.opportunitySlug = opportunitiesList[selectedOpportunityIndex].opportunity.slug;
    }

    return (
        <PageWrapper className="u-pdn--bx5">
            <CardCompanyLogo
                company={company}
                gradeableCard={gradeableCard}
                opportunitiesList={opportunitiesList}
            />
            <InnerContentWrapper
                className="u-pdn--x0"
                contentClassName="u-pdn--x0"
            >
                <CandidateCardWrapper
                    user={user}
                    setUser={setUser}
                    company={company}
                    isLoading={isLoading}
                    getUserInfo={() => setSelectedIndexes()}
                    opportunitySlug={selectedAudition.opportunitySlug}
                    audition={selectedAudition}
                    loadCandidatePosts={(selectedAudition.opportunitySlug && gradeableCard) ? getGuestCandidatePosts : undefined}
                    context={`candidate ${gradeableCard ? 'agency' : 'public'} card`}
                    propPublic
                    gradeableCard={gradeableCard}
                    authorizeGuest={() => setSelectedIndexes()}
                    addCandidateComment={addGuestCandidateComment}
                    loadGradeQuestion={getGradeQuestion}
                    getAuditionQuestionResults={getAuditionQuestionResults}
                    getAuditionScreeningQuestionResults={getAuditionScreeningQuestionResults}
                    gradeFunction={gradeGuestAnswer}
                    opportunitiesList={opportunitiesList}
                    ReportHeaderComp={(
                        <AuditionListSelect
                            opportunitiesList={opportunitiesList}
                            selectedOpportunityIndex={selectedOpportunityIndex}
                            selectedAuditionIndex={selectedAuditionIndex}
                            setSelectedIndexes={setSelectedIndexes}
                            isLoadingAudition={isLoadingAudition}
                            showStatus={false}
                        />
                    )}
                />
            </InnerContentWrapper>
            <CardFooter company={company} />
        </PageWrapper>
    );
});

export default withRouter(PublicCardContent);
