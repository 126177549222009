import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

/* Job Opportunity */
import EmployerIntegrationCard from './pages/card/employer_integration_card';
import PublicCard from './pages/card/public_card';
import PublicCardOLD from './pages/card/public_card_OLD';
import CompanyApply from './pages/company_apply';
import App from './components/app';
import Login from './pages/home/login';
import ForgotPassword from './pages/home/forgot_password';
import RegistrationPremium from './pages/home/registration_premium';
import ChangeForgottenPassword from './pages/home/change_password';
import Registration from './pages/home/registration';
import ProtectedRoute from './components/routes/protected_route';
import PublicCardContent from './components/candidate_cards/PublicCardContent';
import { theme } from './components/mui';
import { appCtx } from './components/appStore';
import Snackbar from './libraries/Snackbar';
import Forbidden from './pages/home/forbidden';
import CodeVerification from './pages/home/code_verification';
import MfaCodeVerification from './pages/home/mfa_code_verification';
import JobAdderAuth from './pages/settings/integrations/components/JobAdderAuth';
import PostHiringSurvey from './pages/post_hiring_survey';
import NotFound from './pages/home/not_found';
import LeverAuth from './pages/settings/integrations/components/LeverAuth';
import Icims from './pages/settings/integrations/components/Icims';
import AssessmentPreview from './pages/assessment/assessment_preview';
import SmartRecruitersLogin from './pages/settings/integrations/components/SmartRecruitersLogin';

// eslint-disable-next-line react/prefer-stateless-function
const Root = observer(({ location }) => {
    const {
        snackbarOpen, snackbarDuration,
        snackbarMessage, snackbarAction,
        closeFlashMessage, flashMessage
    } = useContext(appCtx);

    useEffect(() => {
        localStorage.setItem('setupGuide', 'true');
        redirectRedim();
    }, []);

    useEffect(() => {
        if (window.ga) {
            window.ga((tracker) => {
                const gaClientId = tracker.get('clientId');
                localStorage.setItem('gaClientId', gaClientId);
            });
        }
    }, []);

    useEffect(() => {
        redirectRedim();
        window.scrollTo(0, 0);
    }, [location]);

    const redirectRedim = () => {
        if (window.location.search === '?redeem') {
            window.location.href = 'https://experience.vervoe.com/appsumo-redemption';
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route exact path="/company/apply" component={CompanyApply} />
                        <Route
                            exact
                            path="/candidate/:candidateId/card/public"
                            render={props => (<PublicCardOLD {...props} />)}
                        />
                        <Route
                            exact
                            path="/candidate/:candidateId/card/agency"
                            render={props => (<PublicCardOLD gradeableCard {...props} />)}
                        />
                        <Route
                            exact
                            path="/candidates/:linkUuid/shared-card"
                            render={props => (<PublicCard {...props} />)}
                        />
                        <Route
                            exact
                            path="/candidates/:candidateUuid/card/public"
                            render={props => (<PublicCardContent {...props} />)}
                        />
                        <Route
                            exact
                            path="/candidates/:candidateUuid/card/agency"
                            render={props => <PublicCardContent {...props} gradeableCard />}
                        />
                        <Route
                            exact
                            path="/assessment/:linkUuid/preview"
                            render={props => <AssessmentPreview {...props} />}
                        />
                        <Route exact path="/login" render={props => (<Login {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/jobadder-auth" component={JobAdderAuth} />
                        <Route exact path="/lever-auth" component={LeverAuth} />
                        <Route exact path="/smartrecruiters-auth" component={SmartRecruitersLogin} />
                        <Route exact path="/icims-auth" component={Icims} />
                        <Route exact path="/code-verification" render={props => (<CodeVerification {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/mfa-code-verification" render={props => (<MfaCodeVerification {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/reset-password" component={ForgotPassword} />
                        <Route exact path="/change-password/:token" component={ChangeForgottenPassword} />
                        <Route exact path="/registration" render={props => (<Registration {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/registration-trial" render={props => (<Registration {...props} isTrial flashMessage={flashMessage} />)} />
                        <Route exact path="/registration/seek" render={props => (<Registration {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/registration/premium" render={props => (<RegistrationPremium {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/registration-premium-plans" render={props => (<RegistrationPremium {...props} flashMessage={flashMessage} />)} />
                        <Route exact path="/forbidden" component={Forbidden} />
                        <Route exact path="/not-found" component={NotFound} />
                        <Route exact path="/post-hiring/survey" component={PostHiringSurvey} />
                        <ProtectedRoute
                            path="/integration-card/:userAuditionUuid"
                            component={EmployerIntegrationCard}
                            flashMessage={flashMessage}
                        />
                        <Route path="/" component={App} />
                    </Switch>
                    <Snackbar
                        open={snackbarOpen}
                        message={snackbarMessage}
                        action={snackbarAction}
                        autoHideDuration={snackbarDuration}
                        onClose={closeFlashMessage}
                    />
                </ThemeProvider>
            </BrowserRouter>
        </StyledEngineProvider>
    );
});

export default Root;
