export default theme => ({
    tableRow: {
        fontWeight: 400,
        borderRadius: 20
    },
    tableRowCorrect: {
        backgroundColor: theme.palette.green[50]
    },
    tableCellHead: {
        whiteSpace: 'nowrap',
        '&:last-child': {
            paddingLeft: '16px !important'
        }
    },
    opened: {
        backgroundColor: theme.palette.grey[200],
        verticalAlign: 'top'
    },
    reasonColumn: {
        width: 400,
        whiteSpace: 'break-spaces'
    },
    tableCell: {
        paddingTop: 10,
        paddingBottom: 10
    }
});
