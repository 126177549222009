import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import {
    exportInsightsScoreDistribution, getInsightsScoreDistribution,
    getInsightsScoreDistributionByGroup,
    exportInsightsScoreDistributionByGroup
} from 'requests/AssessmentRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import AssessmentEvents from 'events/AssessmentEvents';
import { loadAssessmentGroups } from 'requests/ScriptRequests';

import Select from 'libraries/Select';
import DownloadImg from 'img/download.svg';

import InsightsSectionWrapper from '../InsightsSectionWrapper';
import Chart from './Chart';

import DistributionsImg from './distribution.svg';

const styles = theme => ({
    averageScore: {
        color: theme.palette.primary.main
    },
    select: {
        textTransform: 'capitalize',
        width: 235
    },
    exportButton: {
        minWidth: 145,
        fontWeight: 700
    },
    menuItem: {
        textTransform: 'capitalize',
        maxWidth: 250,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});

const InsightsScoreDistributions = observer(({
    classes, match, audition
}) => {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [selectedQuestionGroup, setSelectedQuestionGroup] = useState('all');
    const [transactionData, setTransactionData] = useState([]);
    const [questionGroups, setQuestionGroups] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const csvLink = useRef();
    const { name: ttName, uuid: ttId, slug } = audition;


    useEffect(() => {
        getQuestionGroups();
    }, []);

    useEffect(() => {
        setLoading(true);
        const promise = selectedQuestionGroup !== 'all'
            ? getInsightsScoreDistributionByGroup(selectedQuestionGroup)
            : getInsightsScoreDistribution(slug);

        promise
            .then(({ success, data }) => {
                if (success) {
                    setChartData(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [selectedQuestionGroup]);

    const getQuestionGroups = () => (
        loadAssessmentGroups(slug)
            .then(({ data, success }) => {
                if (success && data) {
                    const { questionGroups: questionsGroups } = data;
                    setQuestionGroups(questionsGroups);
                }
            })
    );

    const onSelectChange = (e) => {
        const { value } = e.target;
        AssessmentEvents.INSIGHTS_SCORE_DISTRIBUTION_FILTERED({ ttId, ttName, filterType: 'skill', skillName: value });
        setSelectedQuestionGroup(value);
    };

    const exportDistribution = () => {
        setExportLoading(true);
        const promise = selectedQuestionGroup !== 'all'
            ? exportInsightsScoreDistributionByGroup(selectedQuestionGroup)
            : exportInsightsScoreDistribution(slug);

        promise
            .then((data) => {
                setTransactionData(data);
                AssessmentEvents.INSIGHTS_SCORE_DISTRIBUTION_DOWNLOADED({ ttId, ttName });
                csvLink.current.link.click();
            })
            .finally(() => {
                setExportLoading(false);
            });
    };

    const header = (
        <Typography variant="h5" className="u-txt--left">
            Score Distribution
            <QuestionTooltipBlack className="u-pdn--tx2 u-pdn--bx2" label={<>Shows distribution of<br />all candidate scores</>} />
        </Typography>
    );

    let displayedRanges = [];
    let displayedAverageScore = null;

    if (chartData) {
        const { scoreDistribution, averageScore } = chartData;
        displayedRanges = scoreDistribution;
        displayedAverageScore = averageScore;
    }

    const selectedQuestionGroupIndex = questionGroups.findIndex(({ uuid }) => uuid === selectedQuestionGroup);
    let selectedQuestionGroupTitle = '';
    if (selectedQuestionGroupIndex !== -1) {
        selectedQuestionGroupTitle = questionGroups[selectedQuestionGroupIndex].title;
    }

    return (
        <InsightsSectionWrapper
            header={header}
            imgSrc={DistributionsImg}
            loading={loading}
            selectedQuestionGroup={selectedQuestionGroup}
            isEmpty={!chartData}
            audition={audition}
            emptyLabel="score distribution"
            rightPart={questionGroups.length ? (
                <div className="u-dsp--centered">
                    <Typography variant="h6" className="u-mrg--rx2">Filter</Typography>
                    <Select
                        value={selectedQuestionGroup}
                        onChange={onSelectChange}
                        variant="outlined"
                        className={classes.select}
                        menuPaperClassName={classes.selectPaper}
                        label="Skill"
                        options={
                            [{ value: 'all', label: 'All Skills' }]
                                .concat(questionGroups.map(item => ({
                                    value: item.uuid,
                                    label: item.title,
                                    className: classes.menuItem
                                })))
                        }
                    />
                </div>
            ) : null}
        >
            {
                displayedAverageScore !== null && (
                    <Typography variant="h5" className="u-txt--left">Average Score&nbsp;
                        <span className={classes.averageScore}>
                            {displayedAverageScore}%
                        </span>
                    </Typography>
                )
            }
            <Chart
                value={displayedRanges}
                tooltipLabel="Candidate"
            />
            <div className="u-txt--right">
                <Button
                    disabled={exportLoading}
                    color="primary"
                    className={classes.exportButton}
                    onClick={exportDistribution}
                >
                    {
                        !exportLoading ? (
                            <>
                                <img className="u-mrg--rx1" src={DownloadImg} alt="|" />
                                Download CSV
                            </>
                        ) : (
                            <CircularProgress size={20} color="primary" />
                        )
                    }
                </Button>
                <CSVLink
                    data={transactionData}
                    filename={`${ttName}-distribution-${selectedQuestionGroupTitle || 'all-skills'}-${new Date().toISOString().slice(0, 10)}.csv`}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
            </div>
        </InsightsSectionWrapper>
    );
});

export default withStyles(styles)(withRouter(InsightsScoreDistributions));
