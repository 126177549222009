import React from 'react';
import { withStyles } from '@mui/styles';

import TextField from 'libraries/TextInput';
import IconsBar from './IconsBar';

const styles = {
    inputText: {
        borderRadius: 3,
        fontSize: 16,
        lineHeight: 1.1,
        '& > div': {
            paddingBottom: 40
        }
    }
};


const DescriptionField = ({
    question: { type, screeningQuestion }, classes, handleChangeType, isError,
    value, handleValue, allowedLength, label, placeholder
}) => (
    <div className="o-grid u-mrg--bx6 u-mrg--tx3">
        <div className="o-box o-box--whitefull u-mrg--bx1 u-pdn--x0">
            <TextField
                id="qa-txt-part-question"
                label={label}
                value={value}
                onChange={handleValue}
                className={classes.inputText}
                placeholder={placeholder}
                fullWidth
                multiline
                minRows={11}
                maxLength={allowedLength}
                variant="outlined"
                isError={isError}
            />
            <IconsBar
                questionValue={value}
                valueType={type}
                allowedLength={allowedLength}
                selectQuestionType={handleChangeType}
                screeningQuestion={screeningQuestion}
            />
        </div>
    </div>
);

export default withStyles(styles)(DescriptionField);
