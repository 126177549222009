import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import TooltipBlack from 'libraries/Tooltip';
import InsightsIcon from '../../assets/insightsIcon';
import CorrectAnswerIcon from '../../assets/correntAnswerIcon';
import InstructionsIcon from '../../assets/instructionsIcon';

const styles = theme => ({
    list: {
        display: 'flex'
    },
    listIcon: {
        minWidth: 25
    },
    listRoot: {
        whiteSpace: 'nowrap',
        padding: 8,
        borderRadius: 4
    },
    activeListRoot: {
        '& path, & rect, & ellipse': {
            stroke: theme.palette.primary.main
        }
    }
});

const IconMenu = ({ classes, openedSection, handleOpenDrawer, noExampleAnswers }) => {
    const menuItems = [{
        active: openedSection === 'instructions',
        name: 'instructions',
        onClick: () => handleOpenDrawer('instructions'),
        icon: <InstructionsIcon />,
        tooltipText: 'Scoring Instructions'
    }, {
        active: openedSection === 'correct-answer',
        name: 'correct-answer',
        onClick: () => handleOpenDrawer('correct-answer'),
        icon: <CorrectAnswerIcon noExampleAnswers={noExampleAnswers}/>,
        tooltipText: 'Correct answer examples'
    }, {
        active: openedSection === 'question-insights',
        name: 'question-insights',
        onClick: () => handleOpenDrawer('question-insights'),
        icon: <InsightsIcon />,
        tooltipText: 'Question Insights'
    }];

    return (
        <List className={classes.list}>
            {menuItems.map(({ active, name, onClick, icon, tooltipText }) => (
                <ListItem
                    key={name}
                    className={clsx(classes.listRoot, active && classes.activeListRoot)}
                    button
                    onClick={onClick}
                >
                    <TooltipBlack label={tooltipText}>
                        <ListItemIcon className={classes.listIcon}>
                            {icon}
                        </ListItemIcon>
                    </TooltipBlack>
                </ListItem>
            ))}
        </List>
    );
};

export default withStyles(styles)(IconMenu);
