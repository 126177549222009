import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import InviteResultsDialog from 'components/job_or_assessment_settings/InviteResultsDialog';
import { inviteToAssessment } from 'requests/AssessmentRequests';
import { appCtx } from 'components/appStore';
import AssessmentEvents from 'events/AssessmentEvents';

import styles from './styles';

const TestToMyselfButton = observer(({ classes, className = '', audition, slug, history }) => {
    const [loading, setLoading] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [sentData, setSentData] = useState([]);

    const { expired, valid } = audition;
    const { flashMessage, loggedUser } = useContext(appCtx);

    const handleInviteResultsDialog = () => {
        setOpenMessageDialog(!openMessageDialog);
    };

    const handleGoToCandidates = () => {
        history.push(`/script/invite/${slug}/invite-candidates`);
        handleInviteResultsDialog();
    };

    const inviteCandidates = () => {
        const { uuid: ttId, name: ttName } = audition;
        AssessmentEvents.TEST_TO_MYSELF({ ttName, ttId });
        const { first_name, last_name, email } = loggedUser;
        const inviteArray = [{
            name: `${first_name} ${last_name || ''}`,
            email
        }];
        setLoading(true);
        return inviteToAssessment(slug, inviteArray, true)
            .then(({ success, data }) => {
                setLoading(false);
                if (success) {
                    const { response } = data;
                    const successfullySentData = { };

                    response.forEach((item) => {
                        if (!successfullySentData[item.status]) successfullySentData[item.status] = [];
                        successfullySentData[item.status].push(item);
                    });
                    setSentData(successfullySentData);
                    setLoading(false);
                    handleInviteResultsDialog();
                }
            })
            .catch((err) => {
                setLoading(false);
                flashMessage(err.response.data.errors || 'Something went wrong', '', 10000);
            });
    };

    return (
        <>
            <Button
                className={clsx(classes.saveChangesButton, className)}
                onClick={inviteCandidates}
                color="primary"
                disabled={loading || expired || !valid}
            >
                {
                    loading ? (
                        <CircularProgress
                            size={24}
                            color="primary"
                            thickness={3}
                            classes={{ root: classes.loader }}
                        />
                    ) : 'Send a test to myself'
                }
            </Button>
            <InviteResultsDialog
                open={openMessageDialog}
                sentData={sentData}
                onClose={handleInviteResultsDialog}
            >
                <Button
                    color="primary"
                    className={classes.resultsButton}
                    onClick={handleGoToCandidates}
                >
                    Go to Candidates
                </Button>
            </InviteResultsDialog>
        </>
    );
});

export default withRouter(withStyles(styles)(TestToMyselfButton));
