import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import AssessmentEvents from 'events/AssessmentEvents';
import ExtendIcon from 'img/batchIcons/extend.svg';
import ShareIcon from 'img/batchIcons/share.svg';
import RejectIcon from 'img/batchIcons/reject.svg';
import MessageIcon from 'img/batchIcons/message.svg';
import HireIcon from 'img/batchIcons/hire.svg';
import MessageInactiveIcon from 'img/batchIcons/message_inactive.svg';
import CandidateMessaging from 'components/dialogs/CandidateMessaging';
import ShareCandidateDialog from 'components/dialogs/ShareCandidateDialog';
import ExtendMultipleAssessmentDialog from 'components/dialogs/ExtendMultipleAssessmentDialog';
import RejectMultipleAssessmentDialog from 'components/dialogs/RejectMultipleAssessmentDialog';
import HireMultipleAssessmentDialog from 'components/dialogs/HireMultipleAssessmentDialog';
import { checkHasUserPermission } from 'helper/commonFunctions';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import useStyles from './styles';


const BatchBlock = observer(({
    clearSelection, selectedCandidates, onSuccess: onSuccessProp,
    selectedColumnUserIds, selectedUserAuditionsIds,
    selectedCandidatesIds, loadCandidates, isInviteSection
}) => {
    const [messageDialogOpened, setMessageDialogOpened] = useState(false);
    const [extendDialogOpened, setExtendDialogOpened] = useState(false);
    const [rejectDialogOpened, setRejectDialogOpened] = useState(false);
    const [hireDialogOpened, setHireDialogOpened] = useState(false);
    const [shareDialogOpened, setShareDialogOpened] = useState(false);
    const classes = useStyles();

    const { audition, opportunitySlug } = useContext(assessmentSettingsCtx);
    const { company, flashMessage } = useContext(appCtx);

    if (!audition) return null;

    const scoreShare = checkHasUserPermission(company, audition.permissions, 'agencyShare');
    const message = checkHasUserPermission(company, audition.permissions, 'sendMessage');
    const reject = checkHasUserPermission(company, audition.permissions, 'reject');
    const reset = checkHasUserPermission(company, audition.permissions, 'reset');
    const hire = checkHasUserPermission(company, audition.permissions, 'hire');

    const handleExtendDialog = () => {
        if (!reset || audition.expired || !audition.active || !audition.valid) return;
        setExtendDialogOpened(!extendDialogOpened);
    };

    const handleShareDialog = () => {
        setShareDialogOpened(!shareDialogOpened);
    };

    const handleRejectDialog = () => {
        if (!reject || !audition.active) return;
        setRejectDialogOpened(!rejectDialogOpened);
    };

    const handleHireDialog = () => {
        if (!hire || !audition.active) return;
        setHireDialogOpened(!hireDialogOpened);
    };

    const onSuccess = () => {
        clearSelection();
        loadCandidates();
        if (onSuccessProp) onSuccessProp();
    };

    const targetCandidates = Object.values(selectedCandidates).map(candidate => ({ ...candidate, id: candidate.candidateId }));

    const handleMessageDialog = () => {
        if (!message) return;
        if (!messageDialogOpened) {
            sendEvent();
        }
        setMessageDialogOpened(!messageDialogOpened);
    };

    const sendEvent = (msg) => {
        const { uuid: ttId, name: ttName } = audition;
        AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
            ttId,
            ttName,
            actionType: 'message',
            numberCandidatesActioned: targetCandidates.length,
            content: msg
        });
    };

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.item}>
                    <span className={classes.itemNumber}>{Object.keys(selectedCandidates).length}</span>&nbsp;selected
                </div>
                <div
                    role="presentation"
                    onClick={handleExtendDialog}
                    className={clsx(
                        classes.item,
                        reset && classes.itemClickable,
                        (audition.expired || !audition.active || !audition.valid) && classes.itemDisable
                    )}
                >
                    <img src={ExtendIcon} alt="extent" /> extend
                </div>
                <div
                    role="presentation"
                    onClick={handleShareDialog}
                    className={clsx(classes.item, classes.itemClickable)}
                >
                    <img src={ShareIcon} alt="share" /> share
                </div>
                <div
                    role="presentation"
                    onClick={handleMessageDialog}
                    className={clsx(classes.item, message && classes.itemClickable)}
                >
                    <img src={message ? MessageIcon : MessageInactiveIcon} alt="extent" /> message
                </div>
                <div
                    role="presentation"
                    className={clsx(classes.item, reject && classes.itemClickable, !audition.active && classes.itemDisable)}
                    onClick={handleRejectDialog}
                >
                    <img src={RejectIcon} alt="extent" /> reject
                </div>
                {!isInviteSection && (
                    <div
                        role="presentation"
                        className={clsx(classes.item, hire && classes.itemClickable, !audition.active && classes.itemDisable)}
                        onClick={handleHireDialog}
                    >
                        <img src={HireIcon} alt="extent" /> hire
                    </div>
                )}
                <Button
                    color="primary"
                    onClick={clearSelection}
                    className={classes.button}
                >
                    Clear Selection
                </Button>
            </div>
            {
                messageDialogOpened && (
                    <CandidateMessaging
                        open={messageDialogOpened}
                        onSuccess={clearSelection}
                        targetCandidates={targetCandidates}
                        handleClickMessageModalClose={handleMessageDialog}
                        sentMessage={sendEvent}
                    />
                )
            }
            <ExtendMultipleAssessmentDialog
                open={extendDialogOpened}
                slug={opportunitySlug}
                audition={audition}
                onSuccess={onSuccess}
                handleClose={handleExtendDialog}
                userAuditionIds={selectedUserAuditionsIds}
            />
            <RejectMultipleAssessmentDialog
                open={rejectDialogOpened}
                slug={opportunitySlug}
                onSuccess={onSuccess}
                audition={audition}
                handleClose={handleRejectDialog}
                userIds={selectedCandidatesIds}
            />
            <HireMultipleAssessmentDialog
                open={hireDialogOpened}
                onSuccess={onSuccess}
                audition={audition}
                handleClose={handleHireDialog}
                columnUserIds={selectedColumnUserIds}
                selectedCandidates={Object.values(selectedCandidates)}
            />
            <ShareCandidateDialog
                open={shareDialogOpened}
                opportunitySlug={opportunitySlug}
                onClose={handleShareDialog}
                disabledScoring={!scoreShare}
                company={company}
                flashMessage={flashMessage}
                userIds={selectedCandidatesIds}
                assessment={audition}
                assessmentSlug={audition.slug}
            />
        </>
    );
});

export default BatchBlock;
