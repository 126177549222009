import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { handleSubmitCard, updateCreditCard } from 'requests/SubscriptionRequests';
import PlanEvents from 'events/PlanEvents';
import SettingsEvents from 'events/SettingsEvents';
import TextInput from 'libraries/TextInput';
import { appCtx } from '../../appStore';

import styles from './styles';

const CardDetails = observer(({
    isCardEdit = false,
    updateCompany,
    classes,
    onUpdateCreditCard,
    className = '',
    disabledProceed
}) => {
    const [abn, setAbn] = useState('');
    const [card, setCard] = useState({
        number: '',
        cvc: '',
        exp_month: '',
        exp_year: ''
    });
    const [disableGetVervoe, setDisableGetVervoe] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const { flashMessage, loggedUser, company } = useContext(appCtx);

    useEffect(() => {
        if (loggedUser && loggedUser.abn_acn) {
            setAbn(loggedUser.abn_acn);
        }
    }, [loggedUser]);

    const submitCard = () => {
        if (!disableGetVervoe) {
            setStripeError(null);
            setDisableGetVervoe(true);
            handleSubmitCard()
                .then((data) => {
                    if (data.success) {
                        if (window.Stripe) {
                            window.Stripe.setPublishableKey(data.stripePublicKey);
                            window.Stripe.card.createToken(card, stripeResponseHandler);
                        }
                    } else {
                        setDisableGetVervoe(false);
                    }
                }).catch(() => {
                    SettingsEvents.CREDIT_CARD_UPDATE_FAILED();
                    setDisableGetVervoe(false);
                    PlanEvents.PLAN_UPGRADE_FAILED({ place: 'card error' });
                });
        }
    };

    const handleChange = (event, index) => {
        const { value: val } = event.target;
        const value = val.trim();
        if (!Number.isInteger(+value)) return;
        const newCard = JSON.parse(JSON.stringify(card));
        newCard[index] = value;
        setCard(newCard);
    };


    const handleChangeAbn = (event) => {
        setAbn(event.target.value);
    };

    const stripeResponseHandler = (status, response) => {
        if (status === 200) {
            updateCreditCard(response.id, abn)
                .then(({ success, data }) => {
                    if (success) {
                        updateCompany(data);
                        if (onUpdateCreditCard) onUpdateCreditCard();
                        SettingsEvents.CREDIT_CARD_UPDATED();
                    } else {
                        setDisableGetVervoe(false);
                    }
                })
                .catch(({ response: { data } }) => {
                    flashMessage(data.errors.message);
                    SettingsEvents.CREDIT_CARD_UPDATE_FAILED();
                    if (!isCardEdit) PlanEvents.PLAN_UPGRADE_FAILED();
                    setDisableGetVervoe(false);
                });
        } else {
            setDisableGetVervoe(false);
            setStripeError(response.error.message);
            if (!isCardEdit) PlanEvents.PLAN_UPGRADE_FAILED();
            SettingsEvents.CREDIT_CARD_UPDATE_FAILED();
        }
    };

    return (
        <div className={clsx(classes.wrapper, className)}>
            <div className={classes.header}>Payment Details</div>
            <div className={classes.content}>
                {
                    loggedUser.country === 'Australia' && !loggedUser.abn_acn
                    && (
                        <TextInput
                            value={abn}
                            label="ABN or ACN"
                            fullWidth
                            className="u-mrg--bx3"
                            variant="outlined"
                            onChange={handleChangeAbn}
                        />
                    )
                }
                <TextInput
                    value={card.number}
                    name="card_number"
                    className="u-mrg--bx4"
                    label="Credit Card Number"
                    fullWidth
                    variant="outlined"
                    onChange={e => handleChange(e, 'number')}
                />
                <p className={classes.formHeading}>Expiry</p>
                <div className={classes.formRow}>
                    <div className={classes.formPart}>
                        <TextInput
                            value={card.exp_month}
                            className="card-expiry-month"
                            label="Month (MM)"
                            variant="outlined"
                            fullWidth
                            maxLength={2}
                            onChange={e => handleChange(e, 'exp_month')}
                        />
                    </div>
                    <div className={classes.formPart}>
                        <TextInput
                            value={card.exp_year}
                            label="Year (YY)"
                            fullWidth
                            maxLength={2}
                            variant="outlined"
                            onChange={e => handleChange(e, 'exp_year')}
                        />
                    </div>
                    <div className={classes.formPart}>
                        <TextInput
                            value={card.cvc}
                            id="qa-cc-cvc-txt"
                            label="CVC"
                            fullWidth
                            variant="outlined"
                            type="password"
                            onChange={e => handleChange(e, 'cvc')}
                        />
                        <div className="u-pos--relative  u-z--10">
                            <div className="c-link  c-link--blue  u-txt--12" data-tip="The Card Verification Code, or CVC* is the final three digits of the number printed on the signature strip on the reverse of your card.">What is this?</div>
                            <ReactTooltip place="top" effect="solid" type="light" className="c-tooltip u-txt--13 u-txt--left" />
                        </div>
                    </div>
                </div>
                <Button
                    className={classes.proceedButton}
                    color="primary"
                    fullWidth
                    disabled={disableGetVervoe || disabledProceed}
                    variant="contained"
                    onClick={submitCard}
                    type="submit"
                >
                    {
                        disableGetVervoe ? (
                            <CircularProgress
                                size={25}
                                className={classes.loader}
                            />
                        ) : 'Proceed'
                    }
                </Button>
                <div className={classes.error}>{ stripeError }</div>
                <div className={classes.taxesLabel}>
                    Prices are in { company.currencyStripe === 'usd' ? 'US' : 'AU' } dollars and exclude any applicable taxes. You can cancel your subscription at any time.&nbsp;
                    <a
                        href="https://vervoe.com/global-terms-of-use/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms and conditions
                    </a>
                </div>
            </div>
        </div>
    );
});

export default withStyles(styles)(CardDetails);
