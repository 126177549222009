export default theme => ({
    mansoryWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '20px -19px 0',
        '& > div': {
            marginLeft: 19,
            marginRight: 19,
            marginBottom: 30
        },
        [theme.breakpoints.down('lg')]: {
            margin: '0 -20px 0',
            '& > div': {
                marginLeft: 20,
                marginRight: 20
            }
        },
        '@media only screen and (max-width: 790px)': {
            '& > div': {
                margin: '0 calc(50% - 160px) 30px'
            }
        }
    },
    jobCount: {
        marginBottom: 32
    },
    wrapper: {
        borderRadius: 4,
        margin: 'auto',
        '@media only screen and (max-width: 790px)': {
            padding: 15
        },
        '@media only screen and (max-width: 763px)': {
            padding: 0,
            backgroundColor: 'inherit',
            border: 'none'
        }
    },
    notFoundWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: 50,

        '& > img': {
            width: 30,
            height: 'auto',
            marginBottom: 25
        }
    },
    listSubHeader: {
        marginTop: 5,
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.grey[600]
    },
    progressWrapper: {
        textAlign: 'center'
    },
    countLabel: {
        fontSize: 16,
        color: theme.palette.grey[500]
    },
    notFoundText: {
        width: '100%',
        textAlign: 'center',
        color: 'black',
        fontWeight: 600,
        fontSize: 14
    }
});
