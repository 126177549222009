import React from 'react';
import Player from 'components/ziggeo/player';
import Transcript from 'components/candidate_cards/Transcript';
import VideoPlayer from 'components/media_player/video_player';

const MediaAnswer = ({ question: { ziggeo }, audio, NoAnswerComponent, showTranscript }) => (
    ziggeo ? (
        <div>
            {audio ? (
                <div className="u-txt--center">
                    <Player audio={audio} ziggeo_token={ziggeo.token} />
                </div>
            ) : <VideoPlayer ziggeo={ziggeo} />}
            {
                showTranscript && ziggeo && ziggeo.transcription && (
                    <div className="u-txt--left u-mrg--tx6">
                        <Transcript value={ziggeo.transcription} />
                    </div>
                )
            }
        </div>
    ) : <NoAnswerComponent />
);

export default MediaAnswer;
