import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Layout from 'components/layout';
import InnerHeader from 'components/layout/InnerHeader';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';
import BackIconButton from 'components/assessments_pages/BackIconButton';

import { loadContentLibraryAssessment } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';

import AssessmentStatus from '../common/AssessmentStatus';
import EditQuestions from './edit_questions';
import EditSettings from './edit_settings';
import EditDrawer from './EditDrawer';
import AssessmentSynchronizeStatus from './AssessmentSynchronizeStatus';
import PreviewButton from './PreviewButton';


const ContentAssessment = observer(({ location, history, match }) => {
    const [audition, setAudition] = useState(null);
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const { auditionUuid } = match.params;
    const { pathname } = location;

    useEffect(() => {
        if (pathname === `/content-library/${auditionUuid}`) {
            history.push(`/content-library/${auditionUuid}/questions`);
        }
        setLoading(true);
        getAssessment();
    }, []);

    const getAssessment = () => {
        if (!auditionUuid) return;
        loadContentLibraryAssessment(auditionUuid)
            .then(({ data, success }) => {
                if (data && success) {
                    setAudition(data);
                }
            })
            .catch(() => {
                goToLibrary();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const goToLibrary = () => {
        history.push('/content-library');
    };


    let header = '';
    if (audition && audition.settings) {
        header = audition.settings.name;
    }

    return (
        <Layout>
            <InnerHeader
                header={(
                    <>
                        <BackIconButton onClick={goToLibrary} />
                        {header}
                        <AssessmentStatus
                            className="u-mrg--lx2"
                            isInteractive
                            flashMessage={flashMessage}
                            uuid={audition ? audition.uuid : ''}
                            setAudition={setAudition}
                            status={audition ? audition.status : ''}
                        />
                        {
                            audition && audition.status !== 'draft' && (
                                <AssessmentSynchronizeStatus
                                    className="u-mrg--lx2"
                                    flashMessage={flashMessage}
                                    setAudition={setAudition}
                                    uuid={audition ? audition.uuid : ''}
                                    synchronized={audition ? audition.synchronized : true}
                                />
                            )
                        }
                    </>
                )}
                rightPart={<PreviewButton uuid={auditionUuid} />}
            />
            <DrawerPageWrapper
                setAudition={setAudition}
                EditDrawer={EditDrawer}
                loading={loading}
                audition={audition}
                setLoading={setLoading}
                routes={[{
                    path: '/content-library/:auditionUuid/settings',
                    Component: EditSettings
                }, {
                    path: '/content-library/:auditionUuid/questions',
                    Component: EditQuestions
                }]}
            />
        </Layout>
    );
});

export default withRouter(ContentAssessment);
