import { createContext } from 'react';
import { action, computed, observable } from 'mobx';
import axios from 'axios';
import { getCandidatesInAssessment, getFiltersCount } from 'requests/AssessmentRequests';
import { clone } from 'helper/commonFunctions';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

let FILTER_STATUS_SLUG = 'filter-select-status';
let FILTER_TAGS_SLUG = 'filter-select-tags';
const SORT_PAGE_IDENTIFIER = 'sort-results-page';

const { CancelToken } = axios;
let source = CancelToken.source();

class ResultsStore {
    @observable allCandidates = [];

    @observable totalCount = 0;

    @observable currentCount = 0;

    @observable page = 1;

    @observable hasMore = false;

    @observable query = '';

    @observable filterCategory = {};

    @observable filterTagsCategory = {};

    @observable filterPlagiarismCategory = {};

    @observable isLoading = true;

    @observable isLoadingLocal = false;

    @observable sortCategory = {};

    @observable sortOrder = null;

    @observable selectedCandidates = {};

    @observable slug = '';

    @observable sortLoading = false;

    @observable sortProgress = 0;

    @observable isShortList = false;

    @observable isEmployerSortEnabled = false;

    @observable filtersCount = {};

    timerId = null;

    @computed get selectedCandidatesIds() {
        return Object.keys(this.selectedCandidates);
    }

    @computed get selectedUserAuditionsIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].userAuditionId);
    }

    @computed get selectedColumnUserIds() {
        return Object.keys(this.selectedCandidates).map(key => this.selectedCandidates[key].columnUserId);
    }

    @action setSlug = (slug) => {
        this.slug = slug;
        FILTER_STATUS_SLUG += `_${this.slug}`;
        FILTER_TAGS_SLUG += `_${this.slug}`;
    };

    @action refreshCandidate = (candidate) => {
        const { id, shortlistedBy, shortlistedAt } = candidate;
        const refreshedAllCandidates = clone(this.allCandidates);
        const index = refreshedAllCandidates.findIndex(item => item.userAuditionId === id);
        if (index === -1) return;
        refreshedAllCandidates[index].shortlistedBy = shortlistedBy;
        refreshedAllCandidates[index].shortlistedAt = shortlistedAt;
        this.allCandidates = refreshedAllCandidates;
    };


    @action cleanFilterSettings = (slug, except = '') => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(slug));
        localStorageItemsStatuses.forEach((item) => {
            if (item !== `${slug}-${except}`) {
                localStorage.removeItem(item);
            }
        });
    };

    @action setFilterCategory = () => {
        const localStorageItems = Object.keys({ ...localStorage });
        const localStorageItemsStatuses = localStorageItems.filter(item => item.includes(FILTER_STATUS_SLUG));
        const localStorageItemsTags = localStorageItems.filter(item => item.includes(FILTER_TAGS_SLUG));

        if (localStorageItemsStatuses.length && !this.filterCategory.shortlist) {
            this.filterCategoryStr = localStorageItemsStatuses[0].replace(`${FILTER_STATUS_SLUG}-`, '');
            this.cleanFilterSettings(FILTER_STATUS_SLUG, this.filterCategoryStr);
            this.filterCategory[this.filterCategoryStr] = true;
        }

        if (localStorageItemsTags.length) {
            localStorageItemsTags.forEach((item) => {
                const tagName = item.replace(`${FILTER_TAGS_SLUG}-`, '');
                this.filterTagsCategory[tagName] = true;
            });
        }
    };

    @action loadCandidates = (query = this.query, addLastOne = false) => {
        if (!this.slug) return;
        if (localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`) && !this.sortCategory.shortlist) {
            const [sortBy, sortOrder] = localStorage.getItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`).split(',');
            this.sortCategory[sortBy] = true;
            this.sortOrder = sortOrder;
        }

        const filterCategoryStr = Object.keys(this.filterCategory).join(',');
        const filterTagsCategoryStr = Object.keys(this.filterTagsCategory).join(',');
        const sortCategoryStr = Object.keys(this.sortCategory).join(',');
        source = CancelToken.source();
        this.isLoadingLocal = true;
        return getCandidatesInAssessment(this.slug, this.page, query, filterCategoryStr, filterTagsCategoryStr, sortCategoryStr, this.sortOrder, this.filterPlagiarismCategory, { cancelToken: source.token })
            .then(({ success, data }) => {
                if (success && data) {
                    const { items, hasMorePages, total, currentPage, count } = data;
                    if (this.isShortList && currentPage === this.page && this.page !== 1) {
                        if (addLastOne) {
                            this.allCandidates.push(items[items.length - 1]);
                        } else {
                            this.allCandidates = this.allCandidates.concat(items);
                        }
                    } else {
                        this.allCandidates = items;
                    }
                    this.totalCount = total;
                    this.page = currentPage;
                    this.hasMore = hasMorePages;
                    this.currentCount = count;
                }
            })
            .catch(() => {})
            .finally(() => {
                this.isLoading = false;
                this.isLoadingLocal = false;
            });
    };

    @action loadFiltersStats = () => {
        if (!this.slug) return;
        return getFiltersCount(this.slug)
            .then(({ success, data }) => {
                if (success && data) {
                    this.filtersCount = data;
                }
            });
    };

    @action handleChangeFilter = (e, audition) => {
        const { value } = e.target;
        this.query = value;
        this.page = 1;
        this.isLoadingLocal = true;
        clearTimeout(this.timerId);
        this.timerId = setTimeout(async () => {
            await this.loadCandidates();
            MiscellaneousEvents.CANDIDATE_SEARCH_CONDUCTED({
                numberResults: this.currentCount,
                searchTerm: value,
                context: 'Select',
                ttId: audition.uuid,
                ttName: audition.name
            });
        }, 300);
    };

    @action handleChangePage = (page) => {
        this.page = page;
        this.loadCandidates();
    };

    @action handleChangeFilterSelect = (filterCategory, isTags) => {
        if (filterCategory === 'multipleGeolocations' || filterCategory === 'plagiarism') {
            if (this.filterPlagiarismCategory[filterCategory]) {
                delete this.filterPlagiarismCategory[filterCategory];
            } else {
                this.filterPlagiarismCategory[filterCategory] = true;
            }
        } else if (isTags) {
            if (this.filterTagsCategory[filterCategory]) {
                localStorage.removeItem(`${FILTER_TAGS_SLUG}-${filterCategory}`);
                delete this.filterTagsCategory[filterCategory];
            } else {
                this.filterTagsCategory[filterCategory] = true;
                localStorage.setItem(`${FILTER_TAGS_SLUG}-${filterCategory}`, 'true');
            }
        } else {
            if (this.filterCategory.shortlist) { // impossible to remove shortlist filter
                this.filterCategory = { shortlist: true };
            }
            if (filterCategory === 'all') {
                this.filterCategory = {};
                this.cleanFilterSettings(FILTER_STATUS_SLUG);
            } else {
                this.cleanFilterSettings(FILTER_STATUS_SLUG, filterCategory);
                this.filterCategory = { [filterCategory]: true };
                localStorage.setItem(`${FILTER_STATUS_SLUG}-${filterCategory}`, 'true');
            }
        }

        this.page = 1;
        this.isLoadingLocal = true;
        this.loadCandidates();
    };


    @action handleRemoveFilterSelect = (filterCategory, isTags) => {
        if (filterCategory === 'multipleGeolocations' || filterCategory === 'plagiarism') {
            delete this.filterPlagiarismCategory[filterCategory];
        } else if (isTags) {
            localStorage.removeItem(`${FILTER_TAGS_SLUG}-${filterCategory}`);
            delete this.filterTagsCategory[filterCategory];
        } else if (this.filterCategory.shortlist) { // impossible to remove shortlist filter
            this.filterCategory = { shortlist: true };
        } else {
            this.cleanFilterSettings(FILTER_STATUS_SLUG);
            this.filterCategory = {};
        }

        this.page = 1;
        this.loadCandidates();
    };

    @action handleChangeSortCategory = (sortCategory, sortOrder) => {
        this.sortCategory = this.sortCategory.shortlist ? { shortlist: true } : {}; // impossible to remove shortlist sort
        this.sortCategory[sortCategory] = true;
        this.sortOrder = sortOrder;
        if (sortCategory && sortOrder && !this.sortCategory.shortlist) {
            localStorage.setItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`, `${sortCategory},${sortOrder}`);
        } else {
            localStorage.removeItem(`${SORT_PAGE_IDENTIFIER}_${this.slug}`);
        }
        this.page = 1;
        this.loadCandidates();
    };


    @action setLoading = (value) => {
        this.isLoading = value;
    };

    @action cleanState = () => {
        if (source && source.token) source.cancel();
        this.initState();
    };

    @action initState = () => {
        source = null;
        this.allCandidates = [];
        this.totalCount = -1;
        this.page = 1;
        this.hasMore = false;
        this.query = '';
        this.filterCategory = {};
        this.filterTagsCategory = {};
        this.isLoading = false;
        this.isLoadingLocal = false;
        this.sortCategory = {};
        this.sortOrder = null;
        this.clearSelection();
        this.slug = '';
        this.sortProgress = 0;
        this.sortLoading = false;
        this.isShortList = false;
        this.isEmployerSortEnabled = false;
        this.filtersCount = {};


        FILTER_STATUS_SLUG = 'filter-select-status';
        FILTER_TAGS_SLUG = 'filter-select-tags';
    };

    @action toggleSelectedUserAuditionIds = (candidate) => {
        const { candidateId } = candidate;
        if (this.selectedCandidates[candidateId]) {
            delete this.selectedCandidates[candidateId];
        } else {
            this.selectedCandidates[candidateId] = candidate;
        }
    };

    @action clearSelection = () => {
        this.selectedCandidates = {};
    };

    @action toggleSelectAll = (selected) => {
        if (selected) {
            this.selectedCandidates = {};
        } else {
            this.allCandidates.forEach((item) => {
                this.selectedCandidates[item.candidateId] = item;
            });
        }
    };

    @action setSortParams = (sortProgress, sortLoading) => {
        this.sortProgress = sortProgress;
        this.sortLoading = sortLoading;
    }

    @action setShortlist = (value) => {
        this.isShortList = value;
        if (value) {
            this.sortCategory.shortlist = true;
            this.filterCategory.shortlist = true;
            this.sortOrder = 'desc';
        }
    }

    @action removedCandidateByIndex = (index) => {
        this.allCandidates.splice(index, 1);
        this.loadCandidates(this.query, true);
    }

    @action setEmployerSortEnabled = (employerSortEnabled) => {
        this.isEmployerSortEnabled = employerSortEnabled;
    }
}

export const candidateResultStore = new ResultsStore();
export const candidateResultCtx = createContext(candidateResultStore);
