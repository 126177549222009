import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { checkHasCompanyFeature, clone } from 'helper/commonFunctions';

import { withStyles } from '@mui/styles';
import TagButton from 'components/candidate_cards/CandidateActions/TagButton';
import CandidateMenu from 'components/candidate_cards/CandidateActions/CandidateMenu';
import { appCtx } from 'components/appStore';
import { SEND_SMS_INVITATION } from 'helper/constants';
import ShortlistButton from './ShortlistButton';
import CommentsButton from './CommentsButton';
import PreviewCandidateCard from './PreviewCandidateCard';
import TeamScore from '../TeamScore';
import RejectedIcon from './rejectedIcon.svg';
import { assessmentSettingsCtx } from '../../../store';


const styles = () => ({
    icon: {
        borderRadius: 4,
        padding: 6,
        marginRight: 5
    }
});

const CandidateActions = observer(({
    candidate, dataForEvents, onRemoveFromShortlist, isInviteSection = false,
    loadCandidates, loadFiltersStats, filterTagsCategory, loadAssessmentStats,
    loadExtensionsRequests, classes, commentsDisabled, shortlistDisabled, teamScoreDisabled
}) => {
    const {
        rejected, completionsPerOpportunityLimitExceeded,
        tags, shortlisted, countComment, candidateId, gradedBy,
        score, candidateStatus, invitationLink
    } = candidate;
    const [candidateTags, setCandidateTags] = useState(tags);
    const [candidateShortlisted, setCandidateShortlisted] = useState(shortlisted);

    const { audition, companyTags, setTags, getTags } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);

    const hired = candidateStatus === 'Hired';

    useEffect(() => {
        setCandidateTags(candidateTags);
    }, [tags]);

    useEffect(() => {
        setCandidateShortlisted(shortlisted);
    }, [shortlisted]);

    const addTag = (tag) => {
        const newCandidateTags = clone(candidateTags);
        newCandidateTags.push(tag);
        setCandidateTags(newCandidateTags);
    };

    const removeTag = (index) => {
        const newCandidateTags = clone(candidateTags);
        newCandidateTags.splice(index, 1);
        setCandidateTags(newCandidateTags);
    };

    const callback = () => {
        if (loadFiltersStats) loadFiltersStats();
        if (loadCandidates) loadCandidates();
        if (loadAssessmentStats) loadAssessmentStats();
        if (loadExtensionsRequests) loadExtensionsRequests();
    };

    const onRemove = () => {
        if (onRemoveFromShortlist) onRemoveFromShortlist();
        setCandidateShortlisted(false);
    };

    const onAdd = () => {
        setCandidateShortlisted(true);
    };

    const menuShownOptions = () => {
        const options = ['message', 'extend', 'feedback', 'share'];

        if (!rejected) options.push('reject');
        else options.push('unreject');

        if (!hired && !rejected && isInviteSection && invitationLink) {
            options.splice(3, 0, 'invitation_link');
        }

        if (checkHasCompanyFeature(company, SEND_SMS_INVITATION)) {
            options.push('details');
        }

        if (!isInviteSection && !hired) options.push('hire');
        if (hired) options.push('unhire');

        return options;
    };

    return (
        <div className="u-dsp--f u-fdir--row u-ai--center u-jc--end">
            {!isInviteSection && (
                <TeamScore
                    gradedBy={gradedBy}
                    candidateId={candidateId}
                    score={score}
                    disabled={teamScoreDisabled}
                />
            )}
            {
                !isInviteSection && !rejected && (
                    <ShortlistButton
                        candidate={candidate}
                        shortlisted={candidateShortlisted}
                        disabled={completionsPerOpportunityLimitExceeded || shortlistDisabled || !audition.active}
                        dataForEvents={dataForEvents}
                        onRemove={onRemove}
                        onAdd={onAdd}
                    />
                )
            }
            {!isInviteSection && rejected && <img className={classes.icon} src={RejectedIcon} alt="x" />}
            {!isInviteSection && (
                <CommentsButton
                    count={countComment}
                    disabled={completionsPerOpportunityLimitExceeded || commentsDisabled}
                />
            )}
            <TagButton
                dataForEvents={dataForEvents}
                tags={candidateTags}
                addTag={addTag}
                removeTag={removeTag}
                candidate={candidate}
                loadCandidates={loadCandidates}
                loadFiltersStats={loadFiltersStats}
                filterTagsCategory={filterTagsCategory}
                audition={audition}
                companyTags={companyTags}
                setTags={setTags}
                getTags={getTags}
            />
            <CandidateMenu
                candidate={candidate}
                callback={callback}
                dataForEvents={dataForEvents}
                shownOptions={menuShownOptions()}
                audition={audition}
                company={company}
            />
            <PreviewCandidateCard candidate={{ ...candidate, tags: candidateTags, shortlisted: candidateShortlisted }} />
        </div>
    );
});

export default withStyles(styles)(withRouter(CandidateActions));
