import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import styles from './styles';

const EmptyDataBlock = ({
    audition = {}, imgSrc, classes, history, label = '',
    selectedQuestionGroup,
    customEmptyLabel, customHeader
}) => {
    const { slug, active } = audition;

    const goToInvite = () => {
        history.push(`/script/invite/${slug}/invitation`);
    };

    return (
        <div className={classes.wrapper}>
            <div className="u-txt--center">
                <img src={imgSrc} alt="No data" className="u-mrg--bx2" />
                <Typography variant="h5" className="u-mrg--bx1">
                    {customHeader || `No ${label} data available ${selectedQuestionGroup !== 'all' ? 'for this skill' : ''}`}
                </Typography>
                {
                    (active && selectedQuestionGroup === 'all') && (
                        <p>
                            {
                                customEmptyLabel || (
                                    <>
                                        <span
                                            role="presentation"
                                            className={classes.inviteButton}
                                            onClick={goToInvite}
                                        >
                                            Invite
                                        </span> candidates to complete your assessment
                                    </>
                                )
                            }
                        </p>
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(EmptyDataBlock));
