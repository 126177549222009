import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import {
    TEXT, AUDIO, VIDEO, ANY_FILE, IMAGE,
    CODE_TEST, FEEDBACK, CYBER_SIMULATOR,
    SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR
} from 'helper/constants';
import { nl2brCustom } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import TypeWrapper from './components/TypeWrapper';
import TextQuestion from './components/TextQuestion';
import ImageQuestion from './components/ImageQuestion';
import FileQuestion from './components/FileQuestion';
import MediaQuestion from './components/MediaQuestion';
import CodeEditorQuestion from '../CodeEditorQuestion';

const QuestionTypes = observer(({
    data: { file, ziggeo, codeEditor, type, description: descr, answerType },
    className = '', brandColor
}) => {
    const description = nl2brCustom(descr);
    const props = {
        className,
        description
    };
    const { company } = useContext(appCtx);

    if ([CYBER_SIMULATOR, SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR].includes(answerType)) return null;

    return (
        {
            [TEXT]: <TextQuestion {...props} />,
            [AUDIO]: (
                <TypeWrapper {...props}>
                    <MediaQuestion audio ziggeo={ziggeo} />
                </TypeWrapper>
            ),
            [VIDEO]: (
                <TypeWrapper {...props}>
                    <MediaQuestion ziggeo={ziggeo} />
                </TypeWrapper>
            ),
            [ANY_FILE]: (
                <TypeWrapper {...props}>
                    <FileQuestion file={file} brandColor={brandColor} />
                </TypeWrapper>
            ),
            [IMAGE]: (
                <TypeWrapper {...props}>
                    <ImageQuestion data={file && (process.env.REACT_APP_API_URL + file.fileUrl)} />
                </TypeWrapper>
            ),
            [CODE_TEST]: (
                <TypeWrapper {...props}>
                    <CodeEditorQuestion data={codeEditor} />
                </TypeWrapper>
            ),
            [FEEDBACK]: <TextQuestion
                description={
                    `Do you have any final thoughts or comments for ${company.name}? 
                    Is there anything else you would like to add? This is the final question of the assessment and is optional.`
                }
            />
        }[type] || <TextQuestion {...props} />
    );
});


export default QuestionTypes;
