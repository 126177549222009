import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LoaderBounce from 'components/loaders/LoaderBounce';
import CandidatePhotoInfo from 'components/candidate_cards/CandidateCardWrapper/CandidatePhotoInfo';
import CandidateStatusInfo from 'components/candidate_cards/CandidateCardWrapper/CandidateStatusInfo';
import CommentsInfo from 'components/candidate_cards/CandidateCardWrapper/CommentsInfo';
import ReportCardHeader from 'components/candidate_cards/CandidateCardWrapper/ReportCardHeader';
import TagsInfo from 'components/candidate_cards/CandidateCardWrapper/TagsInfo';
import CardScoreBlock from 'components/candidate_cards/CandidateCardWrapper/CardScoreBlock';
import GradeBlock from 'components/candidate_cards/CandidateCardWrapper/GradeBlock';
import FraudDetectedBanner from 'components/candidate_cards/CandidateCardWrapper/WarningBanner/FraudDetectedBanner';
import CheatDetectedBanner from 'components/candidate_cards/CandidateCardWrapper/WarningBanner/CheatDetectedBanner';
import NotCompletedBlock from 'components/candidate_cards/CandidateCardWrapper/NotCompletedBlock';
import SatisfactionBlock from 'components/candidate_cards/CandidateCardWrapper/SatisfactionBlock';
import StatusHistory from 'components/candidate_cards/CandidateCardWrapper/StatusHistory';
import { getCandidateProfile } from 'requests/APIrequests';

const styles = {
    detailInfoItem: {
        display: 'block',
        marginRight: 20,
        marginBottom: '0 !important',
        whiteSpace: 'nowrap'
    }
};

const CandidateContent = observer(({
    getUserInfo, company, isLoading, classes,
    user, opportunitySlug, audition, audition: { uuid, name },
    loadCandidatePosts, propPublic, authorizeGuest, candidatesProp, gradeableCard,
    addCandidateComment, loadGradeQuestion, gradeFunction,
    getAuditionQuestionResults, hideCandidateDetails, isLoadingAudition,
    getAuditionScreeningResults
}) => {
    const [showHireBanner, setShowHireBanner] = useState(false);
    const [profile, setProfile] = useState({});

    useEffect(() => {
        setShowHireBanner(false);
    }, [user && user.id]);

    const dataForEvents = {
        candidateId: user ? user.candidateUuid : null,
        ttId: uuid,
        ttName: name,
        context: `candidate multiple ${gradeableCard ? 'agency' : 'public'} card`
    };


    const getUserProfile = (userUuid, callback = () => {}) => {
        getCandidateProfile(userUuid, Boolean(propPublic))
            .then((response) => {
                const { success, data } = response;
                if (success) {
                    setProfile(data);
                }
            })
            .finally(callback);
    };

    if (isLoading) {
        return (
            <div className="u-txt--center u-pdn--tx10 u-pdn--bx10">
                <LoaderBounce />
            </div>
        );
    }

    if (!user) return null;
    const { permissions } = audition;

    return (
        <>
            <CandidatePhotoInfo
                {...{
                    user,
                    audition,
                    company,
                    propPublic,
                    getUserInfo,
                    opportunitySlug,
                    showHireBanner,
                    setShowHireBanner,
                    hideCandidateDetails
                }}
            />
            {
                isLoadingAudition && (
                    <div className="u-txt--center u-pdn--tx10 u-pdn--bx10 u-pos--overlay">
                        <LoaderBounce />
                    </div>
                )
            }
            <CandidateStatusInfo
                dataForEvents={dataForEvents}
                user={user}
                company={company}
                permissions={permissions}
                propPublic={propPublic}
                showHireBanner={showHireBanner}
                setShowHireBanner={setShowHireBanner}
                hideCandidateDetails={hideCandidateDetails}
                className="u-dsp--distribute u-pdn--tx3 u-pdn--bx3 u-ovf--a"
                classNameDetailInfo={classes.detailInfoItem}
                getUserProfile={getUserProfile}
                profile={profile}
                audition={audition}
            />
            <TagsInfo
                candidate={user}
                audition={audition}
                propPublic={propPublic}
            />
            {
                !candidatesProp && (gradeableCard || !propPublic) ? (
                    <CommentsInfo
                        user={user}
                        audition={audition}
                        opportunitySlug={opportunitySlug}
                        loadCandidatePosts={loadCandidatePosts}
                        propPublic={propPublic}
                        authorizeGuest={authorizeGuest}
                        addCandidateComment={addCandidateComment}
                    />
                ) : <br />
            }
            <FraudDetectedBanner candidate={user} />
            <CheatDetectedBanner candidate={user} company={company} />
            <ReportCardHeader
                audition={audition}
                opportunitySlug={opportunitySlug}
                candidate={user}
                gradeableCard={gradeableCard}
                propPublic={propPublic}
                ReportHeaderComp={(
                    <Typography variant="h4" style={{ whiteSpace: 'pre-wrap' }}>
                        {audition.name}
                    </Typography>
                )}
            />
            {
                user.completed ? (
                    <>
                        {
                            !(gradeableCard && !user.score) && (
                                <CardScoreBlock
                                    gradeableCard={gradeableCard}
                                    propPublic={propPublic}
                                    candidate={user}
                                />
                            )
                        }
                    </>
                ) : <NotCompletedBlock audition={audition} />
            }
            {
                user.statusHistory && <StatusHistory statusHistory={user.statusHistory} />
            }
            {
                user.completed && (
                    <SatisfactionBlock
                        audition={audition}
                        candidate={user}
                        propPublic={propPublic}
                    />
                )
            }
            {
                (user.completed || user.screeningQuestions) && (
                    <GradeBlock
                        audition={audition}
                        candidate={user}
                        propPublic={propPublic}
                        gradeFunction={gradeFunction}
                        updateUserAuditionAndBox={getUserInfo}
                        loadGradeQuestion={loadGradeQuestion}
                        gradeableCard={gradeableCard}
                        authorizeGuest={authorizeGuest}
                        getAuditionResults={getAuditionQuestionResults}
                        getAuditionScreeningResults={getAuditionScreeningResults}
                    />
                )
            }
        </>
    );
});

export default withStyles(styles)(withRouter(CandidateContent));
