import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import NoSkillsContent from './NoSkillsContent';
import SkillScoreRow from './SkillScoreRow';

const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: 'none',
        marginBottom: '0 !important'
    },
    accordionSummary: {
        padding: 0,
        '&$accordionSummaryContentExpanded': {
            minHeight: 48
        }
    },
    accordionDetails: {
        padding: '15px 0 15px 35px',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        marginTop: 8
    },
    link: {
        color: theme.palette.blue[500],
        marginTop: -2
    },
    accordionSummaryContent: {
        margin: 0,
        display: 'block',
        '&$accordionSummaryContentExpanded': {
            margin: 0
        }
    },
    accordionSummaryContentExpanded: {
        margin: 0
    },
    expandIconButton: {
        position: 'absolute',
        right: -12,
        top: 22
    }
}));


const ScoreBlock = ({ skillScore, isRejected, smallCard }) => {
    const [expanded, setExpanded] = useState(true);
    const classes = useStyles();
    const { taskQuestionScores, groupName } = skillScore;
    const showTaskScores = Boolean(taskQuestionScores && taskQuestionScores.length) && !smallCard;

    const handleExpanded = () => {
        setExpanded(!expanded);
    };

    if (!showTaskScores) {
        return (
            <SkillScoreRow
                key={groupName}
                skillScore={skillScore}
                rejected={isRejected}
            />
        );
    }


    return (
        <Accordion
            className={classes.accordion}
            expanded={expanded}
            onChange={handleExpanded}
        >
            <AccordionSummary
                className={classes.accordionSummary}
                classes={{
                    expandIconWrapper: classes.expandIconButton,
                    content: classes.accordionSummaryContent,
                    expanded: classes.accordionSummaryContentExpanded
                }}
                expandIcon={(
                    <ExpandMoreIcon
                        color="primary"
                        className={classes.expandIcon}
                    />
                )}
            >
                <SkillScoreRow
                    skillScore={skillScore}
                    rejected={isRejected}
                    classNameWrapper="u-mrg--bx0"
                    classNameRow="u-mrg--bx0"
                />
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {
                    taskQuestionScores.map(taskScore => (
                        <SkillScoreRow
                            key={taskScore.groupName}
                            skillScore={{ ...taskScore, isManualScore: true }} // task are exception for score showing
                            rejected={isRejected}
                            isTask
                        />
                    ))
                }
            </AccordionDetails>
        </Accordion>
    );
};

const ScoreContainer = observer(({ candidate, propPublic, className, hideLabel, smallCard }) => {
    const { score, scoreType, rejected } = candidate;

    const renderScore = () => {
        if (scoreType !== 3) return <NoSkillsContent />;
        if (!score) return <span className="u-txt--13 u-txt--semibold">No score for this candidate.</span>;
        const { scoreByFormat } = score;
        if (scoreType === 3 && scoreByFormat) {
            return scoreByFormat.map(skillScore => (
                <ScoreBlock
                    smallCard={smallCard}
                    skillScore={skillScore}
                    isRejected={rejected && !propPublic}
                />
            ));
        }
        return null;
    };

    return (
        <div className="u-wdt--100p ">
            {
                !hideLabel && (
                    <Typography
                        variant="h6"
                        className="u-mrg--bx1"
                    >
                        Skill Scores
                    </Typography>
                )
            }
            <div className={className}>
                {renderScore()}
            </div>
        </div>
    );
});

export default ScoreContainer;
