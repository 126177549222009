import React from 'react';
import { withStyles } from '@mui/styles';
import { checkPluralNew } from 'helper/commonFunctions';
import CompanyLogo from '../../logos/company_logo';

const styles = theme => ({
    text: {
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.grey[900],
        whiteSpace: 'pre-wrap'
    },
    companyLogo: {
        color: theme.palette.grey[900],
        fontSize: 25,
        marginBottom: 12,
        fontFamily: 'Proximanova-Extrabold'
    }
});

const CardCompanyLogo = ({ company, hideCandidateDetails, gradeableCard, countCandidates, classes, opportunitiesList }) => {
    const auditionsNames = [];

    if (opportunitiesList) {
        opportunitiesList.forEach(({ columns }) => {
            columns.forEach(({ audition }) => {
                if (!audition) return;
                const { name } = audition;
                auditionsNames.push(name);
            });
        });
    }

    return (
        <header role="banner">
            {
                company && (
                    <CompanyLogo
                        sizeHeight={36}
                        company={company}
                        className={classes.companyLogo}
                    />
                )
            }
            {
                Boolean(auditionsNames && auditionsNames.length) && (
                    <p className={classes.text}>
                        A candidate report card for {checkPluralNew(auditionsNames.length, 'assessment')} <b>{auditionsNames.join(', ')}</b> has been shared with you.
                        You can view {gradeableCard ? 'and grade ' : ''}{checkPluralNew(countCandidates, "candidate's", 'candidates')} answers{hideCandidateDetails ? ' but candidate identifiers such as name and email have been hidden' : ''}.
                    </p>
                )
            }
        </header>
    );
};

export default withStyles(styles)(CardCompanyLogo);
