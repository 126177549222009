import React from 'react';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player/lazy';

const useStyles = makeStyles(() => ({
    videoWrapper: {
        '& video': {
            maxWidth: '100%'
        }
    }
}));

const VideoPlayer = ({ ziggeo }) => {
    const classes = useStyles();
    if (!ziggeo.media_file_url) return null;

    return (
        <div>
            <ReactPlayer
                url={ziggeo.media_file_url}
                controls
                width="auto"
                height="100%"
                className={classes.videoWrapper}
                config={{
                    file: {
                        attributes: { controlsList: 'nodownload' }
                    }
                }}
            />
        </div>
    );
};

export default VideoPlayer;
