import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { checkHasUserPermission, secondsToTimeObject, timeObjectToFormattedString, capitalizeFirstLetter } from 'helper/commonFunctions';
import HiringManagerButton from 'components/assessments_pages/HiredComponents/HiringManagerButton';
import HiringDateButton from 'components/assessments_pages/HiredComponents/HiredDateButton';
import SmsIndication from 'components/candidate_cards/SMSIndication';
import SeenStarted from 'img/seen_started.svg';
import WasResetIcon from 'img/wasReset.svg';

import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Tooltip from 'libraries/Tooltip';

import useStyles from './styles';

const DetailInfo = ({ header, className = '', value = '' }) => {
    const classes = useStyles();
    if (!value || !header) return null;
    return (
        <div className={clsx(classes.infoItemWrapper, className)}>
            <div className={classes.header}>{header}</div>
            <div className={classes.text}>
                {typeof value === 'string' ? <TruncateWithTooltip text={value} width={200} /> : value}
            </div>
        </div>
    );
};

const CandidateStatusInfo = ({
    user, company, permissions,
    dataForEvents, updateUserFields,
    showHireBanner, setShowHireBanner,
    hideCandidateDetails, getUserProfile,
    className = '', classNameDetailInfo = '',
    profile, isIntegration, propPublic, audition
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();

    const canEdit = checkHasUserPermission(company, permissions, 'edit');

    useEffect(() => {
        if (!user || !user.uuid || hideCandidateDetails || !getUserProfile) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        getUserProfile(user.uuid, () => {
            setIsLoading(false);
        });
    }, [user && user.uuid]);

    if (!user) return null;

    const {
        timeTaken, source, textStatus,
        candidateStatus, canAssignHiringManager = true,
        completed, phone, uuid, auditionOpened, wasReset, candidateFeedbackStatus
    } = user;


    const returnPhoneNumber = () => {
        if (isLoading && !phone) { // phone can come from both user (public cards) and profile
            return (
                <div className="u-dsp--f u-ai--center">
                    <CircularProgress className="u-mrg--tx1" size={13} />
                </div>
            );
        }
        const phoneValue = profile.phone || phone;
        if (phoneValue) {
            return (
                <div className="u-dsp--f u-ai--center">
                    <div className="u-mrg--rx1">
                        {phoneValue}
                    </div>
                    <SmsIndication
                        audition={audition}
                        candidate={user}
                        actionCallback={() => getUserProfile(uuid)}
                        hideLinks={propPublic}
                    />
                </div>
            );
        }
        return 'No phone number';
    };

    return (
        <div className={clsx(classes.wrapper, className)}>
            <DetailInfo
                header="Status"
                value={(
                    <>
                        { textStatus }
                        {
                            auditionOpened && (
                                <Tooltip label="Assessment opened">
                                    <img className="u-mrg--lx1" src={SeenStarted} alt="+" />
                                </Tooltip>
                            )
                        }
                        {
                            wasReset && (
                                <Tooltip label="Extension Received">
                                    <img className="u-mrg--lx1" src={WasResetIcon} alt="+" />
                                </Tooltip>
                            )
                        }
                    </>
                )}
                className={classNameDetailInfo}
            />
            {
                (candidateStatus === 'Hired' && !isIntegration && !propPublic) && (
                    <>
                        <DetailInfo
                            header="Hire Date"
                            className={classNameDetailInfo}
                            value={(
                                <HiringDateButton
                                    showHireBanner={showHireBanner}
                                    setShowHireBanner={setShowHireBanner}
                                    dataForEvents={dataForEvents}
                                    className={classes.hiredDateInput}
                                    candidate={user}
                                    assessmentSlug={audition.slug}
                                    disabled={!canEdit}
                                    onSuccess={updateUserFields}
                                />
                            )}
                        />
                        <DetailInfo
                            header="Hiring Manager"
                            className={classNameDetailInfo}
                            value={(
                                <HiringManagerButton
                                    showHireBanner={showHireBanner}
                                    setShowHireBanner={setShowHireBanner}
                                    dataForEvents={dataForEvents}
                                    candidate={user}
                                    assessmentSlug={audition.slug}
                                    canEdit={canEdit}
                                    canAssignHiringManager={canAssignHiringManager}
                                    onSuccess={updateUserFields}
                                />
                            )}
                        />
                    </>
                )
            }
            <DetailInfo
                header="Time Taken"
                className={classNameDetailInfo}
                value={
                    (completed && timeTaken !== null)
                        ? timeObjectToFormattedString(secondsToTimeObject(timeTaken))
                        : '-'
                }
            />
            {
                candidateFeedbackStatus && !propPublic && !isIntegration && (
                    <DetailInfo
                        header="Feedback Status"
                        className={classNameDetailInfo}
                        value={<TruncateWithTooltip text={capitalizeFirstLetter(candidateFeedbackStatus.toLowerCase())} />}
                    />
                )
            }
            <DetailInfo
                header="Source"
                className={classNameDetailInfo}
                value={<TruncateWithTooltip text={source} />}
            />
            <DetailInfo
                header="SMS Notification"
                className={classNameDetailInfo}
                value={hideCandidateDetails ? (
                    <div className={classes.hiddenBlock} />
                ) : returnPhoneNumber()}
            />
        </div>
    );
};

export default CandidateStatusInfo;
