import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles, withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import InnerTabs from 'components/layout/InnerTabs';
import UsersIcon from 'components/icons/UsersIcon';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CloseIcon from 'img/close.svg';

import styles from '../../styles';

const SelectTabs = observer(({
    classes, history, theme, match, isOnboarding, activeAssessment,

    candidateTabs, removeCandidateTab, setCandidateTabs,

    shortlistCandidatesCount, loadAssessmentStats, totalCompletionsCount
}) => {
    const { scriptSlug } = match.params;

    useEffect(() => {
        setCandidateTabs(scriptSlug);
    }, [scriptSlug]);

    const onRemove = (e, id) => {
        e.stopPropagation();
        removeCandidateTab(id, scriptSlug);
        history.push(`/${isOnboarding ? 'onboarding' : 'script'}/select/${scriptSlug}`);
    };

    const candidateTabsElements = candidateTabs.map(({ id, name }) => ({
        label: (<>
            <div>
                <TruncateWithTooltip text={name} width={250} />
            </div>
            <IconButton className={classes.closeIcon} onClick={e => onRemove(e, id)} size="large">
                <img src={CloseIcon} alt="x" />
            </IconButton>
        </>),
        url: `/${isOnboarding ? 'onboarding' : 'script'}/select/${scriptSlug}/candidate/${id}`
    }));

    return (
        <InnerTabs
            loadAssessmentStats={loadAssessmentStats}
            className={classes.innerTabs}
            tabList={[{
                label: (<>
                    <UsersIcon color={theme.palette.primary.main} />Candidates{totalCompletionsCount ? ` (${totalCompletionsCount})` : ''}
                </>),
                className: classes.allCandidatesTab,
                url: `/${isOnboarding ? 'onboarding' : 'script'}/select/${scriptSlug}`
            }, {
                label: (<>
                    <BookmarkIcon color={theme.palette.green[600]} />Shortlist {shortlistCandidatesCount ? `(${shortlistCandidatesCount})` : null}
                </>),
                className: classes.shortlistTab,
                disabled: isOnboarding || !activeAssessment,
                url: `/${isOnboarding ? 'onboarding' : 'script'}/select/${scriptSlug}/shortlist`
            }].concat(candidateTabsElements)}
        />
    );
});

export default withTheme(withStyles(styles)(withRouter(SelectTabs)));
