import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import SkillsOrTagsMenuItem from 'components/assessments_pages/asessment_settings_components/SkillsOrTagsMenuItem';

import styles from './styles';

const QuestionsListItem = ({
    noOtherItems, classes,
    groups, currentGroup,
    handleMove, question
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingName, setLoadingName] = useState(null);

    useEffect(() => {
        setLoadingName(null);
    }, [currentGroup]);

    const handleTouchTap = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setLoadingName(null);
        setAnchorEl(null);
    };


    if (!question) return null;
    const { id, sort, description, simulationDescription } = question;

    const onSelectSkill = (title, index) => {
        setLoadingName(title);
        handleMove(title, id, index);
    };

    return (
        <>
            <div className={clsx(classes.questionItem, noOtherItems && 'u-pdn--rx3')}>
                { !noOtherItems && <div className={classes.questionNumber}>Q{sort}</div> }
                <div className={clsx(classes.questionItemTextWrapper, noOtherItems && classes.questionItemTextWrapperEmpty)}>
                    { description || (simulationDescription || '').replace(/(<([^>]+)>)/gi, ' ') }
                </div>
                {
                    !noOtherItems && (
                        <Button
                            color="primary"
                            className={classes.moveButton}
                            onClick={handleTouchTap}
                        >
                            move
                        </Button>
                    )
                }
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleRequestClose}
                TransitionComponent={Fade}
            >
                {
                    groups && groups.map((group, index) => {
                        const isActive = currentGroup === group;
                        return (
                            <SkillsOrTagsMenuItem
                                key={group.id}
                                group={{ title: group.title }}
                                className={classes.skillsMenuItem}
                                isActive={isActive}
                                disabled={isActive}
                                onClick={title => onSelectSkill(title, index)}
                                loadingName={loadingName}
                            />
                        );
                    })
                }
            </Menu>
        </>
    );
};

export default withStyles(styles)(QuestionsListItem);
