import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getPublicCompany } from 'requests/APIrequests';
import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import PageWrapper from 'components/layout/PageWrapper';
import LoaderBounce from 'components/loaders/LoaderBounce';
import {
    addGuestCandidateComment, getPublicCandidateAudition,
    getGuestCandidatePosts, getPublicAuditionQuestionResults,
    getPublicLinkInfo, getPublicGradeQuestion, gradeGuestAnswer,
    getPublicAuditionScreeningQuestionResults
} from 'requests/JobOpportunityRequests';
import * as qs from 'query-string';

import CandidateCardWrapper from 'components/candidate_cards/CandidateCardWrapper';
import CardFooter from 'components/candidate_cards/PublicCardContent/CardFooter';
import AuditionListSelect from 'components/candidate_cards/CandidateCardWrapper/AuditionListSelect';
import CardCompanyLogo from 'components/candidate_cards/CardCompanyLogo';

import { appCtx } from 'components/appStore';
import MultipleCardsMode from './MultipleCardsMode';

const PublicCard = observer(({ match, location, history }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingAudition, setLoadingAudition] = useState(false);
    const [gradeableCard, setGradeableCard] = useState(false);
    const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState(0);
    const [selectedAuditionIndex, setSelectedAuditionIndex] = useState(0);
    const [candidateUuid, setCandidateUuid] = useState('');
    const [candidatesInfo, setCandidatesInfo] = useState({});
    const [hideCandidateDetails, setHideCandidateDetails] = useState(false);
    const { linkUuid } = match.params;
    const { company, setCompany } = useContext(appCtx);

    const opportunitiesList = (candidatesInfo[candidateUuid] && candidatesInfo[candidateUuid].opportunities) || [];
    const parsed = qs.parse(location.search);
    const { assessment: assessmentSlug } = parsed;

    useEffect(() => {
        setLoading(true);
        getCompany();
        getLinkInfo();
    }, []);

    useEffect(() => {
        if (!opportunitiesList.length) return;
        setInitialIndexes();
    }, [opportunitiesList]);

    const setInitialIndexes = () => {
        let opportunityIndex = 0;
        let columnIndex = 0;
        if (assessmentSlug) {
            for (let i = 0; i < opportunitiesList.length; i += 1) {
                const index = opportunitiesList[i].columns.findIndex(({ audition }) => audition.slug === assessmentSlug);
                if (index !== -1) {
                    columnIndex = index;
                    opportunityIndex = i;
                    break;
                }
            }
        }
        setSelectedIndexes(opportunityIndex, columnIndex);
    };

    const getCompany = () => {
        getPublicCompany()
            .then(({ success, company: companyRsp }) => {
                if (success && companyRsp) {
                    setCompany({ ...companyRsp, features: companyRsp.companyPlan && companyRsp.companyPlan.features });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getGradeQuestion = id => getPublicGradeQuestion(id, gradeableCard);

    const getAuditionQuestionResults = id => getPublicAuditionQuestionResults(id, gradeableCard);

    const getAuditionScreeningQuestionResults = id => getPublicAuditionScreeningQuestionResults(id, gradeableCard);

    const setSelectedIndexes = (opportunityIndex = selectedOpportunityIndex, assessmentIndex = selectedAuditionIndex) => {
        if (!opportunitiesList) return;
        setLoadingAudition(true);
        setSelectedOpportunityIndex(opportunityIndex);
        setSelectedAuditionIndex(assessmentIndex);
        if (opportunitiesList[opportunityIndex].columns[assessmentIndex]) {
            getPublicCandidateAudition(opportunitiesList[opportunityIndex].columns[assessmentIndex].userAudition.uuid, gradeableCard)
                .then(({ success, data }) => {
                    if (success && data) {
                        setUser({ ...user, ...data });
                    }
                })
                .finally(() => {
                    setLoadingAudition(false);
                });
        }
    };

    const getLinkInfo = () => {
        getPublicLinkInfo(linkUuid)
            .then(({ success, data }) => {
                if (success && data && Object.keys(data).length) {
                    const { candidates, abilityGuestScoring, hiddenCandidateDetails } = data;
                    setGradeableCard(abilityGuestScoring);
                    setHideCandidateDetails(hiddenCandidateDetails);
                    if (Object.keys(candidates).length) {
                        const selectedUuidList = Object.keys(candidates);
                        setUser(candidates[selectedUuidList[0]].candidate);
                        setCandidatesInfo(candidates);
                        setCandidateUuid(selectedUuidList[0]);
                    }
                }
            })
            .catch(() => {
                history.push('/not-found');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangePublicUser = (newUuid) => {
        if (newUuid === candidateUuid) return;
        setUser(candidatesInfo[newUuid].candidate);
        setCandidateUuid(newUuid);
    };

    let selectedAudition = {};
    let opportunitySlug = '';
    if (opportunitiesList.length && opportunitiesList[selectedOpportunityIndex].columns[selectedAuditionIndex]) {
        if (opportunitiesList[selectedOpportunityIndex]) {
            const { opportunity, columns } = opportunitiesList[selectedOpportunityIndex];
            opportunitySlug = opportunity ? opportunity.slug : '';
            if (columns[selectedAuditionIndex].audition) {
                selectedAudition = columns[selectedAuditionIndex].audition;
            }
        }
    }

    const countCandidates = Object.keys(candidatesInfo).length;
    const loadCandidatePosts = (opportunitySlug && gradeableCard) ? getGuestCandidatePosts : undefined;

    let content = isLoading ? (
        <div className="u-txt--center u-pdn--tx10 u-pdn--bx10">
            <LoaderBounce />
        </div>
    ) : null;

    const commonProps = {
        user,
        setUser,
        company,
        isLoading,
        opportunitySlug,
        audition: selectedAudition,
        loadCandidatePosts,
        propPublic: true,
        gradeableCard,
        opportunitiesList,
        hideCandidateDetails,
        addCandidateComment: addGuestCandidateComment,
        authorizeGuest: () => setSelectedIndexes(),
        getUserInfo: () => setSelectedIndexes(),
        loadGradeQuestion: getGradeQuestion,
        getAuditionQuestionResults,
        getAuditionScreeningQuestionResults,
        gradeFunction: gradeGuestAnswer
    };

    if (countCandidates === 1) {
        content = (
            <CandidateCardWrapper
                {...commonProps}
                context={`candidate ${gradeableCard ? 'agency' : 'public'} card`}
                ReportHeaderComp={(
                    <AuditionListSelect
                        opportunitiesList={opportunitiesList}
                        selectedOpportunityIndex={selectedOpportunityIndex}
                        selectedAuditionIndex={selectedAuditionIndex}
                        setSelectedIndexes={setSelectedIndexes}
                        isLoadingAudition={isLoadingAudition}
                        showStatus={false}
                    />
                )}
            />
        );
    } else if (countCandidates > 1) {
        content = (
            <MultipleCardsMode
                {...commonProps}
                isLoadingAudition={isLoadingAudition}
                onChangePublicUser={onChangePublicUser}
                selectedCandidateUuid={candidateUuid}
                candidatesInfo={candidatesInfo}
            />
        );
    }

    return (
        <PageWrapper className="u-pdn--bx5">
            <CardCompanyLogo
                company={company}
                hideCandidateDetails={hideCandidateDetails}
                gradeableCard={gradeableCard}
                countCandidates={countCandidates}
                opportunitiesList={opportunitiesList}
            />
            <InnerContentWrapper
                className="u-pdn--x0"
                contentClassName="u-pdn--x0"
            >
                {content}
            </InnerContentWrapper>
            <CardFooter company={company} />
        </PageWrapper>
    );
});

export default withRouter(PublicCard);
