export default theme => ({
    tableCell: {
        cursor: 'default',
        backgroundColor: theme.palette.grey[200],
        color: '#3A3C43',
        fontWeight: 700,
        fontSize: 14,
        '&:first-child': {
            paddingLeft: 67,
            borderTopLeftRadius: 4
        },
        '&:last-child': {
            paddingLeft: 67,
            borderTopRightRadius: 4
        }
    },
    labelWrapperWithSort: {
        cursor: 'pointer'
    },
    arrowWrapper: {
        display: 'inline-block',
        position: 'absolute',
        zIndex: 1,
        right: -23,
        top: -5,
        width: 20,
        height: 28
    },
    sortArrow: {
        position: 'absolute',
        right: 0,
        color: theme.palette.grey[400]
    },
    sortArrowActive: {
        color: theme.palette.blue[500]
    },
    sortArrowUp: {
        top: 1
    },
    sortArrowDown: {
        top: 9
    },
    labelWrapper: {
        position: 'relative'
    },
    labelWrapperActive: {
        color: theme.palette.primary.main
    }
});
