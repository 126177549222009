import { get, post, patch, del, put } from '../helper/API';

export function loadCandidates(page, filter, query, sort) {
    return get('employer/candidates', {
        page,
        filter,
        query,
        sort
    });
}

export function shortlistCandidate(UserAuditionId, value) {
    return patch(`employer/user-auditions/${UserAuditionId}/shortlist`, { shortlisted: value });
}

export function hireCandidates(slug, columnUserIds) {
    return post(`employer/auditions/${slug}/hire`, { ids: columnUserIds });
}

export function loadHiredCandidates(page, query, sort, assessment, hiringManager, sortBy) {
    return get('employer/candidates/hired', {
        page,
        query,
        sort,
        assessment,
        hiringManager,
        sortBy
    });
}

export function loadFiltersCountForAllCandidates() {
    return get('employer/filters-for-all-candidates');
}

export function loadFiltersForHired() {
    return get('employer/candidates/filters-for-hired');
}

export function getHiringManagersList(slug) {
    return get(`employer/auditions/${slug}/hiring-managers`);
}

export function deleteHiringManager(uuid) {
    return del(`employer/hiring-managers/${uuid}`);
}

export function editHiringManager(uuid, email, fullName) {
    return put(`employer/hiring-managers/${uuid}`, { email, fullName });
}

export function addHiringManager(slug, email, fullName) {
    return post(`employer/auditions/${slug}/hiring-managers`, { email, fullName });
}

export function assignHiringManagerOrDate(slug, date, hiringManagerUuid, columnUsers) {
    return post(`employer/auditions/${slug}/hiring-managers/assign`, { date, hiringManager: hiringManagerUuid, columnUsers });
}

export function getPostHiringSurveyData(token) {
    return get('public/hiring-manager-feedback', { token });
}

export function sendPostHiringSurveyData(token, wouldYouHireAgain, rating, comment) {
    return post('public/hiring-manager-feedback', { token, wouldYouHireAgain, rating, comment });
}

export function getHiringManagerFeedback(columnUserUuid) {
    return post(`employer/column-users/${columnUserUuid}/hiring-managers/feedback`);
}

export function removeCandidateFromHired(columnUserUuid) {
    return patch(`employer/column-users/${columnUserUuid}/unhire`);
}

export function changeCandidatePhoneNumber(candidateUuid, phone) {
    return patch(`employer/candidates/${candidateUuid}`, { phone });
}

export function resendCandidatePhoneNumber(columnUserUuid, phone) {
    return patch(`employer/column-users/${columnUserUuid}/send-sms`, { phone });
}
