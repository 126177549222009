import React from 'react';
import { observer } from 'mobx-react-lite';
import { withTheme, withStyles } from '@mui/styles';
import Trophy from 'components/candidate_cards/Trophy';
import TopPerformerLabel from 'components/candidate_cards/TopPerformerLabel';
import Tooltip from 'libraries/Tooltip';
import FeedbackSentIcon from 'img/icons/sent_feedback.svg';
import styles from './styles';

const SLICE_NUMBER = 3;

const CandidateLabels = observer(({ classes, candidate, isOnBoarding }) => {
    const {
        rejected, score: scoreObj, scoreType,
        isTopPerformer, candidateId, isTest, realCandidate, candidateFeedbackStatus
    } = candidate;

    if (rejected) {
        return (
            <>
                <div className="u-dsp--f u-ai--center">
                    <div className={classes.rejectedLabel}>Rejected</div>
                </div>
                {
                    candidateFeedbackStatus === 'SENT' && (
                        <div className="u-mrg--lx2">
                            <Tooltip label="Feedback Sent">
                                <img src={FeedbackSentIcon} alt="+" />
                            </Tooltip>
                        </div>
                    )
                }
            </>
        );
    }


    let scoreByFormat = [];

    if (scoreObj) {
        scoreByFormat = scoreObj.scoreByFormat;
    }

    let groupsWithTrophies;
    let displayedGroupsWithTrophies = [];

    if (scoreByFormat && scoreType === 3) {
        groupsWithTrophies = scoreByFormat.filter(({ rank, scorePercentage }) => rank && rank <= 3 && scorePercentage >= 20);
        displayedGroupsWithTrophies = groupsWithTrophies.slice(0, SLICE_NUMBER);
    }

    return (
        <div className="u-dsp--f u-ai--center">
            {
                isTest && (
                    <span className="c-card-big__header-test">Test</span>
                )
            }
            {
                isTopPerformer && <TopPerformerLabel className="u-mrg--r/2" />
            }
            {
                Boolean(scoreByFormat && scoreType === 3) && (
                    <div
                        data-tip
                        data-for={`groups_${candidateId}`}
                        className="u-dsp--f u-ai--center u-jc--start u-mrg--rx2"
                    >
                        {
                            displayedGroupsWithTrophies.map(({ rank, index, scorePercentage }) => (
                                <Trophy
                                    scorePercentage={scorePercentage}
                                    className="u-mrg--1/2"
                                    key={index}
                                    rank={rank}
                                />
                            ))
                        }
                        {
                            groupsWithTrophies.length > SLICE_NUMBER && (
                                <div className={classes.trophyNumber}>+{groupsWithTrophies.length - SLICE_NUMBER}</div>
                            )
                        }
                    </div>
                )
            }
            {
                realCandidate && isOnBoarding && (
                    <span className={classes.demoScoreLabel}>Demo Score</span>
                )
            }
            {
                candidateFeedbackStatus === 'SENT' && (
                    <Tooltip label="Feedback Sent">
                        <img src={FeedbackSentIcon} alt="+" />
                    </Tooltip>
                )
            }
        </div>
    );
});

export default withStyles(styles)(withTheme(CandidateLabels));
