import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import { getScoreColor } from 'helper/commonFunctions';

import styles from './styles';

const AssessmentStatsItem = ({
    label, value, valueLabel, theme,
    percentage, isPercentageGrey, classes
}) => (
    <div className={classes.wrapper}>
        <div className={classes.value}>
            { value }
            { valueLabel && <span className={classes.valueLabel}>{valueLabel}</span> }
            { Boolean(percentage !== undefined && value) && (
                <span
                    className={classes.percentage}
                    style={{ color: !isPercentageGrey ? getScoreColor(percentage) : theme.palette.grey[400] }}
                >
                    {percentage}%
                </span>
            )}
        </div>
        <div className={classes.label}>{label}</div>
    </div>
);

export default withTheme(withStyles(styles)(AssessmentStatsItem));
