export default theme => ({
    wrapper: {
        backgroundColor: theme.palette.yellow[100],
        borderRadius: 4,
        padding: '6px 12px',
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 22,
        minHeight: 40,
        '& span': {
            fontWeight: 700
        },
        '& *': {
            margin: '0 8px'
        }
    },
    action: {
        fontWeight: 'bold',
        color: theme.palette.blue[500]
    }
});
