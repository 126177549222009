import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    auxiliaryText: {
        color: theme.palette.grey[500],
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 700,
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center'
    },
    statusLabel: {
        display: 'flex',
        fontWeight: 800,
        borderRadius: 40,
        fontSize: 12,
        color: '#ffffff',
        padding: '5px 12px',
        minWidth: 'max-content',
        marginRight: 9,
        '& img': {
            marginRight: 5,
            marginLeft: -2
        }
    },
    statusText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.grey[900],
        '& span': {
            whiteSpace: 'nowrap'
        }
    },
    buttonBase: {
        padding: '2px 5px 5px 5px',
        marginLeft: 15,
        minWidth: 340,
        borderRadius: 6,
        display: 'block',
        textAlign: 'left'
    },
    aiLiteWrapper: {
        minWidth: 200
    },
    questionIcon: {
        width: 22
    },
    insightsButton: {
        fontWeight: 700,
        textDecoration: 'underline'
    }
}));

export default useStyles;
