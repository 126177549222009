import React from 'react';
import { withStyles } from '@mui/styles';
import { FEEDBACK, NO_ANSWER, AUTOGRADED_ANSWER_TYPES } from 'helper/constants';
import GradeScale from 'components/grade_components/GradeScale';
import Tooltip from 'libraries/Tooltip';
import clsx from 'clsx';

const styles = theme => ({
    header: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 20,
        color: theme.palette.grey[900]
    },
    headerDisabled: {
        cursor: 'pointer',
        userSelect: 'none',
        marginTop: 10,
        color: theme.palette.grey[400]
    }
});

const BottomContainer = ({
    classes, saveGrade, activeAssessment,
    currentGrade, question, className = '',
    canGrade, gradeLoading, withHeader = true, ...other
}) => {
    if (!question?.question) return null;
    const { answerType, willBeGraded } = question?.question;

    const hideGradingContainer = AUTOGRADED_ANSWER_TYPES.includes(answerType) && willBeGraded;

    const disabled = !willBeGraded || hideGradingContainer || [FEEDBACK, NO_ANSWER].some(el => el === answerType);

    return (
        <div className={className}>
            { withHeader && (
                disabled ? (
                    <div className="u-mrg--bx3">
                        <Tooltip
                            label={hideGradingContainer
                                ? 'This question is auto graded'
                                : 'This question is marked as do not grade'}
                        >
                            <span
                                className={clsx(classes.header, classes.headerDisabled)}
                            >
                                Grade Answers
                            </span>
                        </Tooltip>
                    </div>
                ) : <h3 className={classes.header}>Grade Answers</h3>
            )}
            <GradeScale
                canGrade={canGrade}
                grade={currentGrade}
                disabled={hideGradingContainer || !activeAssessment}
                saveGrade={saveGrade}
                willBeGraded={willBeGraded}
                gradeLoading={gradeLoading}
                hintSection={question?.aiScoreSection}
                {...other}
            />
        </div>
    );
};

export default withStyles(styles)(BottomContainer);
