export default theme => ({
    errorStyle: {
        color: theme.palette.red.primary
    },
    labelStyle: {
        marginTop: 5,
        fontSize: 12,
        color: theme.palette.grey[700]
    },
    talkToUsLink: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    formControlLabel: {
        fontSize: 14,
        color: theme.palette.grey[900],
        fontWeight: 700
    }
});
