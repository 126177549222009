import { observer } from 'mobx-react-lite';
import React, { useState, useContext } from 'react';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import { regenerateAssessment } from 'requests/AssessmentRequests';
import IconButton from '@mui/material/IconButton';

import { appCtx } from 'components/appStore';
import Tooltip from 'libraries/Tooltip';
import { cloneChildrenWithNewProps, checkHasUserPermission } from 'helper/commonFunctions';
import { resultsCtx } from 'pages/assessment/results_assessment/store';

import RegenerateIcon from 'components/icons/RegenerateIcon';
import AssessmentEvents from 'events/AssessmentEvents';

const styles = {
    progress: {
        color: '#fff'
    },
    submitButton: {
        minWidth: 124,
        paddingLeft: 40,
        paddingRight: 40
    },
    cancelButton: {
        fontWeight: 700,
        paddingLeft: 30,
        paddingRight: 30,
        color: '#000'
    }
};

const RegenerateComponent = observer(({ classes, assessment, onSuccess, labelComp }) => {
    const [pending, setPending] = useState(false);
    const [open, setOpen] = useState(false);
    const { company, flashMessage, isLoadingCompany } = useContext(appCtx);
    const { removeAllCandidateTabsForAssessment } = useContext(resultsCtx);
    if (!assessment) return null;
    const {
        slug, active, permissions, uuid: ttUUID,
        countRealCandidates, name: ttName
    } = assessment;
    const edit = checkHasUserPermission(company, permissions, 'edit');
    const isFreeTrial = company?.plan?.name === 'Free Trial';

    const regenerate = async (e) => {
        e.stopPropagation();
        setPending(true);
        regenerateAssessment(slug)
            .then(() => {
                if (onSuccess) onSuccess();
                removeAllCandidateTabsForAssessment(slug);
                AssessmentEvents.REGENERATED({ ttUUID, ttName, ttSlug: slug });
                flashMessage('Assessment regenerated successfully', 'done');
            })
            .finally(() => {
                setPending(false);
                handleDialog();
            });
    };

    const handleDialog = () => {
        setOpen(!open);
    };

    const disabled = !active || !edit || !countRealCandidates;
    if (isFreeTrial || isLoadingCompany) return null;


    return (
        <div
            role="presentation"
            onClick={e => e.stopPropagation()}
        >
            {
                labelComp ? (
                    cloneChildrenWithNewProps(labelComp, { disabled, onClick: handleDialog })
                ) : (
                    <Tooltip
                        label="Re-generate"
                    >
                        <IconButton onClick={handleDialog} disabled={disabled}>
                            <RegenerateIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleDialog}
                titleComponent="Re-generate Assessment"
                handleClose={handleDialog}
                actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className={classes.cancelButton}
                            onClick={handleDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={regenerate}
                            disabled={pending}
                            className={classes.submitButton}
                        >
                            {
                                pending ? (
                                    <CircularProgress
                                        size={22}
                                        className={classes.progress}
                                    />
                                ) : 'Re-generate'
                            }
                        </Button>
                    </>
                )}
            >
                Regenerating this assessment will archive the current version and create a new one without candidates. You will still have access to view insights and review previous candidates in the selection panel. If candidate feedback is enabled, this will not be turned off.
                <br /><br />
                Please note once the current version is archived, grading of candidates and reopening the assessment will not be possible. This action is irreversible.
            </Dialog>
        </div>
    );
});

export default withStyles(styles)(RegenerateComponent);
