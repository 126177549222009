import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import GeneralEvents from 'events/GeneralEvents';

import CandidatePhoto from 'components/photos/candidate_photo';
import TableCellCustom from 'components/table_components/table_cell';
import { appCtx } from 'components/appStore';

import Tooltip from 'libraries/Tooltip';

import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { BATCH_ACTIONS, PLAGIARISM_DETECTION, SCORE_TYPE_AI } from 'helper/constants';

import { candidateResultCtx } from 'pages/assessment/results_assessment/common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import { resultsCtx } from 'pages/assessment/results_assessment/store';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import FraudFlag from 'img/icons/fraudFlag.svg';
import CheatFlag from 'img/icons/cheatFlag.svg';

import CandidateActions from '../../../../../common/CandidateActions';
import HiredLabel from '../../../../../common/HiredLabel';

import Score from './Score';
import RankCell from './RankCell';
import GroupScore from './GroupScore';
import CandidateLabels from './CandidateLabels';

import useStyles from './styles';
import { statsCtx } from '../../../../../../statsStore';


const CandidateRow = observer(({ match, candidate, history, companyTags }) => {
    const [shownBatch, setShownBatch] = useState(false);
    const { tableRow, statusDate, rankCell, tableCell } = useStyles();

    const {
        score, candidateId, candidateUuid,
        fullName, completionsPerOpportunityLimitExceeded,
        scoreType, tags, rank, rankDifference, completedAgoActualDate, completed,
        employerScore, candidateStatus, rejected, fraudDetected, plagiarismDetected
    } = candidate;

    const [candidateTags, setCandidateTags] = useState(tags);

    useEffect(() => {
        setCandidateTags(candidateTags);
    }, [tags]);

    const {
        selectedCandidates, toggleSelectedUserAuditionIds,
        isEmployerSortEnabled, loadCandidates, loadFiltersStats,
        filterTagsCategory, sortLoading
    } = useContext(candidateResultCtx);
    const { audition } = useContext(assessmentSettingsCtx);
    const { addCandidateTab } = useContext(resultsCtx);
    const { loadAssessmentStats, loadExtensionsRequests } = useContext(statsCtx);

    const { company } = useContext(appCtx);

    if (!audition) return null;

    const { permissions, uuid: ttId, name: ttName, slug } = audition;

    const hired = candidateStatus === 'Hired';

    const edit = checkHasUserPermission(company, permissions, 'edit');
    const grade = checkHasUserPermission(company, permissions, 'grade');

    const goToCard = () => {
        if (completionsPerOpportunityLimitExceeded) return;
        GeneralEvents.CANDIDATE_VIEWED(dataForEvents);
        addCandidateTab(candidateId, fullName, slug);
        history.push(`${match.url}/candidate/${candidateId}`);
    };

    const scoreObj = (isEmployerSortEnabled && !rejected) ? employerScore : score;

    const dataForEvents = {
        candidateId: candidateUuid,
        context: 'select candidates',
        ttId,
        ttName,
        score: scoreObj ? scoreObj.score : undefined,
        scoreIsAI: scoreObj ? scoreObj.type === SCORE_TYPE_AI : false,
        scoreType,
        rank,
        candidateHired: hired
    };

    const showBatch = () => {
        if (!edit) return;
        setShownBatch(true);
    };

    const hideBatch = () => setShownBatch(false);

    const onBatchClick = (e) => {
        e.stopPropagation();
        toggleSelectedUserAuditionIds(candidate);
    };

    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);
    const hasCompanyPlagiarismDetection = checkHasCompanyFeature(company, PLAGIARISM_DETECTION);
    const hasGroupsCandidate = scoreType === 3;
    const hasGroupsAudition = audition.scoreType === 3;

    return (
        <>
            <TableRow
                className={tableRow}
                onClick={goToCard}
                onMouseEnter={showBatch}
                onMouseLeave={hideBatch}
                hover={!completionsPerOpportunityLimitExceeded}
            >
                <TableCellCustom
                    align="left"
                    className={clsx(tableCell, 'u-txt--bold u-pdn--lx4', !sortLoading && isEmployerSortEnabled && rankCell)}
                >
                    <RankCell rank={rank} rankDifference={rankDifference} />
                </TableCellCustom>
                <TableCellCustom align="left" className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    <div className="u-dsp--centered u-jc--start">
                        <CandidatePhoto
                            user={candidate}
                            onClick={onBatchClick}
                            checked={selectedCandidates[candidateId]}
                            shownBatch={shownBatch && hasCompanyFeature && !completionsPerOpportunityLimitExceeded}
                            setShownBatch={setShownBatch}
                        />
                        <TruncateWithTooltip text={fullName} />
                        {plagiarismDetected && hasCompanyPlagiarismDetection && completed && (
                            <Tooltip
                                white
                                label={(
                                    <div className="u-dsp--f u-ai--center">
                                        <img className="u-mrg--rx1" src={CheatFlag} alt="fraud" />
                                        Similar answer detected
                                    </div>
                                )}
                            >
                                <img className="u-mrg--lx1" src={CheatFlag} alt="fraud" />
                            </Tooltip>
                        )}
                        {fraudDetected && (
                            <Tooltip
                                white
                                label={(
                                    <div className="u-dsp--f u-ai--center">
                                        <img className="u-mrg--rx1" src={FraudFlag} alt="fraud" />
                                        Multiple locations detected
                                    </div>
                                )}
                            >
                                <img className="u-mrg--lx1" src={FraudFlag} alt="fraud" />
                            </Tooltip>
                        )}
                    </div>
                </TableCellCustom>
                <TableCell className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    <Tooltip
                        label={<Moment format="MMM D, YYYY">{completedAgoActualDate}</Moment>}
                    >
                        <span className={statusDate}>
                            <Moment fromNow>
                                {completedAgoActualDate}
                            </Moment>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCellCustom className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')} align="left">
                    <div className="u-dsp--f u-ai--center">
                        <Score
                            candidateUuid={candidateUuid}
                            candidate={{ ...candidate, score: scoreObj }}
                            canGrade={grade}
                            audition={audition}
                            isEmployerSortEnabled={isEmployerSortEnabled}
                            hired={hired}
                        />
                        {hired && <HiredLabel />}
                        <CandidateLabels
                            candidate={{ ...candidate, score: scoreObj }}
                        />
                    </div>
                </TableCellCustom>
                {
                    hasGroupsAudition && (
                        <>
                            {
                                hasGroupsCandidate ? (
                                    <TableCell className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                                        <GroupScore
                                            candidate={{ ...candidate, score: scoreObj }}
                                        />
                                    </TableCell>
                                ) : <TableCell />
                            }
                        </>
                    )
                }
                <TableCellCustom align="right" className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx2')}>
                    <CandidateActions
                        companyTags={companyTags}
                        candidate={{ ...candidate, score: scoreObj }}
                        dataForEvents={dataForEvents}
                        loadCandidates={loadCandidates}
                        loadFiltersStats={loadFiltersStats}
                        filterTagsCategory={filterTagsCategory}
                        loadAssessmentStats={loadAssessmentStats}
                        loadExtensionsRequests={loadExtensionsRequests}
                    />
                </TableCellCustom>
            </TableRow>
        </>
    );
});

export default withRouter(CandidateRow);
