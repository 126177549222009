import React, { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import { XAxis, YAxis, Bar, BarChart, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import ScoreRangeBarChart from './ScoreRangeBarChart';

import styles from './styles';

const labelStyle = { fontSize: 13, fontWeight: 700, color: '#292A2D' }
const tickStyle = { fontSize: 12, fontWeight: 700, fill: '#C7C8CC', fontFamily: 'Open Sans' }

class VerticalBarChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hoveredItemIndex: null
        };
        this.chartType = new ScoreRangeBarChart(props.value);
    }

    componentWillReceiveProps(nextProps) {
        const { value } = this.props;
        if (nextProps.value !== value) {
            this.chartType.chartValues = nextProps.value;
        }
    }

    onMouseEnter = (e, index) => {
        this.setState({ hoveredItemIndex: index });
    };

    onMouseLeave = () => {
        this.setState({ hoveredItemIndex: null });
    };

    render() {
        const {
            tooltipLabel,
            classes,
            value
        } = this.props;
        const { hoveredItemIndex } = this.state;
        return (
            <div className={classes.chartWrapper}>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={this.chartType.chartValues}
                        margin={{ top: 0, left: 0 }}
                    >
                        <XAxis
                            dataKey="range"
                            label={{ value: 'Score Range', offset: -5, position: 'insideBottom', style: labelStyle }}
                            tickLine={false}
                            axisLine={false}
                            tick={tickStyle}
                            interval={0}
                        />
                        <YAxis
                            padding={{ top: 100 }}
                            label={{ value: 'Candidates', offset: -5, angle: -90, position: 'insideLeft', style: labelStyle }}
                            width={this.chartType.getYAxisWidth}
                            tickMargin={0}
                            tickLine={false}
                            axisLine={false}
                            domain={[0, 'dataMax']}
                            tickSize={this.chartType.getTickSize}
                            tick={tickStyle}
                            tickFormatter={this.chartType.tickFormatter}
                            allowDecimals={false}
                        />
                        <CartesianGrid
                            vertical={false}
                            stroke="#ebf3f0"
                        />
                        <Tooltip
                            cursor={false}
                            allowEscapeViewBox={{ x: true }}
                            separator=""
                            wrapperStyle={{ left: -70 }}
                            position={{ y: 15 }}
                            content={tooltipPayload => this.chartType.showTooltipData(tooltipPayload, tooltipLabel, classes)}
                        />
                        <Bar
                            dataKey="count"
                            maxBarSize={100}
                            strokeWidth="1"
                            background={{ fill: '#F8F8FA' }}
                            animationBegin={300}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                            minPointSize={this.chartType.minPointSize}
                        >
                            {
                                value.map((entry, index) => (
                                    <Cell
                                        key={index}
                                        fill="#1247F6"
                                        fillOpacity={hoveredItemIndex === index ? 0.5 : 1}
                                    />
                                ))
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default withStyles(styles)(VerticalBarChart);
