import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';

import QuestionWarning from 'img/question-warning.svg';
import CheckInCircle from 'img/checkInCircle.svg';
import ChevronRight from 'img/chevronRight.svg';
import Optimizing from 'img/shieldLightning.svg';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import ALLinearProgress from 'components/grade_components/LinearProgress';
import ALProgressBar from 'components/grade_components/ALProgressBar';
import { appCtx } from 'components/appStore';
import { statsCtx } from 'pages/assessment/statsStore';

import {
    AI_HEALTH_STATUS_LOW,
    AI_HEALTH_STATUS_MEDIUM, AI_HEALTH_STATUS_HIGH,
    AI_HEALTH_STATUS_OPTIMIZED
} from 'helper/constants';
import GradingResultsDialog from '../grading_results';

import useStyles from './styles';


const ActiveLearningStatus = ({ match, status, isOnboarding }) => {
    const [dialogOpen, setDialogOpen] = useState(null);
    const [openedDrawerSection, setOpenedDrawerSection] = useState(null);
    const { company } = useContext(appCtx);
    const { statsCount } = useContext(statsCtx);
    const {
        buttonBase, auxiliaryText, statusLabel,
        statusText, questionIcon, insightsButton,
        aiLiteWrapper
    } = useStyles();

    const { completed } = statsCount;

    const { scriptSlug } = match.params;
    const hasAILite = company.companySettings?.aiLite;

    const returnALStatusObj = () => {
        if (hasAILite) {
            if (completed) {
                return ({
                    color: '#0032DD',
                    label: 'Optimized'
                });
            }
            return null;
        }

        if (!(status > 0)) return null;

        switch (status) {
            case AI_HEALTH_STATUS_LOW: return {
                color: '#FF113D',
                label: 'Low',
                text: <>More candidate answers required<br />to predict accurate scores</>,
                tooltipText: 'Add correct answer samples to increase the accuracy of your score!'
            };
            case AI_HEALTH_STATUS_MEDIUM: return {
                color: '#009879',
                label: 'Medium',
                text: 'Candidate answers require grading',
                progressBar: true,
                tooltipText: 'Grade candidate responses and take it to the next level!'
            };
            case AI_HEALTH_STATUS_HIGH: return {
                color: '#009879',
                label: 'High',
                text: 'Candidate answers require grading',
                progressBar: true,
                tooltipText: 'Grade responses and train Vervoe to be more like a member of your team!'
            };
            case AI_HEALTH_STATUS_OPTIMIZED: return {
                color: '#009879',
                label: 'Optimized',
                text: 'You\'ve unlocked a high level of accuracy!',
                progressBar: true,
                hasInsights: true
            };
            case 'optimizing': return {
                color: '#0032DD',
                text: <>
                    <div style={{ fontWeight: 700, marginBottom: 5 }}>
                        <img className="u-mrg--r/2" src={Optimizing} alt="!" />
                        <span style={{ color: '#0032DD' }}>
                            Optimizing.&nbsp;
                        </span>
                        Recalculating based on grading
                    </div>
                    <ALLinearProgress />
                    <span style={{ color: '#A9AAAE', fontSize: 10 }}>
                        Optimizing may take up to 3 hours.
                    </span>
                </>
            };
            case 'optimized': return {
                color: '#0032DD',
                label: 'Optimized',
                text: 'Assessment grading complete',
                hasInsights: true
            };
            default: return null;
        }
    };

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleClickInsights = (e) => {
        e.stopPropagation();
        setDialogOpen(true);
        setOpenedDrawerSection('question-insights');
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setOpenedDrawerSection(null);
    };

    if (!returnALStatusObj()) return null;

    const { color, label, text, progressBar, tooltipText, hasInsights } = returnALStatusObj();


    return <>
        <ButtonBase
            className={clsx(buttonBase, hasAILite && aiLiteWrapper)}
            disabled={isOnboarding || hasAILite}
            onClick={handleClick}
            disableRipple={hasInsights || isOnboarding || hasAILite}
        >
            <div className={auxiliaryText}>
                Assessment Score Accuracy
                {tooltipText && <QuestionTooltipBlack label={tooltipText} iconClassName={questionIcon} />}
            </div>
            <div className="u-dsp--f u-ai--center">
                {label && (
                    <div className={statusLabel} style={{ backgroundColor: color }}>
                        <img
                            src={(status === 'optimized' || hasAILite) ? CheckInCircle : QuestionWarning}
                            alt="!"
                        />
                        {label}
                    </div>
                )}
                <div className={statusText}>
                    <span>{text}</span>
                    {hasInsights && (
                        <ButtonBase
                            className={insightsButton}
                            onClick={handleClickInsights}
                        >
                            Review scoring insights
                        </ButtonBase>
                    )}
                </div>
                {(status !== 'optimized' && !hasAILite) && <img className="u-mrg--lx2" src={ChevronRight} alt=">" />}
            </div>
            {progressBar && <ALProgressBar color={color} status={status} />}
        </ButtonBase>
        {
            !isOnboarding && (
                <GradingResultsDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    interviewSlug={scriptSlug}
                    status={status}
                    openedDrawerSection={openedDrawerSection}
                    setOpenedDrawerSection={setOpenedDrawerSection}
                />
            )
        }
    </>;
};

export default withRouter(ActiveLearningStatus);
