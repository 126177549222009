import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import Tooltip from 'libraries/Tooltip';

import FraudIcon from 'img/icons/fraudFlag.svg';
import QuestionIcon from 'img/icons/questionIcon.svg';

import styles from '../styles';

const FraudDetectedBanner = observer(({ candidate, classes }) => {
    const { fraudDetected, fraudDetectedCities } = candidate;
    const { company: { fraudDetectionPeriodInMinutes } } = useContext(appCtx);

    if (!fraudDetected || !fraudDetectedCities || !fraudDetectedCities.length) return null;

    return (
        <div className={classes.wrapper}>
            <img src={FraudIcon} alt="!" />
            <div>
                Candidate identified in multiple locations during assessment
                <span>
                (
                    { fraudDetectedCities.map((city, index) => {
                        if (index === fraudDetectedCities.length - 1) return <>{city}</>;
                        return <>{`${city} / `}</>;
                    })}
                )
                </span>
            </div>
            <Tooltip label={`Location detection is activated if Vervoe detects a candidate has undertaken an assessment in multiple locations within ${fraudDetectionPeriodInMinutes} minutes.`}>
                <img src={QuestionIcon} alt="?" />
            </Tooltip>
        </div>
    );
});

export default withStyles(styles)(withRouter(FraudDetectedBanner));
