import React from 'react';
import { withStyles } from '@mui/styles';
import parse, { domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import styles from './styles';

const HtmlDescription = ({ htmlDescription, classes }) => {
    const options = {
        replace: (domNode) => {
            if (domNode.name && domNode.name === 'iframe') {
                const originalHtml = `<iframe${domNode.attribs
                    ? ` ${Object.entries(domNode.attribs).map(([key, value]) => `${key}="${value}"`).join(' ')}`
                    : ''}>${domNode.children ? domToReact(domNode.children, options) : ''}</iframe>`;
                return <code>{originalHtml}</code>;
            }
        }
    };

    return (
        <div className={classes.textStyle}>{parse(DOMPurify.sanitize(htmlDescription))}</div>
    );
};

export default withStyles(styles)(HtmlDescription);
