import React, { useRef, useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ANY_FILE, AUDIO, CODE_TEST, IMAGE, VIDEO } from 'helper/constants';
import nl2br from 'react-nl2br';
import Collapse from '@mui/material/Collapse';
import Truncate from 'react-truncate';

import { withStyles } from '@mui/styles';
import AnswerOptionMedia from '../AnswerOptionZiggeo';
import AnswerOptionFileDownload from '../AnswerOptionFileDownload';
import AnswerOptionImage from '../AnswerOptionImage';
import AnswerOptionCodeEditor from '../AnswerOptionCodeEditor';
import NoInteractionTooltip from '../../../NoInteractionTooltip';
import styles from './styles';


const MediaAnswerOption = ({ answer, checkbox, classes, index, brandColor }) => {
    const { rootStyle, radioStyle, headerWrapper, collapse,
        answerWrapper, answerTextContainer, noMediaBox,
        mediaWrapper, showMore, openedCollapse, collapseContainer
    } = classes;

    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);

    const truncate = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            // fix of truncating after first rendering
            if (truncate && truncate.current) {
                truncate.current.onResize();
            }
        }, 0);
    }, [truncated]);

    const handleTruncate = (newTruncated) => {
        if (newTruncated !== truncated) {
            setTruncated(newTruncated);
            setExpanded(!newTruncated);
        }
    };

    const expandAnswerText = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(newExpanded => !newExpanded);
    };

    return (
        <FormControlLabel
            control={(
                <>
                    <span
                        role="contentinfo"
                        data-tip=""
                        data-for={`non_interactive_preview_radio_${index}`}
                        className={radioStyle}
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                        style={{
                            borderRadius: checkbox ? '3px' : '50%',
                            borderColor: brandColor
                        }}
                    />
                    <NoInteractionTooltip id={`non_interactive_preview_radio_${index}`} />
                </>
            )}
            classes={{
                root: rootStyle,
                label: answerWrapper
            }}
            onClick={e => e.preventDefault()}
            label={(
                <>
                    <div className={headerWrapper}>
                        <div
                            className={answerTextContainer}
                        >
                            <Collapse
                                className={collapse}
                                in={expanded || !truncated}
                                collapsedSize={truncated ? '45px' : '0px'}
                                classes={{
                                    root: collapseContainer,
                                    wrapper: openedCollapse
                                }}
                            >
                                {!expanded && (
                                    <Truncate
                                        ref={truncate}
                                        lines={!expanded && 2}
                                        ellipsis={(
                                            <span>...&nbsp;
                                                <div
                                                    onClick={expandAnswerText}
                                                    className={showMore}
                                                >
                                                    (show more)
                                                </div>
                                            </span>
                                        )}
                                        onTruncate={handleTruncate}
                                    >
                                        {answer.title && nl2br(answer.title)}
                                    </Truncate>
                                )}
                                {expanded && (answer.title && nl2br(answer.title))}
                                {expanded && truncated && (
                                    <div
                                        onClick={expandAnswerText}
                                        className={showMore}
                                    >
                                        &nbsp;(show less)
                                    </div>
                                )}
                            </Collapse>
                        </div>
                    </div>
                    <div
                        className={mediaWrapper}
                        onClick={e => e.stopPropagation()}
                    >
                        {{
                            [AUDIO]: <AnswerOptionMedia answer={answer} />,
                            [VIDEO]: <AnswerOptionMedia answer={answer} />,
                            [ANY_FILE]: <AnswerOptionFileDownload answer={answer} />,
                            [IMAGE]: <AnswerOptionImage answer={answer} />,
                            [CODE_TEST]: <AnswerOptionCodeEditor answer={answer} />
                        }[answer.type] || <div className={noMediaBox}>No media</div>}
                    </div>
                </>
            )}
        />
    );
};

export default withStyles(styles)(MediaAnswerOption);
