import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { loadCandidateAssessmentForSharing } from 'requests/CandidateLinksRequests';
import { appCtx } from 'components/appStore';
import { CANDIDATE_SHARING } from 'helper/constants';
import ShareCandidateDialog from 'components/dialogs/ShareCandidateDialog';
import ShareIcon from 'img/candidateCard/share.svg';

import styles from './styles';

const ReportCardHeader = observer(({
    candidate, audition, opportunitySlug,
    classes, handleCloseCard, ReportHeaderComp,
    propPublic
}) => {
    const [openedDialog, setOpenedDialog] = useState(false);
    const [loadingAssessments, setLoadingAssessments] = useState(false);
    const [assessmentList, setAssessmentList] = useState([]);

    const { flashMessage, company } = useContext(appCtx);

    const { candidateId, candidateUuid } = candidate;
    const { permissions, slug } = audition;

    useEffect(() => {
        loadAssessmentsList();
    }, [candidateId]);

    const loadAssessmentsList = () => {
        if (!candidateId || propPublic) return;
        setLoadingAssessments(true);
        loadCandidateAssessmentForSharing(candidateId)
            .then(({ data, success }) => {
                if (data && success) {
                    setAssessmentList(data);
                }
            })
            .finally(() => {
                setLoadingAssessments(false);
            });
    };

    const handleDialog = () => {
        setOpenedDialog(!openedDialog);
    };

    const scoreShare = checkHasUserPermission(company, permissions, 'agencyShare');

    return (
        <div className={classes.wrapper}>
            {
                ReportHeaderComp || (
                    <Typography variant="h4">
                        Report Card
                    </Typography>
                )
            }
            <div className={classes.buttonWrapper}>
                {
                    handleCloseCard && (
                        <Button
                            className="u-mrg--rx2 u-txt--13 u-txt--semibold"
                            color="primary"
                            onClick={handleCloseCard}
                        >
                            Close Report Card
                        </Button>
                    )
                }
                {
                    !propPublic && checkHasCompanyFeature(company, CANDIDATE_SHARING) && Boolean(assessmentList.length) && (
                        <Button
                            className="u-pdn--rx3 u-pdn--lx3"
                            variant="outlined"
                            onClick={handleDialog}
                        >
                            <img src={ShareIcon} className="u-mrg--rx1" alt=" " />
                            Share Candidate
                        </Button>
                    )
                }
            </div>
            <ShareCandidateDialog
                open={openedDialog}
                opportunitySlug={opportunitySlug}
                onClose={handleDialog}
                disabledScoring={!scoreShare}
                company={company}
                flashMessage={flashMessage}
                userIds={[candidateId]}
                user={candidate}
                userUuid={candidateUuid}
                assessmentSlug={slug}
                assessment={audition}
            />
        </div>
    );
});

export default withStyles(styles)(withRouter(ReportCardHeader));
