import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import EmployerCardContent from 'components/candidate_cards/EmployerCardContent';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { getCandidateOpportunitiesList } from 'requests/JobOpportunityRequests';
import { candidatesCtx } from '../store';
import { candidateCtx } from '../AllCandidates/candidateStore';

const EmployerCard = observer(({ match, userId: userIdProp }) => {
    const { company } = useContext(appCtx);
    const { candidateTabs, addCandidateTab } = useContext(candidatesCtx);
    const { fetchAllCandidatesStats } = useContext(candidateCtx);
    const [opportunitiesList, setOpportunitiesList] = useState([]);

    const { user_id } = match.params;
    const userId = user_id || userIdProp;

    const getOpportunitiesList = candidateUuid => getCandidateOpportunitiesList(candidateUuid)
        .then(({ success, data }) => {
            if (success && data) {
                setOpportunitiesList(data);
            }
        });

    const onLoadCandidate = (candidate) => {
        const { id, fullName } = candidate;
        const index = candidateTabs.findIndex(({ id: candidateId }) => candidateId === id);
        if (index === -1) addCandidateTab(id, fullName);
        return getOpportunitiesList(candidate.uuid);
    };

    return (
        <EmployerCardContent
            userId={userId}
            company={company}
            onLoadCandidate={onLoadCandidate}
            opportunitiesList={opportunitiesList}
            context="all candidates page card"
            onHireChanges={fetchAllCandidatesStats}
        />
    );
});

export default withRouter(EmployerCard);
