import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import { validateEmail } from 'helper/commonFunctions';
import TextInput from 'libraries/TextInput';
import Loader from 'components/loaders/Loader';
import { appCtx } from 'components/appStore';
import { loginUser } from 'requests/LoginRequests';
import styles from 'pages/home/common/styles';
import IntegrationsAuthWrapper from '../IntegrationsAuthWrapper';

const baseURL = process.env.REACT_APP_BASE_URL;


const SmartRecruitersLogin = observer(({ classes, location }) => {
    const { flashMessage } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const [errorForm, setErrorForm] = useState({});

    const { companyId, redirect } = qs.parse(location.search);

    if (!companyId || companyId === 'undefined') return <Redirect to="/not-found" />;

    const login = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        loginUser(JSON.stringify(form))
            .then(({ companySlug, refreshToken }) => {
                const { protocol, port } = window.location;
                const baseLocationRedirect = `${protocol}//${companySlug}.${baseURL}${port ? `:${port}` : ''}`;
                window.location = `${baseLocationRedirect}/auth-smartrecruiters?companyIdSR=${companyId}&redirectSR=${redirect}&token=${refreshToken}`;
            })
            .catch((err) => {
                setLoading(false);
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            });
    };

    const renderError = (formParam) => {
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    const renderValue = (value) => {
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    const validateForm = () => {
        const { email, password } = form;
        const newErrorForm = {};
        if (!email) {
            newErrorForm.email = 'Please enter your email.';
        } else if (!validateEmail(email)) {
            newErrorForm.email = 'Please enter a valid email address.';
        }
        if (!password) {
            newErrorForm.password = 'Please enter your password.';
        }
        if (Object.keys(newErrorForm).length) {
            setErrorForm(newErrorForm);
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const updateForm = { ...form };
        const updateErrorForm = { ...errorForm };
        updateForm[targetName] = targetValue;
        updateErrorForm[targetName] = '';
        setForm(updateForm);
        setErrorForm(updateErrorForm);
    };

    return (
        <IntegrationsAuthWrapper>
            <h1 className={classes.heading}>Sign in</h1>
            <form onSubmit={login} id="loginForm">
                <TextInput
                    variant="outlined"
                    label="Enter your email"
                    isError={Boolean(renderError('email'))}
                    helperText={renderError('email')}
                    value={renderValue('email')}
                    fullWidth
                    type="text"
                    name="email"
                    onChange={handleChange}
                />
                <TextInput
                    variant="outlined"
                    label="Enter your password"
                    isError={Boolean(renderError('password'))}
                    helperText={renderError('password')}
                    value={renderValue('password')}
                    fullWidth
                    type="password"
                    name="password"
                    onChange={handleChange}
                />
                <div style={{ position: 'relative' }}>
                    <Button
                        className={classes.signInButton}
                        variant="contained"
                        color="primary"
                        fullWidth
                        id="btn_login"
                        type="submit"
                        disabled={loading}
                        onClick={() => {}}
                    >
                    Sign in to Vervoe
                    </Button>
                    {loading && (
                        <Loader
                            className={classes.loader}
                            size={24}
                            show
                            thickness={2}
                        />
                    )}
                </div>
            </form>
        </IntegrationsAuthWrapper>
    );
});

export default withRouter(withStyles(styles)(SmartRecruitersLogin));
