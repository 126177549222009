import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        marginTop: 16,
        marginBottom: 23,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: '#FFFFFF',
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        fontSize: 12,
        width: 'fit-content'
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[500],
        fontWeight: 700,
        textTransform: 'uppercase',
        padding: '0 32px',
        height: 50,
        borderRight: `2px solid ${theme.palette.grey[300]}`,
        '& img': {
            marginRight: 10
        }
    },
    itemNumber: {
        fontSize: 16,
        color: theme.palette.grey[900]
    },
    itemClickable: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    itemDisable: {
        cursor: 'default',
        backgroundColor: theme.palette.grey[100],
        '& img': {
            filter: 'grayscale(1)',
            opacity: 0.5
        }
    },
    button: {
        fontSize: 12,
        marginLeft: 10,
        marginRight: 10,
        fontWeight: 700
    }
}));
