export default theme => ({
    questionHeader: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.grey[900],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 15,
        marginBottom: 24,
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    iconButton: {
        width: 40,
        height: 40,
        padding: 0
    }
});
