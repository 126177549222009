/* eslint-disable prefer-template */
import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import SnackbarProgress from 'libraries/SnackbarProgress';
import { checkHasCompanyFeature, checkPluralNew } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import { SEND_SMS_INVITATION, VALIDATE_IS_EMAIL } from 'helper/constants';
import SmsCreditWarningDialog from 'components/dialogs/SmsCreditWarningDialog';

import InviteFormFields from './InviteFormFields';
import InvitingProgressDialog from './InvitingProgressDialog';

let flashLabel = '';

const InviteForm = observer(({
    context, slug, isQuick = false,
    hideAddMoreRowsButton, id = 'formInvite',
    defaultRowCount, onErrorInviteForm, assessment
}) => {
    const { company } = useContext(appCtx);

    const {
        smsWarningDialogOpen, setSmsWarningDialogOpen,
        inviteViaEmailForm, currentStepState,
        stepsNeededState, error,
        isCanceled, setErrorMessage, setDefaultFormInvite,
        loading, onCancel, defaultFormInvite,
        onValueChange, setRowCount, setDefaultRowCount,
        invitationArray, setSlug, cleanForm
    } = useContext(context);

    const hasSMSFeature = checkHasCompanyFeature(company, SEND_SMS_INVITATION);

    useEffect(() => {
        setSlug(slug);
        if (defaultRowCount) {
            setRowCount(defaultRowCount);
            setDefaultRowCount(defaultRowCount);
        }
        const newDefaultFormInvite = {
            name: {
                value: '',
                validator: []
            },
            email: {
                value: '',
                validator: [VALIDATE_IS_EMAIL]
            }
        };
        if (hasSMSFeature) {
            newDefaultFormInvite.phone = { value: '' };
            newDefaultFormInvite.isPhoneValid = { value: true };
        }
        setDefaultFormInvite(newDefaultFormInvite);
        return (() => {
            cleanForm();
        });
    }, []);

    useEffect(() => () => {
        if (onCancel) onCancel();
    }, []);

    const candidatesWithPhonesToInviteCount = invitationArray.filter(({ phone }) => phone).length;
    const candidatesCount = Object.keys(inviteViaEmailForm).length;

    if (candidatesCount) {
        const candidateFormatted = `${candidatesCount} ${checkPluralNew(candidatesCount, 'candidate')}`;
        flashLabel = `Inviting ${candidateFormatted}`;
    }

    if (!defaultFormInvite) return null;

    const progressInvite = Math.ceil(currentStepState / stepsNeededState * 100);

    return (
        <>
            {
                (stepsNeededState > 1 && !error) ? (
                    <InvitingProgressDialog
                        open={loading}
                        error={error}
                        onCancel={onCancel}
                        canceled={isCanceled}
                        percents={progressInvite}
                    />
                ) : (
                    <SnackbarProgress
                        open={loading}
                        showSuccessIcon
                        onCancel={onCancel}
                        message={flashLabel}
                        percents={progressInvite}
                        error={error}
                        canceled={isCanceled}
                        errorLabel={error}
                    />
                )
            }
            <InviteFormFields
                id={id}
                company={company}
                loading={loading}
                setErrorMessage={setErrorMessage}
                onValueChange={onValueChange}
                hidePhoneField={isQuick}
                hideAddMoreRowsButton={hideAddMoreRowsButton}
                context={context}
                onErrorInviteForm={onErrorInviteForm}
                expired={assessment?.expired || !assessment?.valid}
            />
            <SmsCreditWarningDialog
                open={smsWarningDialogOpen}
                onClose={() => setSmsWarningDialogOpen(false)}
                countSmsLeft={company.countSmsLeft}
                context={context}
                assessment={assessment}
                isQuick={isQuick}
                candidatesWithPhonesToInviteCount={candidatesWithPhonesToInviteCount}
            />
        </>
    );
});

export default withRouter(InviteForm);
