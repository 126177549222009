import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ReorderDialog from 'components/dialogs/ReorderDialog';
import ReorderIcon from 'components/icons/ReorderIcon';
import TrashIcon from 'components/icons/TrashIcon';

import DeleteQuestionDialog from '../../../DeleteQuestionDialog';
import { assessmentEditCtx } from '../../../../store';

const styles = {
    actionsWrapper: {
        position: 'absolute',
        fontSize: 18,
        top: 16,
        right: 10,
        paddingLeft: 75,
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%)',
        display: 'flex',
        alignItems: 'center'
    },
    iconButton: {
        padding: 3
    }
};

const HeaderActions = ({ isActive, question, classes, questionIndex, className, isScreening }) => {
    const { template, screeningQuestion } = question;
    const [reorderDialogOpened, setReorderDialog] = useState(false);
    const [deleteQuestionDialogOpen, setDeleteQuestionDialog] = useState(false);
    const {
        questions, reorderQuestions, deleteQuestion,
        setActiveQuestionBySkill, selectedSkill
    } = useContext(assessmentEditCtx);

    const removeQuestion = async (key) => {
        await deleteQuestion(key, isScreening);
        setActiveQuestionBySkill(selectedSkill);
    };

    if (!isActive) return null;

    const handleReorderDialog = (e) => {
        e.stopPropagation();
        setReorderDialog(!reorderDialogOpened);
    };

    const handleDeleteQuestion = () => {
        if (template) {
            removeQuestion(questionIndex);
        } else {
            setDeleteQuestionDialog(!deleteQuestionDialogOpen);
        }
    };

    const onReorder = (e, targetQuestionIndex, newIndex, position) => {
        reorderQuestions(question.id, targetQuestionIndex, questionIndex, newIndex, position, isScreening)
            .then(() => {
                handleReorderDialog(e);
            });
    };

    return (
        <div className={clsx(classes.actionsWrapper, className)} onClick={e => e.stopPropagation()} role="presentation">
            {
                questions.length > 1 && (
                    <IconButton
                        className={clsx(classes.iconButton, 'u-mrg--rx1')}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteQuestion(e);
                        }}
                        size="large"
                    >
                        <TrashIcon size={24} color="#C7C8CC" />
                    </IconButton>
                )
            }
            <IconButton onClick={handleReorderDialog} className={classes.iconButton} size="large">
                <ReorderIcon />
            </IconButton>
            <ReorderDialog
                open={reorderDialogOpened}
                onReorder={onReorder}
                entityList={questions}
                entityIndex={questionIndex}
                onClose={handleReorderDialog}
                labelParameter={screeningQuestion ? 'text' : 'description'}
            />
            <DeleteQuestionDialog
                open={deleteQuestionDialogOpen}
                deleteQuestion={removeQuestion}
                questionIndex={questionIndex}
                onClose={() => { setDeleteQuestionDialog(false); }}
            />
        </div>
    );
};

export default withStyles(styles)(HeaderActions);
