import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Recorder from 'components/ziggeo/recorder';
import Player from 'components/ziggeo/player';

import styles from './styles';

class VideoPreview extends Component {
    state = {
        record: false,
        showMedia: false,
        recordStarted: false
    };

    handleClick = () => {
        const { record } = this.state;
        this.setState({ record: !record });
    };

    recordingStarted = () => {
        const { handleLockSave } = this.props;
        this.setState({ recordStarted: true });
        if (handleLockSave) handleLockSave(true);
    };

    recordingStoped = () => {
        const { handleLockSave } = this.props;
        this.setState({ recordStarted: false });
        if (handleLockSave) handleLockSave(false);
    };

    recordingProcessedToogle = () => {
        this.handleClick();
    };

    showVideoMedia = () => {
        this.handleClick();
        this.setState({ showMedia: true });
    };

    removeVideoDescription = (e) => {
        const { removeVideoDescription } = this.props;
        this.setState({ record: false });
        removeVideoDescription(e);
    };

    render() {
        const { ziggeoToken, recordingPublished, classes } = this.props;
        const { record, recordStarted, showMedia } = this.state;

        return (
            <div className={classes.noVideoWrapper}>
                {
                    (!ziggeoToken && !showMedia) && (
                        <div className={classes.wrapper}>
                            <div className={classes.message}>
                                <p className={classes.introHeader}>
                                    Add a video introduction
                                </p>
                                <p className={classes.introSubheader}>
                                    INCREASE ASSESSMENT COMPLETIONS BY 80%
                                </p>
                                <p>
                                    Adding a video intro gives your candidates a better sense of the role, increasing completion rates.  The video will be added to your job post and seen before the assessment begins.
                                </p>
                                <Button
                                    className={classes.recordButton}
                                    onClick={this.showVideoMedia}
                                    variant="outlined"
                                >
                                    Record Video
                                </Button>
                            </div>
                            <iframe
                                title="edit_video_preview"
                                width="457"
                                height="257"
                                className={classes.videoPreviewWrapper}
                                src="https://www.youtube.com/embed/b2o12jLk4cE"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    )
                }
                <div className={`o-grid ${classes.mediaWrapper} ${(ziggeoToken || showMedia) ? classes.mediaWrapperShow : ''}`}>
                    <div className={classes.videoWrapper}>
                        {
                            record && (
                                <Recorder
                                    recordingStarted={this.recordingStarted}
                                    recordingStoped={this.recordingStoped}
                                    recordingProcessed={this.recordingProcessedToogle}
                                    recordingPublished={recordingPublished}
                                    video
                                />
                            )
                        }
                    </div>

                    {
                        !recordStarted && record && ziggeoToken
                        && (
                            <div className="o-grid__1/4">
                                <Button
                                    className="u-mrg--tx3"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleClick}
                                >
                                    Review video
                                </Button>
                            </div>
                        )
                    }

                    <ToggleDisplay show={!record}>
                        <div className={classes.videoWrapper}>
                            {
                                ziggeoToken
                                && (
                                    <Player
                                        ziggeo_token={ziggeoToken}
                                    />
                                )
                            }
                        </div>
                        <div className={classes.buttonWrapper}>
                            {
                                !record && (
                                    <Button
                                        className={classes.reRecordButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleClick}
                                    >
                                        Re-record
                                    </Button>
                                )
                            }
                            {
                                ziggeoToken
                                && (
                                    <Button
                                        className={classes.removeButton}
                                        color="primary"
                                        onClick={this.removeVideoDescription}
                                    >
                                        Remove
                                    </Button>
                                )
                            }
                        </div>
                    </ToggleDisplay>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(VideoPreview);
