import React from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import { isNumeric } from 'helper/commonFunctions';
import colors from 'components/colors';

import styles from './styles';

export function getScoreColor(score, totalCount) {
    if (score === null || score === false || !isNumeric(+score) || !totalCount) return '';
    if (score >= 4) {
        return colors.green[600];
    } if (score >= 3) {
        return colors.yellow[800];
    }
    return colors.red[600];
}


const RatingItem = ({
    label, value, totalCount,
    valueLabel, classes, onClick
}) => (
    <div className={clsx(classes.wrapper, totalCount && 'u-cursor--p')} onClick={totalCount ? onClick : undefined}>
        <div className={classes.value}>
            <span style={{ color: getScoreColor(value, totalCount) }}>{ (!totalCount || (!value && value !== 0)) ? '-' : value }</span>
            { valueLabel && <span className={classes.valueLabel}>{valueLabel}</span> }
            <span
                role="presentation"
                className={classes.countLabel}
            >
                ({totalCount})
            </span>
        </div>
        <div className={classes.label}>{label}</div>
    </div>
);

export default withTheme(withStyles(styles)(RatingItem));
