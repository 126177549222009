export default theme => ({
    wrapper: {
        height: 347,
        width: 320,
        fontWeight: 'normal',
        overflow: 'hidden',
        boxShadow: '0px 0px 40px rgba(41, 42, 45, 0.15)',
        '@media only screen and (max-width: 763px)': {
            height: 350
        },
        [theme.breakpoints.down('lg')]: {
            width: 358
        }
    },
    headerWrapper: {
        position: 'relative',
        padding: '53px 24px 0px'
    },
    boardMenu: {
        position: 'absolute',
        right: 24,
        top: 14,
        borderRadius: 4
    },
    title: {
        boxSizing: 'content-box',
        maxHeight: 48,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.4',
        letterSpacing: 'normal',
        color: theme.palette.grey[900],
        whiteSpace: 'pre-wrap'
    },
    header: {
        height: 86,
        paddingBottom: 16
    },
    infoRow: {
        paddingLeft: 24,
        paddingRight: 24,
        borderTop: `1px solid ${theme.palette.grey[300]}`
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 24px 24px',
        fontWeight: 600,
        fontSize: 12,
        '@media only screen and (max-width: 763px)': {
            flexDirection: 'column'
        }
    },
    previewButton: {
        padding: '6px 30px',
        fontWeight: 700,
        '@media only screen and (max-width: 763px)': {
            width: '100%'
        }
    },
    contentWrapper: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        padding: '16px 24px'
    },
    descriptionWrapper: {
        boxSizing: 'content-box',
        height: 60,
        fontSize: 13,
        marginBottom: 16,
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '20px',
        letterSpacing: 'normal',
        color: theme.palette.grey[900]
    },
    authorWrapper: {
        marginTop: 5,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[500]
    },
    companyName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: 120,
        fontWeight: 700
    },
    companyNameExpanded: {
        maxWidth: 'calc(100% - 22px)'
    },
    skillsWrapper: {
        height: 22,
        whiteSpace: 'nowrap'
    },
    categoryType: {
        fontWeight: 700,
        whiteSpace: 'nowrap',
        '&:hover': {
            color: theme.palette.grey[900]
        }
    }
});
