import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';

const stylesJS = theme => ({
    list: {
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
    },
    wrapper: {
        maxWidth: '100%',
        minHeight: 22,
        color: theme.palette.grey[900]
    },
    skillItem: {
        display: 'inline-block',
        marginBottom: 7,
        background: theme.palette.grey[300],
        borderRadius: '30px',
        fontWeight: 700,
        padding: '4px 12px',
        fontSize: 10,
        marginRight: 5,
        letterSpacing: '0.2px',
        color: theme.palette.grey[900]
    },
    highlightedSkillItem: {
        background: theme.palette.green[300]
    }
});


const SkillsGroup = ({ classes, items = [], bigCard, scoreType, className, skillItemClassName = '' }) => {
    const [width, setWidth] = useState(null);
    const searchTerm = '';
    const checkIfIncludes = title => searchTerm && title && title.toLowerCase().includes(searchTerm.toLowerCase());
    let sortedItems = [...items].sort((a, b) => a.title.length - b.title.length);
    if (searchTerm) {
        sortedItems = sortedItems.sort(a => (checkIfIncludes(a.title) ? -1 : 1));
    }

    const elementRef = useRef(null);

    let numberOfDisplayedSkills = 0;

    useEffect(() => {
        window.addEventListener('resize', countBlockWidth);
        return () => {
            window.removeEventListener('resize', countBlockWidth);
        };
    }, []);

    useEffect(() => {
        const newWidth = elementRef.current ? elementRef.current.offsetWidth : 0;
        setWidth(newWidth);
    }, [elementRef.current]);

    useEffect(() => {
        numberOfDisplayedSkills = countNumberOfDisplayedSkills();
    }, [width]);

    const countBlockWidth = () => {
        if (elementRef.current) {
            setWidth(elementRef.current.offsetWidth);
        }
    };


    const widthWithoutCounter = width - 50;

    const countNumberOfDisplayedSkills = () => {
        if (!width || bigCard) return sortedItems.length;
        let count = 0;
        let countWidth = 0;


        for (let i = 0; i < sortedItems.length; i += 1) {
            const lettersWidth = 6.2 * sortedItems[i].title.length;
            countWidth += Math.min(widthWithoutCounter, lettersWidth) + 5 + 24; // + margin + padding
            const isLastElement = i === sortedItems.length - 1;
            const comparedWidth = isLastElement ? width : widthWithoutCounter;
            if (comparedWidth - countWidth < 0) { // break if it is a last element and it fits
                break;
            }
            count += 1;
        }

        return count || 1;
    };

    numberOfDisplayedSkills = countNumberOfDisplayedSkills();

    return (
        <>
            {
                sortedItems.length && scoreType && scoreType === 3 ? (
                    <div ref={elementRef} className={clsx(classes.wrapper, className)}>
                        {
                            sortedItems
                                .slice(0, numberOfDisplayedSkills)
                                .map(({ title }, index) => (
                                    <div
                                        key={`${title}_${index}`}
                                        className={clsx(classes.skillItem,
                                            skillItemClassName,
                                            checkIfIncludes(title) && classes.highlightedSkillItem)}
                                    >
                                        <TruncateWithTooltip text={title} width={widthWithoutCounter} />
                                    </div>
                                ))
                        }
                        {
                            !bigCard && (items.length - numberOfDisplayedSkills) > 0 && (
                                <span className="u-txt--10 u-txt--bold u-mrg--lx1 u-mrg--bx1 u-dsp--ib">
                                    +{items.length - numberOfDisplayedSkills}
                                </span>
                            )
                        }
                    </div>
                ) : <div className={clsx(classes.wrapper, className)} />
            }
        </>
    );
};

export default withStyles(stylesJS)(withRouter(SkillsGroup));
