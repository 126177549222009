import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { getImageUrl, getCategoryTitle } from 'helper/assessmentFunctions';

import stylesJS from './styles';

const CategoryIcon = observer(({ category, classes, className }) => (
    category ? (
        <div
            className={clsx(classes.popularCategoryTitle, className)}
        >
            <img src={getImageUrl(category.title)} alt={getCategoryTitle(category.title)} />
            {getCategoryTitle(category.title)}
        </div>
    ) : null
));

export default withStyles(stylesJS)(CategoryIcon);
