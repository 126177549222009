import React from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItemCustom from './Item';

import styles from './styles';


const Questions = observer(({ classes, selectedQuestionIndex, questionList, setSelectedQuestionIndex }) => (
    <div className={classes.wrapper}>
        <div className={classes.header}>Questions</div>
        <List
            component="nav"
            className={classes.questionList}
            aria-label="secondary mailbox folders"
        >
            {
                questionList.map((item, index) => (
                    <ListItemCustom
                        key={index}
                        question={item}
                        selectedQuestionIndex={selectedQuestionIndex}
                        setSelectedQuestionIndex={setSelectedQuestionIndex}
                        questionNumber={index + 1}
                    />
                ))
            }
        </List>
    </div>
));

export default withStyles(styles)(Questions);
