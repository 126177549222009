import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { checkHasUserPermission, clone } from 'helper/commonFunctions';
import { unTagCandidate } from 'requests/AssessmentRequests';
import GeneralEvents from 'events/GeneralEvents';
import { SCORE_TYPE_AI } from 'helper/constants';

import { appCtx } from 'components/appStore';
import TagButton from 'components/candidate_cards/CandidateActions/TagButton';
import TagsContainer from 'components/candidate_cards/TagsContainer';

import styles from './styles';

const TagsInfo = observer(({
    candidate, classes, audition, loadFiltersStats,
    getTags, companyTags, setTags, propPublic, context,
    isIntegration
}) => {
    const [candidateTags, setCandidateTags] = useState([]);
    const { company } = useContext(appCtx);

    const {
        tags, score, candidateUuid,
        scoreType, columnUserId, rank, candidateStatus
    } = candidate;

    const candidateHired = candidateStatus === 'Hired';

    const { permissions } = audition;

    const canTagCandidates = checkHasUserPermission(company, permissions, 'tagCandidates');


    const { uuid: ttId, name: ttName } = audition;

    useEffect(() => {
        setCandidateTags(tags);
    }, [tags]);

    const addTag = (tag) => {
        const newCandidateTags = clone(candidateTags);
        newCandidateTags.push(tag);
        setCandidateTags(newCandidateTags);
    };

    const removeTag = (index) => {
        const newCandidateTags = clone(candidateTags);
        newCandidateTags.splice(index, 1);
        setCandidateTags(newCandidateTags);
    };

    const dataForEvents = {
        candidateId: candidateUuid,
        context,
        ttId,
        ttName,
        score: score ? score.score : undefined,
        scoreIsAI: score ? score.type === SCORE_TYPE_AI : undefined,
        scoreType,
        rank: rank || '-',
        candidateHired
    };

    let onRemove = false;
    if (canTagCandidates) {
        onRemove = async ({ id, title }, index) => {
            const eventData = { ...dataForEvents, tagId: id, tagName: title };
            await unTagCandidate(id, columnUserId);
            removeTag(index);
            GeneralEvents.CANDIDATE_UNTAGGED(eventData);
            if (loadFiltersStats) loadFiltersStats();
        };
    }

    return (
        <div className={classes.blockWrapper}>
            <div className="u-dsp--distribute u-mrg--bx2">
                <Typography variant="h5">Tags</Typography>
                {
                    (!propPublic && !isIntegration) && (
                        <TagButton
                            setTags={setTags}
                            dataForEvents={dataForEvents}
                            tags={candidateTags}
                            addTag={addTag}
                            removeTag={removeTag}
                            candidate={candidate}
                            audition={audition}
                            companyTags={companyTags}
                            getTags={getTags}
                        />
                    )
                }
            </div>
            {
                candidateTags && candidateTags.length ? (
                    <TagsContainer
                        tags={candidateTags}
                        onRemove={(propPublic || isIntegration || !audition.active) ? undefined : onRemove}
                    />
                ) : 'The candidate is not tagged.'
            }
        </div>
    );
});

export default withStyles(styles)(withRouter(TagsInfo));
