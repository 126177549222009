import React from 'react';
import { withStyles } from '@mui/styles';
import { AUDIO, TEXT, VIDEO } from 'helper/constants';
import HtmlDescription from 'components/assessments_pages/HtmlDescription';
import { nl2brCustom } from 'helper/commonFunctions';
import nl2br from 'react-nl2br';

const styles = theme => ({
    wrapper: {
        '& > div': {
            marginBottom: 25
        },
        fontSize: 12
    },
    header: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        color: theme.palette.grey[900]
    },
    headerTask: {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: 10,
        textTransform: 'uppercase',
        color: theme.palette.grey[600]
    }
});

const ScoringTips = ({ classes, question, isTask, hideExampleAnswers }) => {
    if (!question?.question) return null;
    const { scoringInstruction, exampleAnswers, answerType } = question.question;
    const hideScoringTips = !scoringInstruction && !(exampleAnswers && exampleAnswers.length && [TEXT, AUDIO, VIDEO].some(el => el === answerType));
    if (hideScoringTips) return null;

    if (isTask && scoringInstruction) {
        return (
            <div className={classes.wrapper}>
                {
                    scoringInstruction && (
                        <div>
                            <h3 className={classes.headerTask}>Scoring Instructions</h3>
                            <HtmlDescription htmlDescription={nl2brCustom(scoringInstruction)} />
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            {
                scoringInstruction && (
                    <div>
                        <h3 className={classes.header}>Team Scoring Instructions</h3>
                        <HtmlDescription htmlDescription={nl2brCustom(scoringInstruction)} />
                    </div>
                )
            }
            {
                Boolean(exampleAnswers && exampleAnswers.length && !hideExampleAnswers && [TEXT, AUDIO, VIDEO].some(el => el === answerType)) && (
                    <div>
                        <h3 className={classes.header}>Examples of a correct answer</h3>
                        {
                            exampleAnswers.map((item, index) => (
                                <div key={index}>#{index + 1}. {nl2br(item)}</div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default withStyles(styles)(ScoringTips);
