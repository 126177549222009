import React from 'react';
import clsx from 'clsx';
import { withStyles, withTheme } from '@mui/styles';
import ListItem from '@mui/material/ListItem';

import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import { AI_HEALTH_STATUS_LOW, AI_HEALTH_STATUS_MEDIUM,
    AI_HEALTH_STATUS_HIGH, AI_HEALTH_STATUS_OPTIMIZED } from 'helper/constants';
import Tooltip from 'libraries/Tooltip';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import Chip from 'components/job_or_assessment_settings/Chip';

import CompletedIcon from './assets/completed.svg';
import NoResponsesIcon from './assets/no-responses.svg';

import styles from './styles';

const ListItemCustom = withTheme(withStyles(styles)(({
    classes, setSelectedQuestionIndex, theme,
    questionNumber, question, selectedQuestionIndex, ...other
}) => {
    const { answerType, questionGroup, suggestedAnswerAdded, status, questionOrder } = question;
    const selected = selectedQuestionIndex === questionNumber - 1;

    const returnTypeImg = () => {
        if (!answerType) return null;
        return getQuestionTypeInfo(answerType, renderIconAndLabel);
    };

    const renderIconAndLabel = (Icon, label, labelShort = label) => (
        <Tooltip
            label={labelShort}
            tooltipClass={classes.tooltipClass}
        >
            {
                Icon && (
                    <span>
                        <Icon color={selected ? '#ffffff' : '#292A2D'} className={classes.svgIconStyle} />
                    </span>
                )
            }
        </Tooltip>
    );

    const returnQuestionStatusChipObj = () => {
        switch (status) {
            case AI_HEALTH_STATUS_LOW:
                return {
                    text: 'Low',
                    color: theme.palette.red[600],
                    backgroundColor: theme.palette.red[50]
                };
            case AI_HEALTH_STATUS_MEDIUM:
                return {
                    text: 'Medium',
                    color: theme.palette.green[900],
                    backgroundColor: theme.palette.green[50]
                };
            case AI_HEALTH_STATUS_HIGH:
            case AI_HEALTH_STATUS_OPTIMIZED:
                return {
                    text: status === AI_HEALTH_STATUS_HIGH ? 'High' : 'Optimized',
                    color: theme.palette.green[900],
                    backgroundColor: theme.palette.green[50]
                };
            default: return null;
        }
    };

    return (
        <ListItem
            button
            onClick={() => setSelectedQuestionIndex(questionNumber - 1)}
            className={clsx(classes.wrapper, selected && classes.wrapperActive)}
            {...other}
        >
            <div className={classes.header}>
                <div className={clsx(classes.questionLabel, selected && classes.questionLabelActive)}>
                    Q{questionOrder} {returnTypeImg()}
                </div>
                <TruncateWithTooltip className={classes.skillGroup} text={questionGroup?.title || '-'} />
            </div>
            <div className={classes.lowerContainer}>
                <div className={classes.suggestedAnswersLabel}>
                    Suggested Answers
                    <img src={suggestedAnswerAdded ? CompletedIcon : NoResponsesIcon} className="u-mrg--lx1" alt="!" />
                </div>
                {status <= 4 && status >= 1 && <Chip {...returnQuestionStatusChipObj()} className={classes.questionStatusChip} />}
            </div>
        </ListItem>
    );
}));

export default ListItemCustom;
