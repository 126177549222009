import React from 'react';
import Player from 'components/ziggeo/player';
import { withStyles } from '@mui/styles';
import styles from './styles';

const AnswerOptionMedia = ({ answer: { ziggeo, type }, classes }) => {
    const { wrapper } = classes;
    if (!ziggeo || !ziggeo.token) return null;

    const handleClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div role="contentinfo" className={wrapper} onClick={handleClick}>
            <Player
                small
                audio={type === 3}
                ziggeo_token={ziggeo.token}
            />
        </div>
    );
};

export default withStyles(styles)(AnswerOptionMedia);
