import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

import {
    getCandidateRatingFilter, getCandidateRatingList,
    requestExportFeedbackCsv
} from 'requests/AssessmentRequests';
import CandidatePhoto from 'components/photos/candidate_photo';
import { checkHasCompanyFeature, downloadCSV } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import ExportingPopup from 'libraries/SnackbarProgress';
import InsightEvents from 'events/InsightEvents';

import RatingItem from './RatingItem';
import DownloadButton from './DownloadButton';

import styles from '../styles';
import { statsCtx } from '../statsStore';

const RatingData = observer(({ classes, uuid }) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const [dataList, setDataList] = useState({});
    const [filterData, setFilterData] = useState(null);
    const [dataListLoading, setDataListLoading] = useState(false);
    const [selectedScore, setSelectedScore] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [CSVPercents, setCSVPercents] = useState(0);
    const [cancelImporting, setCancelImporting] = useState(false);
    const [dialog_exporting_error, setDialogExportingError] = useState(false);
    const [dialogExporting, setDialogExporting] = useState(false);
    const { ratingData, slug } = useContext(statsCtx);
    const { company } = useContext(appCtx);

    const canDownloadCSV = checkHasCompanyFeature(company, 'EXPORT_CANDIDATE_FEEDBACK_DATA');

    useEffect(() => {
        if (!slug || !dialogOpened) return;
        loadData();
    }, [dialogOpened, currentPage, selectedScore]);


    useEffect(() => {
        if (dialogOpened) {
            loadDataFilter();
        } else {
            setCurrentPage(1);
            setSelectedScore('All');
            setDataList({});
            setFilterData(null);
        }
    }, [dialogOpened]);

    const handleDialog = () => {
        setDialogOpened(!dialogOpened);
    };

    const handleSelectedScore = (score) => {
        setDataList({});
        setCurrentPage(1);
        setSelectedScore(score);
    };

    const loadData = () => {
        setDataListLoading(true);
        getCandidateRatingList(slug, currentPage, selectedScore)
            .then(({ success, data }) => {
                if (success && data) {
                    setDataList({
                        ...data,
                        items: dataList.items ? [...dataList.items, ...data.items] : data.items
                    });
                }
            })
            .finally(() => {
                setDataListLoading(false);
            });
    };

    const loadDataFilter = () => {
        getCandidateRatingFilter(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setFilterData(data);
                }
            });
    };

    const loadMore = () => {
        if (!dataList.hasMorePages) return;
        setDataList({ ...dataList, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    const exportCSV = () => {
        InsightEvents.FEEDBACKS_EXPORTED({
            ttUuid: uuid,
            ttName: slug
        });

        setDialogExporting(true);
        setCancelImporting(false);
        setDialogExportingError(false);
        setCSVPercents(0);

        downloadFeedbackCSV();
    };

    const downloadFeedbackCSV = () => {
        const handleError = () => {
            setDialogExportingError(true);
            setCancelImporting(false);
            setTimeout(() => {
                setDialogExporting(false);
            }, 5000);
        };


        requestExportFeedbackCsv(slug)
            .then((response) => {
                const { success, totalItemsCount, offset, csv } = response;

                const handleSuccess = (fullString) => {
                    if (!cancelImporting) {
                        setCancelImporting(false);
                        setCSVPercents(100);
                        downloadCSV(fullString, `Candidate-feedback-${slug}`);
                    }

                    setTimeout(() => {
                        setDialogExporting(false);
                    }, 1000);
                };


                if (success) {
                    if (parseInt(totalItemsCount, 10) > parseInt(offset, 10)) {
                        const maxPage = Math.ceil(totalItemsCount / offset);
                        let page = 2;
                        let fullString = csv;

                        const CSVPercentsCalc = () => parseInt((offset * (page - 1) * 100) / totalItemsCount, 10);
                        const makeRequest = () => {
                            requestExportFeedbackCsv(slug, page)
                                .then((newResponse) => {
                                    if (newResponse.success) {
                                        fullString += `\n${newResponse.csv}`;
                                        page += 1;
                                        if (page <= maxPage) {
                                            setCSVPercents(CSVPercentsCalc());
                                            makeRequest();
                                        } else {
                                            handleSuccess(fullString);
                                        }
                                    } else {
                                        handleError();
                                    }
                                });
                        };
                        setCSVPercents(CSVPercentsCalc());
                        makeRequest();
                    } else {
                        handleSuccess(csv);
                    }
                } else {
                    handleError();
                }
            })
            .catch(() => {
                handleError();
            });
    };

    const cancelExporting = () => {
        setCancelImporting(true);
        setTimeout(() => {
            setDialogExporting(false);
        }, 1000);
    };

    const retryCSV = () => {
        setDialogExportingError(false);
        setCSVPercents(0);
        downloadFeedbackCSV();
    };

    if (!ratingData) return null;

    return (
        <>
            <div className={classes.ratingWrapper}>
                <p className={classes.label}>Candidate rating</p>
                <div className={classes.content}>
                    <RatingItem
                        value={ratingData.rating}
                        valueLabel="/ 5"
                        totalCount={ratingData.count}
                        label="CSAT rating"
                        onClick={handleDialog}
                    />
                </div>
            </div>
            <Dialog
                maxWidth="sm"
                open={dialogOpened}
                onClose={handleDialog}
                handleClose={handleDialog}
                titleComponent="Candidate Feedback"
            >
                {
                    filterData && (
                        <div className="u-dsp--f u-ai--center u-jc--end">
                            <span className={classes.filterLabel}>Show Rating</span>
                            { filterData.map(({ count, score }) => (
                                <span
                                    role="presentation"
                                    className={clsx(classes.score, selectedScore === score && classes.selectedScore)}
                                    onClick={() => { handleSelectedScore(score); }}
                                >
                                    {score}
                                    <span className={classes.countLabel}>({count})</span>
                                </span>
                            )) }
                        </div>
                    )
                }
                { canDownloadCSV && <DownloadButton onClick={exportCSV} /> }
                <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    loadMore={loadMore}
                    threshold={0}
                    hasMore={dataList.hasMorePages && !dataListLoading}
                >
                    {
                        dataList.items && dataList.items.map(({ candidate, score, comment, createdAt }) => (
                            <div className={classes.ratingCandidateItem}>
                                <CandidatePhoto
                                    user={{ ...candidate, candidateImageUrl: candidate.candidateProfileImage }}
                                />
                                <div className="u-wdt--100p">
                                    <div className="u-dsp--f u-jc--sb u-ai--start">
                                        <div className={classes.itemName}>
                                            {candidate.fullName}<br />
                                                    Rating: {score}/5
                                        </div>
                                        <Moment
                                            className={classes.itemDate}
                                            format="D MMM YYYY"
                                        >
                                            {createdAt}
                                        </Moment>
                                    </div>
                                    {Boolean(comment) && <div className="u-mrg--tx1">{comment}</div>}
                                </div>
                            </div>
                        ))
                    }
                </InfiniteScroll>
                {
                    dataListLoading && (
                        <div className="u-txt--center u-mrg--tx3">
                            <CircularProgress color="primary" size={20} />
                        </div>
                    )
                }
            </Dialog>
            <ExportingPopup
                open={dialogExporting}
                message="Exporting candidates to CSV…"
                onCancel={cancelExporting}
                error={dialog_exporting_error}
                onRetry={retryCSV}
                canceled={cancelImporting}
                percents={CSVPercents}
            />
        </>
    );
});

export default withStyles(styles)(withRouter(RatingData));
