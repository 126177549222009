import { tracking } from 'helper/eventSegment';

export default {
    ADD_QUESTION_CLICKED: (data) => {
        tracking('TT-add-question-clicked', data);
    },
    QUESTION_DELETED: (data) => {
        tracking('TT-question-deleted', data);
    },
    QUESTION_CHANGES_SAVED: (data) => {
        tracking('TT-questions-changes-saved', data);
    },
    ADVANCED_SCORING_CLICKED: (data) => {
        tracking('TT-advanced-scoring-clicked', data);
    },
    GROUP_CREATED: (data) => {
        tracking('TT-group-created', data);
    },
    GROUP_DELETED: (data) => {
        tracking('TT-group-deleted', data);
    },
    GROUP_NAME_EDITED: (data) => {
        tracking('TT-group-name-edited', data);
    },
    GROUP_QUESTION_MOVED: (data) => {
        tracking('TT-group-question-moved', data);
    },
    SAVED_GROUP_QUESTION_DELETED: (data) => {
        tracking('G-saved-group-deleted', data);
    },
    EEOC_ENABLED: (data) => {
        tracking('TT-eeoc-enabled', data);
    },
    ASSESSMENT_SLUG_CHANGED: (data) => {
        tracking('TT-slug-changed', data);
    },
    ASSESSMENT_BRANDING_ADDED: (data) => {
        tracking('TT-branding-added', data);
    },
    ASSESSMENT_BRANDING_RESET: (data) => {
        tracking('TT-branding-reset', data);
    },
    FEEDBACK_ENABLED: (data) => {
        tracking('TT-feedback-enabled', data);
    },
    FEEDBACK_SETTING_CHANGED: (data) => {
        tracking('TT-feedback-setting-changed', data);
    },
    FEEDBACK_RANK_INCLUDED: (data) => {
        tracking('TT-feedback-rank-included', data);
    }
};
