export default theme => ({
    wrapper: {
        background: 'transparent',
        border: 0,
        outline: 'none',
        padding: 0,
        maxWidth: '100%',

        '&:hover': {
            background: 'transparent'
        },
        '& .ba-videoplayer-container': {
            top: 2,
            //minHeight: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                maxHeight: 173
            }
        },
        '& .ba-audioplayer-container.ba-player-no-title': {
            minWidth: 'auto'
        },
        '& .playerWrapperAudio': {
            border: 'none!important',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                maxHeight: 173
            }
        }

    }
});
