import { post, get, patch, del } from '../helper/API';

export function myAccount(form) {
    return post('employer/my-account', {
        form
    });
}

export function setCompanyDailyDigest(active) {
    return post('employer/notifications/daily-digests', {
        active
    });
}

export function setProductNews(active) {
    return post('employer/notifications/product-news', {
        active
    });
}

export function setBlogArticles(active) {
    return post('employer/notifications/blog-articles', {
        active
    });
}

export function getGreenhouseSettings() {
    return get('employer/ats-integrations/greenhouse');
}

export function saveGreenhouseSettings(enabled, apiKey, harvestApiKey, mailTemplate) {
    return post('employer/ats-integrations/greenhouse', {
        apiKey,
        harvestApiKey,
        mailTemplate,
        enabled
    });
}

export function applyWithSeekAdvertiserId(advertiserId) {
    return post('save/apply-with-seek-advertiser-id', {
        advertiserId
    });
}

export function removeMember(email) {
    return del('employer/delete', { email });
}

export function getTeamMembers() {
    return get('employer/team-members');
}

export function getPageUpCredentials() {
    return get('employer/ats-integrations/pageup');
}

export function updatePageUpIntegration(clientId, privateKey, enabled) {
    return post('employer/ats-integrations/pageup', {
        clientId,
        privateKey,
        enabled
    });
}

export function getEmployerNotifications() {
    return get('employer/notifications');
}

export function getPossibleTimezones() {
    return get('employer/company/timezones');
}

export function getComeetCredentials() {
    return get('employer/ats-integrations/comeet');
}

export function updateComeetIntegration(apiKey, enabled) {
    return post('employer/ats-integrations/comeet', {
        apiKey,
        enabled
    });
}

export function getPublicApiSettings() {
    return get('employer/ats-integrations/ats-api');
}

export function updatePublicApiSettings(data) {
    return post('employer/ats-integrations/ats-api', data);
}

export function getOracleSettings() {
    return get('employer/ats-integrations/oracle');
}

export function updateOracleSettings(data) {
    return post('employer/ats-integrations/oracle', data);
}

export function changeTeamRole(uuid, type) {
    return patch(`employer/${uuid}/permissions/change-role`, { type });
}

export function changeCompanyOwner(uuid) {
    return patch(`employer/admins/${uuid}/permissions/change-company-owner`);
}

export function getCompanyTags() {
    return get('employer/tags');
}

export function addCompanyTag(data) {
    return post('employer/tags', data);
}

export function deleteCompanyTag(tagId) {
    return del(`employer/tags/${tagId}`);
}

export function changeCompanyTag(tagId, data) {
    return patch(`employer/tags/${tagId}`, data);
}

export function getAssessmentsLanguages() {
    return get('employer/company/languages');
}

export function getBrandingsList() {
    return get('employer/brands');
}

export function addNewBranding(title) {
    return post('employer/brands', { title });
}

export function editBrandingName(title, uuid) {
    return patch(`employer/brands/${uuid}`, { title });
}

export function editBrandingColor(uuid, color) {
    return patch(`employer/brands/${uuid}`, { color });
}

export function updateBrandingLogo(uuid, data) {
    return post(`employer/brands/${uuid}/logo`, data);
}

export function deleteCompanyBranding(uuid) {
    return del(`employer/brands/${uuid}`);
}

export function assignBrandingToAssessment(assessmentSlug, brandingUuid) {
    return post(`employer/auditions/${assessmentSlug}/brands/${brandingUuid}`);
}


export function resetBrandingInAssessment(assessmentSlug) {
    return del(`employer/auditions/${assessmentSlug}/brands`);
}

export function sendIcimsData(data) {
    return post('public/icims/auth', data);
}

export function setAtsApiSettings(data) {
    return post('employer/ats-integrations/ats-api', data);
}

export function getAtsApiSettings(data) {
    return get('employer/ats-integrations/ats-api', data);
}

export function changeAtsApiEvent(eventTitle, enabled) {
    return patch(`employer/ats-integrations/ats-api/report-events/${eventTitle}`, { enabled });
}

export function resendTeamInvitation(teamId) {
    return post(`employer/invitations/${teamId}/reinvite`, {});
}

export function getJobViteSettings() {
    return get('employer/ats-integrations/job-vite');
}

export function updateJobVite(data) {
    return post('employer/ats-integrations/job-vite', data);
}

export function setPremiumGroup() {
    return patch('employer/company/plan/group/460861bb-1ea1-45a9-963f-20a893e672a7');
}

export function applyCandidateFeedbackAgreement(data) {
    return post('employer/company/candidate-feedback-agreement', data);
}
