import React from 'react';
import { withStyles } from '@mui/styles';
import QuestionContent from 'components/grade_components/QuestionContent';
import { modifyScreeningQuestion } from 'helper/questionSettingsFunctions';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoneIcon from 'components/icons/CheckIcon';
import CloseIcon from 'img/remove.svg';
import Checkbox from 'libraries/Checkbox';

import QuestionResultHeader from './QuestionResultHeader';

const styles = theme => ({
    wrapper: {
        maxWidth: '100%',
        overflow: 'auto',
        width: '100%'
    },
    innerWrapper: {
        letterSpacing: '0.16px',
        padding: '20px 40px',
        color: theme.palette.grey[900],
        minWidth: 750
    },
    hr: {
        borderTop: `2px solid ${theme.palette.grey[300]}`
    },
    screenAnswerWrapper: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        marginTop: 12,
        marginBottom: 18,
        paddingBottom: 7
    },
    nonPass: {
        width: 20,
        marginLeft: 6
    }
});

const ScreenList = ({ classes, screenQuestionResults }) => {
    const returnPass = (passed) => {
        if (passed) return <div className="u-dsp--f u-ai--center u-txt--bold">Pass <DoneIcon className="u-mrg--lx1" /></div>;
        return <div className="u-dsp--f u-ai--center u-txt--bold">Fail <img src={CloseIcon} alt="" className={classes.nonPass} /></div>;
    };

    if (!screenQuestionResults.length) return null;

    return (
        <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
                <QuestionResultHeader
                    onlyMultipleChoice={screenQuestionResults.every(({ singleChoice }) => !singleChoice)}
                    label={returnPass(screenQuestionResults.every(({ failed }) => !failed))}
                    className="u-pdn--x0 u-pdn--tx3 u-pdn--bx3 u-ai--start"
                />
                <hr className="u-mrg--x0 u-mrg--bx3" />
                {
                    screenQuestionResults.map((result) => {
                        const question = modifyScreeningQuestion({ ...result, screeningQuestion: true });
                        if (!question.answered) return null;
                        return (
                            <React.Fragment key={question.id}>
                                <QuestionContent question={question} />
                                <div className={classes.screenAnswerWrapper}>
                                    {
                                        question.answers?.filter(({ checked }) => checked).map((answer) => {
                                            const { checked, title, id, correct } = answer;
                                            return (
                                                <div className="u-dsp--distribute" key={id}>
                                                    {
                                                        question.singleChoice ? (
                                                            <FormControlLabel
                                                                key={id}
                                                                value="runTime"
                                                                control={(
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={checked}
                                                                    />
                                                                )}
                                                                label={title || ' '}
                                                            />
                                                        ) : (
                                                            <Checkbox
                                                                label={title}
                                                                checked={checked}
                                                            />
                                                        )
                                                    }
                                                    { question.singleChoice && returnPass(correct)}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </React.Fragment>
                        );
                    })
                }

            </div>
        </div>
    );
};

export default withStyles(styles)(ScreenList);
