import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import TooltipBlack from 'libraries/Tooltip';
import { checkHasCompanyFeature, checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import { CSV_EXPORT } from 'helper/constants';
import { appCtx } from 'components/appStore';
import ExportUsers from 'components/dialogs/ExportCSVDialog';
import ExportIcon from 'components/icons/ExportIcon';

import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import styles from './styles';


const ExportButton = observer(({
    classes, candidatesCount,
    opportunity, slug, disabled,
    segmentEventContext, isAssessment,
    isHired
}) => {
    const [exportOpened, setExportOpened] = useState(false);
    const { company } = useContext(appCtx);
    const hasCompanyFeature = checkHasCompanyFeature(company, CSV_EXPORT);
    const canUpgrade = checkCompanyCanBeUpgraded(company);

    const handleExportDialogOpen = () => {
        setExportOpened(!exportOpened);
    };

    if (disabled) {
        return (
            <Button
                className={classes.saveChangesButton}
                disabled
            >
                <ExportIcon />&nbsp;
                Export
            </Button>
        );
    }

    return (
        <>
            {
                !hasCompanyFeature ? (
                    <TooltipBlack
                        tooltipClass={classes.tooltip}
                        leaveDelay={500}
                        interactive
                        label={(
                            <>
                                <p className="u-txt--14 u-txt--normal">
                                    The ability to export candidates <br />to CSV is not available on the current plan.
                                </p>
                                {
                                    canUpgrade
                                        ? (
                                            <SubscriptionsLink
                                                label="Upgrade"
                                                className={classes.talkToUsLabel}
                                                segmentLocation='"export to CSV" disabled button tooltip'
                                            />
                                        )
                                        : (
                                            <div
                                                role="presentation"
                                                className={classes.talkToUsLabel}
                                                onClick={() => window.open('/subscriptions', '_blank')}
                                            >
                                                Upgrade →
                                            </div>
                                        )
                                }
                            </>
                        )}
                    >
                        <div>
                            <Button
                                className={classes.saveChangesButton}
                                onClick={handleExportDialogOpen}
                                disabled
                            >
                                <ExportIcon />&nbsp;
                                Export
                            </Button>
                        </div>
                    </TooltipBlack>
                ) : (
                    <Button
                        className={classes.saveChangesButton}
                        onClick={handleExportDialogOpen}
                        disabled={disabled}
                    >
                        <ExportIcon />&nbsp;
                        Export
                    </Button>
                )
            }
            <ExportUsers
                isAssessment={isAssessment}
                segmentEventContext={segmentEventContext}
                open={exportOpened}
                usersCount={candidatesCount}
                slug={slug}
                opportunity={opportunity}
                isHired={isHired}
                onClose={handleExportDialogOpen}
            />
        </>
    );
});

export default withStyles(styles)(ExportButton);
