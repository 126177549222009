import React from 'react';
import clsx from 'clsx';
import { TEXT, VIDEO, AUDIO, CODE_TEST } from 'helper/constants';

// material components
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';

import TextInput from 'libraries/TextInput';
import Tooltip from 'libraries/Tooltip';

import jss from './styles';

const Wrapper = ({
    valueType, children,
    value, handleChangeType,
    onChange, questionFile,
    isUploadingFile,
    classes, allowedLength,
    label, placeholder, className, isError
}) => {
    const isIconButton = (valueType === VIDEO || valueType === AUDIO);

    return (
        <div className={clsx(classes.wrapper, isError && classes.wrapperError, className)}>
            {
                label && (
                    <span className={classes.label}>
                        {label}
                    </span>
                )
            }
            <TextInput
                className={classes.textFieldRoot}
                onChange={onChange}
                value={value}
                fullWidth
                maxLength={allowedLength}
                multiline
                placeholder={placeholder}
            />
            {
                !isIconButton && (
                    <div className="u-txt--right u-mrg--bx2">
                        <Button
                            onClick={e => handleChangeType(e, TEXT)}
                            classes={{
                                root: classes.button
                            }}
                        >
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.25 0.75L0.75 8.25" stroke="#1247F6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M0.75 0.75L8.25 8.25" stroke="#1247F6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{(questionFile || valueType === CODE_TEST) ? 'Remove' : 'Cancel'}</span>
                        </Button>
                    </div>
                )
            }
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    display: valueType === 6 && questionFile ? 'inline-block' : 'block'
                }}
            >
                {!isUploadingFile && isIconButton && (
                    <Tooltip
                        label="Remove media"
                        position="top"
                    >
                        <IconButton
                            onClick={e => handleChangeType(e, TEXT)}
                            classes={{
                                root: classes.iconButton
                            }}
                            size="large">
                            <svg
                                className={classes.icon}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path fill="white" d="M0 0h24v24H0V0z" />
                                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
                            </svg>
                        </IconButton>
                    </Tooltip>
                )}
                {children}
            </div>
        </div>
    );
};

export default withStyles(jss)(Wrapper);
