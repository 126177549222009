import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { getCandidateCardInfo } from 'requests/AssessmentRequests';
import { appCtx } from 'components/appStore';
import UpgradeDialog from 'components/dialogs/UpgradeDialog';
import CandidateCardWrapper from 'components/candidate_cards/CandidateCardWrapper';
import { getCandidatePosts, addCandidateComment } from 'requests/JobOpportunityRequests';
import { candidateResultCtx } from '../common/CandidateListWrapper/candidatesStore';
import { assessmentSettingsCtx } from '../../store';
import { resultsCtx } from '../store';
import { inviteTabCtx } from '../../candidate_invite/store';

const styles = {
    wrapper: {
        width: 'calc(100% + 60px)',
        margin: '0 -30px'
    }
};


const CandidatePage = observer(({ classes, match, history, isInvite }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { company } = useContext(appCtx);
    const {
        opportunitySlug, audition, getTags,
        setTags, companyTags
    } = useContext(assessmentSettingsCtx);
    const { candidateTabs, addCandidateTab, removeCandidateTab } = useContext(isInvite ? inviteTabCtx : resultsCtx);
    const { loadFiltersStats, isEmployerSortEnabled } = useContext(candidateResultCtx);

    const { user_id: userId, scriptSlug } = match.params;

    useEffect(() => {
        setIsLoading(true);
        setUser(null);
        getUserInfo();
    }, [userId]);

    const handleCloseCard = () => {
        removeCandidateTab(userId, scriptSlug);
        if (isInvite) {
            history.push(`/script/invite/${scriptSlug}/invite-candidates`);
        } else {
            history.push(`/script/select/${scriptSlug}`);
        }
    };

    const getUserInfo = () => {
        getCandidateCardInfo(scriptSlug, userId)
            .then(({ success, data }) => {
                if (success) {
                    const { fullName, candidateId: id, candidateUuid } = data;
                    setUser({ ...data, uuid: candidateUuid });
                    const index = candidateTabs.findIndex(({ id: candidateId }) => candidateId === id);
                    if (index === -1) addCandidateTab(id, fullName, scriptSlug);
                }
            })
            .catch(() => {
                handleCloseCard();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (!user) return null;

    if (user.completionsPerOpportunityLimitExceeded) {
        return (
            <UpgradeDialog
                open
                onClose={handleCloseCard}
            />
        );
    }

    return (
        <CandidateCardWrapper
            className={classes.wrapper}
            user={user}
            setUser={setUser}
            company={company}
            isLoading={isLoading}
            getUserInfo={getUserInfo}
            handleCloseCard={handleCloseCard}
            opportunitySlug={opportunitySlug}
            audition={audition}
            context="candidate report card"
            loadFiltersStats={loadFiltersStats}
            companyTags={companyTags}
            getTags={getTags}
            setTags={setTags}
            loadCandidatePosts={getCandidatePosts}
            addCandidateComment={addCandidateComment}
            isEmployerSortEnabled={isEmployerSortEnabled}
        />
    );
});

export default withStyles(styles)(withRouter(CandidatePage));
