import React, { useContext, useEffect } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as qs from 'query-string';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';

import Layout from 'components/layout';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';

import SettingsHeader from '../settings_header';
import PausedLabel from '../PausedLabel';

import PlanFeaturesBlock from './PlanFeaturesBlock';
import PlanInfoBlock from './PlanInfoBlock';
import PurchaseWrapper from './PurchaseWrapper';
import { subscriptionDialogStoreCtx } from './store';

const styles = theme => ({
    content: {
        padding: 0,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }
});

const SubscriptionsSettings = observer(({ company, match, classes, location }) => {
    const {
        companyPlan, getAvailablePlans, getCompanyPlan,
        getCards, setShowSecret
    } = useContext(subscriptionDialogStoreCtx);


    useEffect(() => {
        if (window.location.search.includes('?forsales')) {
            setShowSecret(true);
        }
        const parsed = qs.parse(location.search);
        const { plan = '' } = parsed;
        getAvailablePlans(plan);
        getCompanyPlan();
        getCards();
    }, []);

    if (!company || !companyPlan) {
        return (
            <Layout>
                <SettingsHeader />
            </Layout>
        );
    }

    return (
        <Layout>
            <PausedLabel />
            <SettingsHeader />
            <PageWrapper
                headerLabel="Choose your plan"
            >
                <ContentWrapper className={classes.content}>
                    <PlanInfoBlock />
                    <PlanFeaturesBlock />
                </ContentWrapper>
            </PageWrapper>
            <Switch>
                <Route
                    exact
                    path={`${match.url}/plans/:planSlug`}
                    render={props => (
                        <PurchaseWrapper
                            {...props}
                            open
                        />
                    )}
                />
                <Route render={props => (<Redirect to="/subscriptions" {...props} />)} />
            </Switch>
        </Layout>
    );
});

export default withRouter(withStyles(styles)(SubscriptionsSettings));
