import React, { useContext, useEffect, useState } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import PageWrapper from 'components/layout/PageWrapper';
import { skipOnBoarding } from 'requests/APIrequests';
import { getCandidatesInAssessment, loadAssessmentStats } from 'requests/AssessmentRequests';
import { loadAudition } from 'requests/ScriptRequests';

import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import SelectTabs from 'pages/assessment/results_assessment/common/SelectTabs';
import { appCtx } from 'components/appStore';
import { resultsCtx } from 'pages/assessment/results_assessment/store';
import OnboardingEvents from 'events/OnboardingEvents';
import { getOnboardingLocation } from 'helper/commonFunctions';

import { returnStatsArray } from '../../assessment/statsStore';

import CandidatesTab from './candidates_list';
import CandidatePage from './candidate';
import OnboardingDialog from '../onboarding_dialog';


import styles from './styles';

const OnboardingAssessmentSelect = observer(({ classes, match, history, location }) => {
    const [dialogOpened, setDialogOpened] = useState(true);
    const [statsLoading, setStatsLoading] = useState(true);
    const [candidatesLoading, setCandidatesLoading] = useState(true);
    const [audition, setAudition] = useState({});
    const [statsArr, setStats] = useState({});
    const [candidates, setCandidates] = useState({
        count: 24,
        currentPage: 1,
        hasMorePages: false,
        items: [],
        total: 24
    });
    const { candidateTabs, removeCandidateTab, setCandidateTabs } = useContext(resultsCtx);
    const { company, updateCompany } = useContext(appCtx);
    const { onboarding } = company;
    const { auditionName, auditionSlug: ttSlug, auditionUuid: ttUUID } = onboarding;

    const { scriptSlug } = match.params;

    useEffect(() => {
        function triggerPushState() {
            window.history.pushState(null, document.title, window.location.href);
        }

        if (match.url.includes(`/onboarding/select/${ttSlug}`)) {
            triggerPushState();
            window.addEventListener('popstate', triggerPushState);
        }

        OnboardingEvents.SELECT_LOADED({ ttSlug, ttName: auditionName, ttUUID });
        loadAudition(scriptSlug)
            .then(({ data, success }) => {
                if (data && success) {
                    setAudition(data);
                }
            });
        loadStats();

        return (() => {
            window.removeEventListener('popstate', triggerPushState);
        });
    }, []);

    useEffect(() => {
        if (location.pathname.includes('candidate')) {
            OnboardingEvents.CARD_LOADED({ ttSlug, ttName: auditionName, ttUUID });
        }
    }, [location.pathname]);

    const loadStats = () => {
        setStatsLoading(true);
        loadAssessmentStats(scriptSlug)
            .then(({ data, success }) => {
                if (data && success) {
                    setStats(returnStatsArray(data));
                    setStatsLoading(false);
                }
            });
    };

    const loadCandidates = () => {
        setCandidatesLoading(true);
        getCandidatesInAssessment(scriptSlug)
            .then(({ data, success }) => {
                if (data && success) {
                    setCandidates(data);
                    setCandidatesLoading(false);
                }
            });
    };

    const onSkip = () => {
        OnboardingEvents.GET_STARTED_CLICKED({ step: getOnboardingLocation(location.pathname) });
        skipOnBoarding()
            .then(() => {
                const { companySettings } = company;
                updateCompany({
                    ...company,
                    onboarding: null,
                    companySettings: {
                        ...companySettings,
                        activeAuditionsPerCompanyUsed: 0
                    }
                });
                history.push('/marketplace?redirectFrom=onboarding');
            });
    };

    const handleDialog = () => {
        setDialogOpened(false);
    };

    const cardProps = {
        opportunitySlug: audition.opportunitySlug,
        audition,
        statsArr,
        opportunity: audition,
        isLoading: candidatesLoading,
        assessmentSlug: scriptSlug,
        allCandidates: candidates.items,
        totalCount: candidates.total,
        loadCandidates
    };

    return (
        <>
            <OnboardingDialog
                open={dialogOpened}
                onClose={handleDialog}
            >
                <span role="img" aria-label="!">🎉</span> You've completed your first simulation!<br />
                You're now about to see how Vervoe scores and ranks candidates in your company dashboard.<br />
                Ready to see how you performed against other candidates?
            </OnboardingDialog>

            <PageWrapper className="u-pdn--bx5">
                <div className={classes.selectHeader}>
                    <Typography variant="h2" className={classes.headerClass}>
                        {auditionName}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSkip}
                        className="u-mrg--lx2 u-pdn--lx6 u-pdn--rx6"
                    >
                        Get Started With Your Own Assessment Now
                    </Button>
                </div>
                {
                    statsLoading ? (
                        <InnerContentWrapper>
                            <div className={classes.loaderWrapper}>
                                <CircularProgress color="primary" />
                            </div>
                        </InnerContentWrapper>
                    ) : (
                        <InnerContentWrapper
                            className={classes.contentWrapper}
                            contentClassName="u-pdn--x0"
                        >
                            <SelectTabs
                                isOnboarding
                                params={match.params}
                                {...{
                                    shortlistCandidatesCount: 0,
                                    loadAssessmentStats: loadStats,
                                    totalCompletionsCount: candidates.total,
                                    candidateTabs,
                                    removeCandidateTab,
                                    setCandidateTabs
                                }}
                            />
                            <div className={classes.content}>
                                {
                                    audition && (
                                        <Switch>
                                            <Route
                                                path="/onboarding/select/:scriptSlug/candidate/:user_id"
                                                render={props => (<CandidatePage {...props} {...cardProps} />)}
                                            />
                                            <Route
                                                render={props => (<CandidatesTab {...props} {...cardProps} />)}
                                                path="/onboarding/select/:scriptSlug"
                                            />
                                        </Switch>
                                    )
                                }
                            </div>
                        </InnerContentWrapper>
                    )
                }
            </PageWrapper>
        </>
    );
});

export default withRouter(withStyles(styles)(OnboardingAssessmentSelect));
