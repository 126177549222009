import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withTheme, withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import List from '@mui/material/List';
import SkillsOrTagsMenuItem from 'components/assessments_pages/asessment_settings_components/SkillsOrTagsMenuItem';
import TextInput from 'libraries/TextInput';
import Popover from 'libraries/Popover';
import Tooltip from 'libraries/Tooltip';

import { addCompanyTag } from 'requests/SettingsRequests';
import { tagCandidate, unTagCandidate } from 'requests/AssessmentRequests';
import SettingsEvents from 'events/SettingsEvents';
import GeneralEvents from 'events/GeneralEvents';

import { checkHasUserPermission, clone } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import TagIcon from 'components/icons/TagIcon';
import TagIconActive from 'components/icons/TagIconActive';

import styles from './styles';


const TagButton = observer(({
    classes, tags = [], addTag, theme, removeTag, candidate,
    loadFiltersStats, loadCandidates, filterTagsCategory,
    dataForEvents, audition, getTags, companyTags: allTags, setTags: setAllTags, ...other
}) => {
    const [skillName, setSkillName] = useState('');
    const [loadingName, setLoadingName] = useState(null);
    const [loadingAddingGroup, setLoadingAddingGroup] = useState(false);
    const { flashMessage, company, loggedUser } = useContext(appCtx);
    const { roleType, permissions: userPermissions } = loggedUser;

    if (!audition || !candidate) return null;

    const { permissions, onboarding } = audition;

    const { columnUserId } = candidate;

    const canTagCandidates = checkHasUserPermission(company, permissions, 'tagCandidates');
    const canAddTags = checkHasUserPermission(company, userPermissions, 'addTags');

    let isDisabledAddButton = false;

    const { companySettings } = company;
    if (companySettings) {
        const { tags: settingsTags } = companySettings;
        isDisabledAddButton = tags !== -1 && allTags && allTags.length >= settingsTags;
    }

    const onClose = () => {
        setSkillName('');
    };

    const onChange = (e) => {
        const { value } = e.target;
        setSkillName(value);
    };

    const onTag = async ({ id, title }, index, isActive) => {
        setLoadingName(title);
        const action = isActive ? unTagCandidate : tagCandidate;
        const eventData = { ...dataForEvents, tagId: id, tagName: title };
        try {
            const { data, success } = await action(id, columnUserId);
            if (!isActive && success && data) {
                addTag(data);
                GeneralEvents.CANDIDATE_TAGGED(eventData);
            } else if (isActive) {
                removeTag(index);
                GeneralEvents.CANDIDATE_UNTAGGED(eventData);
                if (filterTagsCategory && Object.keys(filterTagsCategory).length && filterTagsCategory) {
                    loadCandidates();
                }
            }
            if (loadFiltersStats) loadFiltersStats();
            setLoadingName(null);
        } catch (e) {
            setLoadingName(null);
        }
    };


    const addNewTag = () => {
        setLoadingAddingGroup(true);
        addCompanyTag({ title: skillName.toLowerCase() })
            .then(async ({ success, data }) => {
                if (data && success) {
                    const { id, title } = data;
                    const newAllTags = clone(allTags);
                    newAllTags.push(data);
                    setSkillName('');
                    if (setAllTags) setAllTags(newAllTags);
                    onTag(data, newAllTags.length - 1, false);
                    if (loadFiltersStats) loadFiltersStats();
                    if (getTags) getTags();
                    SettingsEvents.CANDIDATE_TAG_ADDED({ tagId: id, tagName: title, context: dataForEvents.context });
                }
                setLoadingAddingGroup(false);
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.data && response.data.errors) {
                    const { errors } = response.data;
                    if (typeof errors === 'object') {
                        const { title } = response.data.errors;
                        flashMessage(title[0].message);
                    } else {
                        flashMessage(errors);
                    }
                } else {
                    console.log(err);
                }
                setLoadingAddingGroup(false);
            });
    };

    return (
        <Popover
            className={classes.popoverRoot}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            onClose={onClose}
            label={(
                <Tooltip label="Add Tags">
                    <div>
                        <IconButton
                            size="large"
                            className={classes.iconStar}
                            {...other}
                        >
                            { tags.length ? <TagIconActive /> : <TagIcon /> }
                        </IconButton>
                    </div>
                </Tooltip>
            )}
        >
            <div className={classes.popoverHeader}>
                <Typography variant="h6">Tag Candidate</Typography>
            </div>
            <div className={classes.popoverContent}>
                {
                    loadingAddingGroup && (
                        <div className={classes.loaderWrapper}>
                            <CircularProgress size={35} color="primary" />
                        </div>
                    )
                }
                <List className={classes.list}>
                    {
                        allTags && allTags.length ? allTags.map((tag) => {
                            const { id } = tag;
                            const tagIndex = tags.findIndex(({ id: tagId }) => tagId === id);
                            const isActive = tagIndex !== -1;
                            return (
                                <SkillsOrTagsMenuItem
                                    key={id}
                                    group={tag}
                                    disabled={!canTagCandidates || !audition.active}
                                    isActive={isActive}
                                    loadingName={loadingName}
                                    onClick={() => onTag(tag, tagIndex, isActive)}
                                />
                            );
                        }) : (
                            <div className={classes.emptyTags}>
                                <TagIcon width="32" height="30" /> <br />
                                <Typography variant="h6">
                                    No tags created
                                </Typography>
                            </div>
                        )
                    }
                </List>
            </div>
            {
                (canAddTags && audition.active) && (
                    <>
                        <div className={classes.addSection}>
                            <TextInput
                                variant="outlined"
                                placeholder="Create new tag"
                                className={classes.skillInput}
                                onChange={onChange}
                                value={skillName}
                                maxLength={150}
                            />

                            {
                                isDisabledAddButton ? (
                                    <Tooltip
                                        label="Maximum tag limit reached."
                                    >
                                        <div>
                                            <Button
                                                className="u-txt--bold"
                                                color="primary"
                                                disabled
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        disabled={loadingAddingGroup || !skillName || !skillName.trim().length}
                                        className="u-txt--bold"
                                        onClick={addNewTag}
                                        color="primary"
                                    >
                                        Add
                                    </Button>
                                )
                            }
                        </div>
                        {
                            roleType !== 'team' && (
                                <div className={classes.footer}>
                                    Manage tags in&nbsp;
                                    <Link
                                        to="/company-account"
                                        className={clsx(onboarding && 'u-pe--none')}
                                    >
                                        company account
                                    </Link>
                                </div>
                            )
                        }
                    </>
                )
            }
        </Popover>
    );
});

export default withStyles(styles)(withTheme(TagButton));
