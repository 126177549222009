import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { CSV_EXPORT_CANDIDATE_QUESTIONS_AND_ANSWERS, STATUS_READY_TO_GRADE } from 'helper/constants';
import LoaderCalibration from 'components/loaders/LoaderCalibration';
import { gradeQuestion } from 'requests/ActiveLearningRequests';
import GeneralEvents from 'events/GeneralEvents';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import BackArrowIcon from 'img/backBlueArrow.svg';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';

import IconMenu from '../IconMenu';
import PersistentDrawer from '../PersistentDrawer';
import QuestionStatistic from '../QuestionStatistic';

import CandidateAnswer from './CandidateAnswer';
import NoGradingBlock from './NoGradingBlock';
import GradingBlock from './GradingBlock';
import QuestionContentAccordion from './QuestionContentAccordion';
import ScoresBlock from './ScoresBlock';

import useStyles from './styles';

const ContentWrapper = observer(({
    className, drawerWidth,
    question, selectedQuestionIndex, questionHashUuid,
    loadAudition, setCurrentQuestion, findNextQuestionToGrade,
    openedSection, handleOpenDrawer
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [grade, setGrade] = useState(null);
    const [scoresSectionOpen, setScoresSectionOpen] = useState(false);
    const { audition, audition: { permissions } } = useContext(assessmentSettingsCtx);
    const { company, flashMessage } = useContext(appCtx);
    const canGrade = checkHasUserPermission(company, permissions, 'grade');
    const hasCSVExport = checkHasCompanyFeature(company, CSV_EXPORT_CANDIDATE_QUESTIONS_AND_ANSWERS);

    const classes = useStyles({ drawerWidth });

    const { question: questionContent, questionResult, status, gradedCount, responsesCount, suggestedAnswerAdded } = question;
    const readyToGradeCount = status > 0 ? responsesCount - gradedCount : 0;
    const questionNumber = selectedQuestionIndex + 1;

    let noGradingHeader = null;
    let noGradingSubHeader = null;

    if (questionResult === null || readyToGradeCount === 0) {
        noGradingHeader = 'No Answers to Grade';
        noGradingSubHeader = 'There are no more candidate responses to this question.';
    }

    const saveGrade = (value) => {
        setIsLoading(true);
        setGrade(value);

        const { id: questionResultId, question: { uuid: questionUuid } } = questionResult;
        const { uuid, name } = audition;

        return gradeQuestion(questionResultId, questionHashUuid, value)
            .then(({ success, data }) => {
                if (success && data) {
                    setCurrentQuestion(data);
                }
                const { questionForGradingDTO: newQuestionForGradingDTO } = data;
                const { status: newStatus } = newQuestionForGradingDTO;
                if (status !== newStatus && newStatus !== STATUS_READY_TO_GRADE) {
                    findNextQuestionToGrade();
                }
                loadAudition();
                GeneralEvents.QUESTION_SCORED({
                    scoreGiven: value,
                    questionNumber,
                    questionId: questionUuid,
                    ttId: uuid,
                    ttName: name,
                    context: 'grading tab'
                });
                setIsLoading(false);
                setGrade(null);
            })
            .catch(() => {
                setIsLoading(false);
                setGrade(null);
            });
    };

    return (
        <div className={className}>
            <div className="u-pos--relative">
                <div className={classes.header}>
                    <div className="u-dsp--f u-ai--center">
                        {scoresSectionOpen && (
                            <Button className={classes.backBtn} onClick={() => setScoresSectionOpen(false)}>
                                <img src={BackArrowIcon} alt="<-" />
                            </Button>
                        )}
                        <Typography variant="h3">
                            Question {questionContent.sort}
                        </Typography>
                    </div>
                    <IconMenu
                        {...{ openedSection, handleOpenDrawer }}
                        noExampleAnswers={!suggestedAnswerAdded}
                    />
                </div>
                <div className={classes.wrapper}>
                    <QuestionStatistic
                        questionResult={questionResult}
                        question={question}
                        scoresSectionOpen={scoresSectionOpen}
                        setScoresSectionOpen={setScoresSectionOpen}
                    />
                    <QuestionContentAccordion question={questionContent} />
                    {scoresSectionOpen ? (
                        <ScoresBlock
                            {...{
                                scoresSectionOpen,
                                flashMessage,
                                audition,
                                questionHashUuid,
                                hasCSVExport
                            }}
                            questionNumber={questionContent.sort}
                        />
                    ) : (
                        <div className={classes.answerWrapper}>
                            {noGradingHeader ? (
                                <NoGradingBlock
                                    header={noGradingHeader}
                                    subHeader={noGradingSubHeader}
                                />
                            ) : (
                                <CandidateAnswer
                                    questionNumber={questionNumber}
                                    question={questionResult}
                                />
                            )}
                            {
                                isLoading && (
                                    <div className={classes.loaderWrapper}>
                                        <LoaderCalibration />
                                    </div>
                                )
                            }
                            {!noGradingHeader && (
                                <GradingBlock
                                    grade={grade}
                                    gradeLoading={isLoading}
                                    saveGrade={saveGrade}
                                    canGrade={canGrade}
                                    hintSection={questionResult?.aiScoreSection}
                                />
                            )}
                        </div>
                    )}
                    <PersistentDrawer
                        openedSection={openedSection}
                        drawerWidth={drawerWidth}
                        question={questionContent}
                        suggestedAnswerAdded={suggestedAnswerAdded}
                        status={status}
                        permanentDrawerWidth={0}
                        handleOpen={handleOpenDrawer}
                    />
                </div>
            </div>
        </div>
    );
});

export default ContentWrapper;
