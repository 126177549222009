import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    wrapper: {
        marginTop: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    rejectButton: {
        color: `${theme.palette.red[600]}!important`
    },
    hiredButton: {
        color: `${theme.palette.yellow[800]}!important`
    },
    shortlistButton: {
        color: `${theme.palette.green[600]}!important`
    },
    button: {
        position: 'relative',
        borderRadius: 2,
        fontSize: 12,
        fontWeight: 700,
        width: 170,
        padding: 5,
        textTransform: 'uppercase',
        background: theme.palette.grey[200],
        '&:disabled': {
            background: theme.palette.grey[200],
            '& span': {
                color: theme.palette.grey[400]
            }
        },
        '&:hover, &:active': {
            '& span': {
                color: '#fff'
            },
            background: 'currentColor'
        },
        '&:not(:last-child)': {
            marginRight: 15
        }
    },
    buttonActive: {
        background: 'currentColor',
        '& span': {
            color: '#fff'
        },
        '&:hover, &:active': {
            '& span': {
                color: 'currentColor'
            },
            background: theme.palette.grey[200]
        },
        '&:disabled': {
            background: 'currentColor',
            '& span': {
                color: '#fff'
            }
        }
    },
    hireBanner: {

    }
}));
